import { Box, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { PropTypes } from 'prop-types';

export const KlaviyoTemplateSearch = ({ onSearch, searchName }) => {
	const [searchText, setSearchText] = useState(searchName);
	const [searchTimer, setSearchTimer] = useState(null);

	// Don't make a search a requst on every key
	const textChanged = (e) => {
		clearTimeout(searchTimer);
		setSearchText(e.target.value);
		const timerId = setTimeout(() => { onSearch(e.target.value); }, 800);
		setSearchTimer(timerId);
	};

	return (
		<Box>
			<Box display={'flex'} alignItems={'center'}>
				<Typography variant={'h6'} sx={{ marginY: 1, marginRight: 1 }}>Or search: </Typography>
				<TextField
					id="templateName"
					label="Template Name (full)"
					variant="filled"
					value={searchText}
					onChange={textChanged}
				/>
			</Box>
		</Box>
	);
};

KlaviyoTemplateSearch.propTypes = {
	onSearch: PropTypes.object.isRequired,
	searchName: PropTypes.string
};
