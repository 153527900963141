import React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';

export const formatStripePricing = (amount, options = {}) => {
	if (amount === null) { return; }
	return (amount / 100).toLocaleString(undefined, options);
};

export const getCurrencySymbol = (stripeCurrency) => {
	if (!stripeCurrency) { return ' '; }
	switch (stripeCurrency) {
		case 'dkk': return 'kr.'; // eslint-disable-line
		case 'eur': return '€'; // eslint-disable-line
		default: return '?'; // eslint-disable-line
	}
};

// const LoadingWrapper = ({ show, children }) => show ? <Skeleton>{children}</Skeleton> : children;
// const LoadingWrapper = ({ show, children }) => children;


export const TierPricing = ({ tier, activeTier, selectedUsage, isLoadingActiveSubscription }) => {
	if (isLoadingActiveSubscription) {
		return (<Skeleton sx={{ maxWidth: '60%' }}><Box sx={{ height: 'calc(6.25rem + 2px)' }} /></Skeleton>)
	} else if (selectedUsage?.flatAmount == null) {
		return <Box sx={{ my: 3, height: 'calc(3.5rem + 1px)' }} />;
	} else {
		return (
			<Box sx={{ my: 3 }}>
				<Typography sx={{ minHeight: '1.5rem' }} variant='h4' component='div' display='flex'>
					<Typography sx={{ mt: '3px', mr: '2px' }} fontSize={14}>
						<b>{getCurrencySymbol(activeTier?.currency || tier?.stripeProduct?.default_price?.currency)}</b>
					</Typography>

					{formatStripePricing(selectedUsage?.flatAmount)}
				</Typography>

				<Typography sx={{ minHeight: '1.5rem' }} variant="body2">
					{tier?.pricingDescriptionMtd}
				</Typography>
			</Box>
		);
	}
};

TierPricing.propTypes = {
	currency: PropTypes.string,
	isLoadingActiveSubscription: PropTypes.bool,
	tier: PropTypes.shape({
		pricingDescriptionMtd: PropTypes.string,
		stripeProduct: PropTypes.shape({
			default_price: PropTypes.shape({ currency: PropTypes.string })
		})
	}).isRequired,
	activeTier: PropTypes.shape({
		currency: PropTypes.string,
	}),
	selectedUsage: PropTypes.shape({
		flatAmount: PropTypes.number
	})
};
