import React, { createContext, useContext, useMemo } from "react";
import { PropTypes } from 'prop-types';
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import "./sortable-item.css";

const SortableItemContext = createContext({
	attributes: {},
	listeners: undefined,
	ref() { }
});

export function SortableItem({ children, id }) {
	const {
		attributes,
		isDragging,
		listeners,
		setNodeRef,
		setActivatorNodeRef,
		transform,
		transition
	} = useSortable({ id });

	const context = useMemo(
		() => ({
			attributes,
			listeners,
			ref: setActivatorNodeRef
		}),
		[attributes, listeners, setActivatorNodeRef]
	);
	const style = {
		opacity: isDragging ? 0.4 : undefined,
		transform: CSS.Translate.toString(transform),
		transition,
		padding: 0,
		marginTop: '3px',
		marginBottom: '3px',
		// backgroundColor: 'green',
		listStyle: 'none',
		display: 'flex',
		alignItems: 'center',

	};

	return (
		<SortableItemContext.Provider value={context}>
			<li ref={setNodeRef} style={style}>{children}</li>
		</SortableItemContext.Provider>
	);
}

SortableItem.propTypes = {
	id: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	])
};

export function DragHandle() {
	const { attributes, listeners, ref } = useContext(SortableItemContext);

	return (
		<button className="DragHandle" {...attributes} {...listeners} ref={ref}>
			<svg viewBox="0 0 20 20" width="12">
				<path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
			</svg>
		</button>
	);
}
