import React, { useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

import { SortableOverlay } from './sortable-overlay';
import { DragHandle, SortableItem } from './sortable-item';

export function SortableList({ items, onChange, renderItem }) {
	const [active, setActive] = useState(null);
	const activeItem = useMemo(
		() => items?.find((item) => item.id === active?.id),
		[active, items]
	);

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
	);

	return (
		<DndContext
			sensors={sensors}
			onDragStart={({ active }) => setActive(active)}
			onDragEnd={({ active, over }) => {
				if (over && active.id !== over?.id) {
					const activeIndex = items.findIndex(({ id }) => id === active.id);
					const overIndex = items.findIndex(({ id }) => id === over.id);
					onChange(arrayMove(items, activeIndex, overIndex));
				}
				setActive(null);
			}}
			onDragCancel={() => { setActive(null); }}
		>
			<SortableContext items={items}>
				<ul role='application' style={{ padding: 0, listStyle: 'none' }}>
					{items.map((item) => (
						<React.Fragment key={item.id}>{renderItem(item)}</React.Fragment>
					))}
				</ul>
			</SortableContext>

			<SortableOverlay>
				{activeItem ? renderItem(activeItem) : null}
			</SortableOverlay>
		</DndContext >
	);
}

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;

SortableList.propTypes = {
	items: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.arrayOf(PropTypes.object)
	]),
	onChange: PropTypes.func,
	renderItem: PropTypes.func,
};
