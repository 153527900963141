import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate, useParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Unstable_Grid2 as Grid } from '@mui/material';

import { getWebhookPromptsForKlaviyoAccount } from 'api/klaviyo-accounts';
import { setPrompt, clearPrompt, setEditorType, EDITOR_TYPES } from "services/redux/slicers/slicers.prompt-editor";

import { MetadataEditor } from "components/shared-components/prompt-editor/metadata-editor";
// import { ConfigurationSliders } from "components/shared-components/prompt-editor/prompt-configuration-sliders";
import { TextEditor } from "components/shared-components/prompt-editor/text-editor";
import { FinishButton } from "components/shared-components/prompt-editor/finish-button";
import { GoBackButton } from "components/shared-components/prompt-editor/back-button";
import { StartNewPromptButton } from "components/shared-components/prompt-editor/start-new-prompt-button";
import { PromptSelectorTable } from "components/shared-components/prompt-editor/prompt-selector-table";
import { SystemInfoEditor } from 'components/shared-components/prompt-editor/system-info-editor';
import { GptModelSelector } from 'components/shared-components/prompt-editor/gpt-model-selector';
import { AccordionCard } from 'components/shared-components/accordion-card';

import { KlaviyoResultPreview } from './preview/klaviyo-result-preview';
import { createEmptyKlaviyoPrompt, isKlaviyoPromptReadyToBeUpserted, validateTemplateForKlaviyoPrompt } from '../klaviyo-utilities';
import { StaticBackupTextEditor } from './static-backup-text-editor';
import { KlaviyoWebhookValueCopier } from './klaviyo-webhook-value-copier';
import { PERMISSIONS } from 'config/permissions';
import { ProductIdPathSelector } from 'components/shared-components/prompt-editor/product-id-path-selector';

export const KlaviyoPromptBuilder = () => {
	const { klaviyoAccountId } = useParams();
	const dispatch = useDispatch();
	const promptToUpsert = useSelector((state) => state.promptEditor.prompt);
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);

	useEffect(() => {
		// Setting the prompt editor type first!
		dispatch(setEditorType(EDITOR_TYPES.KLAVIYO_WEBHOOK));

		// Cleanup function
		// return () => dispatch(clearPrompt());
	}, [dispatch]);

	const goToPromptTableOverview = () => {
		dispatch(clearPrompt());
		// TODO: fix
		// navigate(`/accounts/${accountId}/klaviyo/${klaviyoAccountId}/flows`);
	};

	const startNewPrompt = () => {
		const prompt = createEmptyKlaviyoPrompt({ klaviyoAccountId });
		dispatch(setPrompt(prompt));
	};

	if (!promptToUpsert) {
		return (
			<Grid container spacing={1}>
				<Grid xs={12}>
					{/* New prompt will be initiated from here */}
					{currentlySelectedAccount.permissions[PERMISSIONS.klaviyoWebhookWrite] === true && (
						<StartNewPromptButton startNew={startNewPrompt} />
					)}
				</Grid>

				<Grid xs={12}>
					{/* Existing prompt is set in redux here */}
					<PromptSelectorTable getPrompts={() => getWebhookPromptsForKlaviyoAccount({ klaviyoAccountId })} />
				</Grid>
			</Grid>
		);
	} else {
		return (
			<Grid container spacing={1}>
				<Grid container xs={12} justifyContent='space-between' flexDirection='row'>
					<Grid xs={4}>
						<GoBackButton goBack={goToPromptTableOverview} text='Prompts' />
					</Grid>

					<Grid container flexDirection={'row'} xs={8} justifyContent={'end'} >
						<Grid>
							<KlaviyoWebhookValueCopier />
						</Grid>
						<Grid>
							<KlaviyoResultPreview />
						</Grid>
					</Grid>
				</Grid>

				<Grid xs={12} lg={7.5}>
					<TextEditor>
						<GptModelSelector />
						<ProductIdPathSelector />
						{/* <ConfigurationSliders /> */}
					</TextEditor>

					<AccordionCard>
						<SystemInfoEditor />
						<StaticBackupTextEditor />
					</AccordionCard>
				</Grid>

				<Grid xs={12} lg={4.5} container alignContent={'start'}>
					<Grid xs={12}>
						<MetadataEditor />
					</Grid>

					<Grid xs={12}>
						<FinishButton
							buttonVisibility={isKlaviyoPromptReadyToBeUpserted}
							validateTemplate={validateTemplateForKlaviyoPrompt}
						/>
					</Grid>
				</Grid>
			</Grid >
		);
	}
};
