import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { TextField, Typography, Card, Grid, Autocomplete, CircularProgress } from '@mui/material';

import { updatePrompt } from 'services/redux/slicers/slicers.prompt-editor';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getKlaviyoMetrics } from 'api/klaviyo-accounts';

export const MetadataEditor = ({ extraFields = [] }) => {
	const dispatch = useDispatch();
	const { klaviyoAccountId } = useParams();
	const prompt = useSelector((state) => state.promptEditor.prompt);

	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const loading = open && options.length === 0;
	const [autocompleteMetric, setAutocompleteMetric] = useState(null);

	useEffect(() => {
		// Get metric
		if (klaviyoAccountId) {
			getKlaviyoMetrics(klaviyoAccountId)
				.then(resp => {
					setOptions(resp.data);
					// Set metric if one was set in prompt
					resp.data?.forEach(metric => {
						if (metric.id === prompt.klaviyo_metric_id) {
							setAutocompleteMetric(metric)
						}
					});
					console.log('resp', resp)
				})
				.catch(err => console.log('error getting klaviyo metrics', err))
		}
	}, []);

	if (!prompt) return;

	const textChanged = (e) => {
		const key = e.target.id;
		const value = e.target.value;
		dispatch(updatePrompt({ key, value }));
	};

	return (
		<Card sx={{ bgcolor: '#2a2e35', p: 2 }}>
			<Typography color="white" variant="h5" sx={{ textAlign: 'center', mb: 2 }}>
				Metadata
			</Typography>

			<Grid container>
				<Grid xs={12} item>
					{/* Name */}
					<TextField
						id="name"
						required
						fullWidth
						label="Name"
						variant="filled"
						value={prompt.name}
						onChange={textChanged}
						sx={{ mr: 2, '& .MuiInputBase-input': { color: 'white' } }}
					/>
				</Grid>

				<Grid xs={12} item>
					{/* Description */}
					<TextField
						multiline
						fullWidth
						id="description"
						label="Description"
						variant="filled"
						value={prompt.description}
						onChange={textChanged}
						sx={{ mr: 2, mt: 2, '& .MuiInputBase-input': { color: 'white' } }}
					/>
				</Grid>

				<Grid xs={12} item>
					{/* Klaviyo Metric */}
					<Autocomplete
						id="search-metrics"
						sx={{ mr: 2, mt: 2 }}
						open={open}
						fullWidth
						value={autocompleteMetric}
						onChange={(_, newMetric) => {
							setAutocompleteMetric(newMetric)
							// console.log('changed',);
							// if (newMetric) {
							dispatch(updatePrompt({ key: 'klaviyo_metric_id', value: newMetric?.id || '' }));
							// }
						}}
						onOpen={() => { setOpen(true); }}
						onClose={() => { setOpen(false); }}
						isOptionEqualToValue={(option) => option?.id === prompt.klaviyo_metric_id}
						getOptionLabel={(option) => option?.attributes?.name}
						options={options}
						loading={loading}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Metrics"
								sx={{ '& .MuiInputBase-input': { color: 'white' } }}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<Fragment>
											{loading ? <CircularProgress color="primary" size={20} /> : null}
											{params.InputProps.endAdornment}
										</Fragment>
									),
								}}
							/>
						)}
					/>
				</Grid>

				{extraFields.map(field => (
					<Grid xs={12} item key={field} sx={{ pt: 2 }}>
						{field}
					</Grid>
				))}
			</Grid>
		</Card>
	);
};

MetadataEditor.propTypes = {
	extraFields: PropTypes.arrayOf(PropTypes.object.isRequired)
};
