import axios from "axios";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PageLoader } from "components/page-loader";

import { startLoading } from "services/redux/slicers/slicers.auth";

// const XANO_API_GROUP_BASE_URL = process.env.REACT_APP_XANO_LEADDRIVE_AUTH_URL;
// const REDIRECT_URI = process.env.REACT_APP_AUTH0_REDIRECT_URI;
// const REACT_APP_XANO_BRANCH = process.env.REACT_APP_AUTH0_REDIRECT_URI;
const {
	REACT_APP_XANO_BRANCH,
	REACT_APP_AUTH0_REDIRECT_URI,
	REACT_APP_XANO_LEADDRIVE_AUTH_URL
} = process.env;

export const AuthLoginPage = () => {
	const dispatch = useDispatch(); // Redux

	const loading = useSelector((state) => state.auth.loading); // Redux
	// const [searchParams] = useSearchParams(); // React Rotuer Dom
	const [error, setError] = useState({}); // State


	useEffect(() => {
		// Start loading
		dispatch(startLoading());

		// Get the auth url from the backend and then redirect the user to it
		axios.get(
			REACT_APP_XANO_LEADDRIVE_AUTH_URL + '/oauth/auth0/init',
			{
				headers: { 'X-Branch': REACT_APP_XANO_BRANCH, },
				params: { redirect_uri: REACT_APP_AUTH0_REDIRECT_URI }
			}
		)
			.then(resp => {
				// Redirect the user
				console.log('redirecting user');
				window.location.href = resp.data.authUrl;
			})
			.catch(err => {
				// Log and set error
				console.error('Error getting auth url for auth', err);
				setError(err.response);
			})


	}, [dispatch]);

	if (loading) return <PageLoader />;
	else if (error) return (<div>Error Login Page Authing. Take screenshot and send to admin <br /><br /> {JSON.stringify(error)}</div>);
	else return (<div>Unkown login page auth error. Take screenshot and contact admin</div>);
};