import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

// import { KlaviyoAccountsTable } from 'components/account-detail-page/tabs/klaviyo-page/klaviyo-account/klaviyo-accounts-table';
// import { AddNewKlaviyoAccount } from 'components/account-detail-page/tabs/klaviyo-page/klaviyo-account/add-new-klaviyo-account';
import { KlaviyoPromptBuilder } from 'components/account-detail-page/tabs/klaviyo-page/prompt-editor-page/klaviyo-prompt-builder';
import { KlaviyoAccountDetails } from 'components/account-detail-page/tabs/klaviyo-page/klaviyo-account/klaviyo-account-details';
import { SubpageNavigation } from 'components/account-detail-page/sub-page-navigation';
import { GoBackButton } from 'components/shared-components/prompt-editor/back-button';
// import { CampaignEditorPage } from './campaign-editor-page';
import { PERMISSIONS } from 'config/permissions';
// import { StoreProductsPage } from './store-products-page';

const DETAIL_SUBPAGES = [
	{
		label: 'Flows',
		id: 'flows',
		permissionsAny: [PERMISSIONS.klaviyoWebhookRead, PERMISSIONS.klaviyoWebhookWrite],
		component: <KlaviyoPromptBuilder />
	},
	// {
	// 	label: 'Products',
	// 	id: 'products',
	// 	permissionsAny: [PERMISSIONS.klaviyoCampaignRead, PERMISSIONS.klaviyoWebhookRead],
	// 	component: <StoreProductsPage />
	// },
	// {
	// 	label: 'Campaigns',
	// 	id: 'campaigns',
	// 	permissionsAny: [PERMISSIONS.klaviyoCampaignRead, PERMISSIONS.klaviyoCampaignWrite],
	// 	component: <CampaignEditorPage />
	// },
	{
		label: 'Details',
		id: 'details',
		component: <KlaviyoAccountDetails />
	}
];


export const KlaviyoAccountDetailPage = () => {
	const navigate = useNavigate();
	function goBack() { navigate('../../', { relative: 'path' }); }

	return (
		<Box>
			<GoBackButton goBack={goBack} text={'Accounts'} />
			<SubpageNavigation subpages={DETAIL_SUBPAGES} />
		</Box>
	);
};
