import { forwardRef, useImperativeHandle, useState } from 'react';
import { PropTypes } from 'prop-types';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Unstable_Grid2 as Grid, IconButton, Modal, Paper, Typography, Divider } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';

import { formatUsage } from './usage-selector';
import './tier.css'
import { formatStripePricing } from './tier-pricing';

const MODAL_BOX_STYLE = {
	position: 'absolute',
	left: '50%',
	top: '10%',
	transform: 'translate(-50%, 0%)',
	width: 'max-content',
	minWidth: 'min(50vw, 850px)',
	maxWidth: 'min(95vw, 1000px)'
};

const CURRENCY_OPTIONS = { style: "currency", currency: "EUR" };

const InvoiceRow = ({ label, amount, variant = 'body2', dividerStyling = { my: 1 } }) => (
	<>
		{dividerStyling !== null && <Grid xs={12} sx={dividerStyling}><Divider /></Grid>}
		<Grid sm={6} xs={8}><Typography variant={variant}>{label}</Typography></Grid>
		<Grid sm={6} xs={4}><Typography variant={variant} textAlign={'end'}>{formatStripePricing(amount, CURRENCY_OPTIONS)}</Typography></Grid>
	</>
);


InvoiceRow.propTypes = {
	label: PropTypes.string,
	amount: PropTypes.number,
	variant: PropTypes.string,
	dividerStyling: PropTypes.object
};

export const SummaryPricingModal = forwardRef(({ activeTier, selectedUsage, onUpdateSubscription }, ref) => { // eslint-disable-line
	const [open, setOpen] = useState(false);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [selectedTier, setSelectedTier] = useState({});
	const [previewedInvoice, setPreviewedInvoice] = useState({});
	// const [invoiceTiers(), setInvoiceTiers] = useState({});

	useImperativeHandle(ref, () => ({
		openSummaryModal: (selectedTier, previewedInvoice) => {
			setSelectedTier(selectedTier);
			setPreviewedInvoice(previewedInvoice);

			// const currentTier = previewedInvoice.lines.data.find(line => line.amount !== 0 && line.proration === true);
			// const nextTier = previewedInvoice.lines.data.find(line => line.amount !== 0 && line.proration === false);
			// setInvoiceTiers({ currentTier, nextTier });
			handleModalOpen();
		},
		closeSummaryModal: () => handleModalClose(),
		setLoading: (isLoading) => setLoading(isLoading),
		setError: (error) => setError(error),
	}));

	const handleModalOpen = () => setOpen(true);
	const handleModalClose = () => setOpen(false);

	return (
		<Modal open={open}>
			<Box sx={MODAL_BOX_STYLE} role='preview-modal'>
				<IconButton
					size='small'
					aria-label='delete'
					sx={{ color: 'white', px: 0 }}
					onClick={handleModalClose}
				>
					<CloseIcon />
				</IconButton>

				<Paper sx={{ py: 3, px: 4, maxHeight: 'calc(90vh)' }}>
					<Typography variant='h5' textAlign={'center'}>
						Plan Summary
					</Typography>

					<Grid container xs={12} mt={2}>
						{/* Left Side Plan Overview */}
						<Grid container xs={12} sm={5} py={2} alignContent={'flex-start'} justifyContent={'space-between'} flexDirection={'column'}>
							<Grid xs={12}>
								<Grid xs={12}>
									<Box className={'price-tile'} sx={{ p: '0.5rem', border: '1px solid ', borderRadius: '1rem', mb: '1.25rem' }}>
										<Typography variant='h6'>Current Plan ex. tax</Typography>
										<Typography>{activeTier.header} </Typography>

										<Typography textAlign={'end'}>{formatUsage(activeTier.usage)} mails / mo</Typography>
										<Typography textAlign={'end'}><b>{formatStripePricing(activeTier?.priceTiers?.find(lvl => lvl.upTo === activeTier.usage)?.flatAmount, CURRENCY_OPTIONS)}</b></Typography>
									</Box>
								</Grid>
								<Grid xs={12}>
									<Box className={'price-tile'} sx={{ p: '0.5rem', border: '1px solid ', borderRadius: '1rem', }}>
										<Typography variant='h6'>Target Plan ex. tax</Typography>
										<Typography>{selectedTier.header}</Typography>

										<Typography textAlign={'end'}>{formatUsage(selectedUsage?.upTo)} mails / mo</Typography>
										<Typography textAlign={'end'}><b>{formatStripePricing(selectedUsage?.flatAmount, CURRENCY_OPTIONS)}</b></Typography>
									</Box>
								</Grid>
							</Grid>

							<Grid xs={12}>
								<IconButton sx={{ p: 0, pt: '0.5rem' }}><HelpIcon /></IconButton>
							</Grid>
						</Grid>

						<Grid xs={0} sm={1} container justifyContent={'center'}>
							<Box sx={{ width: '2px', backgroundColor: '#00000059', height: '100%' }}></Box>
						</Grid>

						{/* Right Side Invoice Overview */}
						<Grid container xs={12} sm={6} py={2} alignContent={'flexStart'}>
							{/* <InvoiceRow
								label={'Target Plan ex. tax'}
								variant='h6'
								dividerStyling={null}
								amount={invoiceTiers?.nextTier?.amount_excluding_tax}
							/> */}

							{/* <InvoiceRow
								label={'Current Plan ex. tax'}
								variant='h6'
								amount={activeTier?.priceTiers?.find(lvl => lvl.upTo === activeTier.usage)?.flatAmount * -1}
							/> */}

							<InvoiceRow
								label={'Subtotal ex. tax'}
								dividerStyling={{ mt: 3, mb: 0.75 }}
								amount={previewedInvoice.subtotal_excluding_tax}
							/>

							{previewedInvoice?.discount?.coupon && (
								<InvoiceRow
									// dividerStyling={{ mt: 3, mb: 0.75 }}
									label={previewedInvoice.discount.coupon.name}
									amount={previewedInvoice.discount.coupon.amount_off * -1}
								/>
							)}


							<InvoiceRow
								label={'Total ex. tax'}
								amount={previewedInvoice.total_excluding_tax}
							/>


							<InvoiceRow
								label={'Tax'}
								amount={previewedInvoice.tax}
							/>

							<InvoiceRow
								label={'Total'}
								amount={previewedInvoice.total}
							/>

							{previewedInvoice.starting_balance !== previewedInvoice.ending_balance && (
								<InvoiceRow
									label={'Applied balance'}
									amount={previewedInvoice.starting_balance - previewedInvoice.ending_balance}
								/>
							)}

							<InvoiceRow
								label={'Amount due'}
								variant='h6'
								amount={previewedInvoice.amount_due}
							/>

							{
								previewedInvoice.starting_balance > previewedInvoice.ending_balance && (
									<Typography sx={{ mt: '3.5rem', mb: '0.5rem' }} variant='body2'>
										<i>
											Balance added to your account<br />
											<a href={`mailto:support@leaddrive.ai?subject=${encodeURIComponent("Balance Refund")}`}
											>Contact</a> for refund.
										</i>
									</Typography>
								) || (<Box sx={{ mt: '4rem', mb: '0.5rem' }} />)}

							<LoadingButton
								onClick={onUpdateSubscription}
								loading={loading}
								variant='contained'
								fullWidth sx={{ p: '1rem' }}
							>
								Finish
							</LoadingButton>

							{error && (
								<Typography color={'error'} sx={{ mt: 1 }}>
									Error: {error}
								</Typography>
							)}
						</Grid>
					</Grid>
				</Paper>
			</Box >
		</Modal >
	);
});

SummaryPricingModal.propTypes = {
	currency: PropTypes.string,
	isLoadingActiveSubscription: PropTypes.bool,
	tier: PropTypes.shape({
		pricingDescriptionMtd: PropTypes.string
	}),
	activeTier: PropTypes.shape({
		currency: PropTypes.string,
		header: PropTypes.string,
		usage: PropTypes.number,
		priceTiers: PropTypes.array,
	}),
	selectedUsage: PropTypes.shape({
		flatAmount: PropTypes.number,
		upTo: PropTypes.number
	})
};