import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Skeleton, Typography } from "@mui/material";

import { KlaviyoEmailTemplateSelector } from "./klaviyo-email-template-selector";
import { updatePromptPreview } from "services/redux/slicers/slicers.prompt-editor";
import { renderKlaviyoTemplate } from "api/klaviyo-accounts";

export const KlaviyoEmailPreview = () => {
	const dispatch = useDispatch();
	const { klaviyoAccountId } = useParams();

	const [error, setError] = useState(null);
	const [renderedTemplate, setRenderedTemplate] = useState(null);
	const [storedTemplateKey, setStoredTemplateKey] = useState(null);

	const prompt = useSelector((state) => state.promptEditor.prompt);
	const promptResult = useSelector((state) => state.promptEditor.preview?.promptResult);
	const templateToRender = useSelector((state) => state.promptEditor.preview?.templateToRender);

	useEffect(() => {
		if (!prompt.id) { return; }
		const sessionStorageKey = `${prompt.id}_stored_klaviyo_template`;
		setStoredTemplateKey(sessionStorageKey);

		// If a template has been stored in sessionStorage, set it in redux
		const storedSelectedTemplate = sessionStorage.getItem(sessionStorageKey);
		if (storedSelectedTemplate) {
			dispatch(updatePromptPreview({ key: 'templateToRender', value: JSON.parse(storedSelectedTemplate) }));
		}
	}, [dispatch, prompt.id]);

	useEffect(() => {
		// If "templateToRender" was cleared, clear it from sessionStorage aswell 
		if (!templateToRender) {
			sessionStorage.setItem(storedTemplateKey, null);
			return;
		}

		// Match the data that is set on the profile when a real klaviyo job is run
		const data = {
			person: {
				[`main_body_${prompt.id}`]: promptResult,
				[`main_body_${prompt.id}_fallback`]: prompt.backup_text_static,
			}
		};
		// console.log('Template Rendering Data', data);

		// Store it as the template to render
		sessionStorage.setItem(storedTemplateKey, JSON.stringify(templateToRender));

		// Clear the rendered template to show skeleton
		setRenderedTemplate(null);

		// Clear error
		setError(null);

		// Render the template with values
		renderKlaviyoTemplate({ klaviyoAccountId, templateId: templateToRender.id, data })
			.then((resp) => {
				// console.log('resp', resp);
				if (resp.data.errors) {
					throw new Error(resp.data.errors[0].detail)
				} else {
					setRenderedTemplate(resp.data.data);
				}
			})
			.catch(err => { setError(err); console.log('err', err); })
	}, [templateToRender, klaviyoAccountId, prompt, promptResult, storedTemplateKey]);

	if (error) {
		return (
			<Box>
				<Typography variant="h5">Error in Email Preview</Typography>
				<Typography>{error.message}</Typography>
			</Box>
		);
	} else {
		return (
			<Box>
				{/* "Select New" or list of clickable templates */}
				<KlaviyoEmailTemplateSelector />

				{/* If the template to render has been specified, but not rendered yet: show skeleton */}
				{templateToRender && !renderedTemplate && (
					<Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
						<Skeleton variant='rectangular' sx={{ width: 'inherit', height: 750, borderRadius: '8px', overflow: 'hidden' }} />
					</Box>
				)}

				{/* If the template to render has been specified and it has rendered: */}
				{templateToRender && renderedTemplate && (
					<div
						style={{ borderRadius: '8px', overflow: 'hidden' }}
						dangerouslySetInnerHTML={{ __html: renderedTemplate?.attributes?.html }}
					/>
				)}
			</Box>
		);
	}
};

KlaviyoEmailPreview.propTypes = {
};


// rgba(55,63,71,8%) 0px 2px 8px,rgba(55,63,71,12%) 0px 1px 2px