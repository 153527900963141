import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { compact, isEmpty, trimEnd } from 'lodash';
import { PropTypes } from 'prop-types';
import ReactJson from 'react-json-view'
import { Alert, Box, Button, CircularProgress, Divider, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { getKlaviyoEventsForMetric } from 'api/klaviyo-accounts';
import { updatePromptPreview } from 'services/redux/slicers/slicers.prompt-editor';

/*
	Create the prompt-valid value for the copied value
	So if "event.Items[0]" was clicked namespace would be [false, 'event', 'Items', '0'] (false is because we haven't supplied a root name)
		that should be returned as {{event.Items[0]}}
*/
export function getKlaviyoClipboardPath({ namespace }) {
	const compactedNamespace = compact(namespace);

	let completedPath = compactedNamespace.reduce((formattedPath, subPath) => {
		const arrayIndex = parseInt(subPath);
		const illegalHandlebarjsIdentifiers = [" ", '-', '!', '"', '#', '%', '&', "'", '(', ')', '*', '+', ',', '.', '/', ';', '<', '=', '>', '@', '[', '\\', ']', '^', '`', '{', '|', '}', '~'];

		if (!arrayIndex && arrayIndex !== 0) {
			// Is not array index
			if (illegalHandlebarjsIdentifiers.some(identifier => subPath.includes(identifier))) {
				// Illegal string
				return formattedPath += `[${subPath}].`;
			} else {
				// Normal, legal string
				return formattedPath += subPath + '.';
			}
		} else {
			// Is array index = integer
			return formattedPath += `[${subPath}].`;
		}
	}, '');

	// Remove the last dot and wrap it in '{{' '}}'
	completedPath = `{{${trimEnd(completedPath, '.')}}}`;

	// Copy to clipboard
	navigator.clipboard.writeText(completedPath);
}

const PAYLOAD_TYPES = { STATIC: 'static', DYNAMIC: 'dynamic' };

export const JsonEditor = () => {
	const dispatch = useDispatch();
	const { klaviyoAccountId } = useParams();
	const [staticPayload, setStaticPayload] = useState('');
	const [payloadError, setPayloadError] = useState(null);
	const [prefferedPayloadType, setPrefferedPayloadType] = useState(null); // "static" | "dynamic" - PAYLOAD_TYPES

	const [dynamicPayloadsLength, setDynamicPayloadsLength] = useState(null);
	const [dynamicPayloadIndex, setDynamicPayloadIndex] = useState(null);
	const [dynamicPayloadError, setDynamicPayloadError] = useState(null);

	const [loading, setLoading] = useState(false);
	const { prompt, preview } = useSelector((state) => state.promptEditor);
	const localSessionStorageKey = prompt?.id ? `${prompt.id}_STATIC_SESSION_STORAGE_PAYLOADS` : null;
	const prefferedPayloadTypeStorageKey = prompt?.id ? `${prompt.id}_PREFFERED_PAYLOAD_TYPE` : null;
	const dynamicPayloadsSessionStorageKey = prompt?.klaviyo_metric_id ? `${prompt.klaviyo_metric_id}_DYNAMIC_SESSION_STORAGE_PAYLOADS` : null;
	const dynamicPayloadIndexSessionStorageKey = prompt?.klaviyo_metric_id ? `${prompt.klaviyo_metric_id}_DYNAMIC_SESSION_STORAGE_INDEX` : null;

	/**
	 * Set the payload in the redux shared object so its set for the other steps
	 * @param {string} payload - The payload to parse and set.
	 * @returns {void}
	 */
	const setPopulatedPrompt = useCallback((payload) => {
		dispatch(updatePromptPreview({ key: 'payload', value: payload }));
	}, [dispatch]);

	/**
	 * Parses and sets the local Klaviyo payload.
	 * @param {string} payload - The payload to parse and set.
	 * @returns {void}
	 */
	const parseAndSetLocalKlaviyoPayload = useCallback((payload) => {
		try {
			// Set the parsed payload to be displayed in the json-viewer
			setPopulatedPrompt(JSON.parse(payload));

			// Store raw data so the user doesn't have to keep re-pasting the payload
			sessionStorage.setItem(localSessionStorageKey, payload);
			setPayloadError(null);
		} catch (error) {
			console.log('error', error);
			setPopulatedPrompt({});
			setPayloadError(error.message);
		}
	}, [localSessionStorageKey, setPopulatedPrompt]);

	const togglePrefferedPayloadType = useCallback(() => {
		const prefferedPayloadType = sessionStorage.getItem(prefferedPayloadTypeStorageKey);
		const type = prefferedPayloadType === PAYLOAD_TYPES.STATIC ? PAYLOAD_TYPES.DYNAMIC : PAYLOAD_TYPES.STATIC;
		sessionStorage.setItem(prefferedPayloadTypeStorageKey, type);
		setPrefferedPayloadType(type);
		setPayload();
	}, [prefferedPayloadTypeStorageKey]);

	/**
	 * Sets dynamic payload by index.
	 * @param {number} dynamicTargetIndex - The index of the dynamic payload to set - current if none is passed
	 * @returns {void}
	 */
	const setPayload = useCallback((dynamicTargetIndex = null) => {
		const dynamicPayloadsRaw = sessionStorage.getItem(dynamicPayloadsSessionStorageKey);
		if (dynamicPayloadsRaw) {
			const dynamicPayloads = JSON.parse(dynamicPayloadsRaw);
			let index = dynamicTargetIndex;
			if (index === null) { index = sessionStorage.getItem(dynamicPayloadIndexSessionStorageKey) || 0; }

			const minSafeIndex = parseInt(Math.min(index, dynamicPayloads.length) || 0); // Out of bounds catch
			const prefferedPayloadTypeLocal = sessionStorage.getItem(prefferedPayloadTypeStorageKey);

			sessionStorage.setItem(dynamicPayloadIndexSessionStorageKey, minSafeIndex); // Store last used index for continuity in sessionstorage
			setDynamicPayloadIndex(minSafeIndex); // Store last used index in state
			setDynamicPayloadsLength(dynamicPayloads.length);

			const staticPayload = sessionStorage.getItem(localSessionStorageKey);
			if (prefferedPayloadTypeLocal === PAYLOAD_TYPES.STATIC) {
				parseAndSetLocalKlaviyoPayload(staticPayload || '{}');
			} else {
				setPopulatedPrompt(dynamicPayloads[minSafeIndex]); // Store the prompt to show in the previewed prompt
			}
		}
	}, [
		localSessionStorageKey,
		prefferedPayloadTypeStorageKey,
		dynamicPayloadsSessionStorageKey,
		dynamicPayloadIndexSessionStorageKey,
		setPopulatedPrompt,
		parseAndSetLocalKlaviyoPayload
	]);

	useEffect(() => {
		// Set static payload
		const storedStaticPayload = sessionStorage.getItem(localSessionStorageKey);
		if (storedStaticPayload) { setStaticPayload(storedStaticPayload); }
		if (!prompt.klaviyo_metric_id) { parseAndSetLocalKlaviyoPayload(storedStaticPayload); }

		if (!prompt.klaviyo_metric_id || !prompt.id) { return; }
		const prefferedPayloadTypeLocal = sessionStorage.getItem(prefferedPayloadTypeStorageKey);
		if (prefferedPayloadTypeLocal) { setPrefferedPayloadType(prefferedPayloadTypeLocal); }

		const storedPayloads = sessionStorage.getItem(dynamicPayloadsSessionStorageKey);
		const dynamicHasPayloads = storedPayloads && storedPayloads !== '[]';

		// Payload is stored in cache, don't get new ones
		if (dynamicHasPayloads) { setPayload(); return; }

		// Clear prompt and payload length
		setLoading(true);
		setPopulatedPrompt('');
		setDynamicPayloadsLength(null);

		// No payload stored for this metric - get from klaviyo
		getKlaviyoEventsForMetric(klaviyoAccountId, prompt.klaviyo_metric_id)
			.then(resp => {
				const payloads = resp.data;

				// Store the payloads in session storage
				sessionStorage.setItem(dynamicPayloadsSessionStorageKey, JSON.stringify(payloads));
				if (Array.isArray(payloads)) {
					if (!prefferedPayloadTypeLocal) {
						// If a preffered type hasn't been set, set it to dynamic, now that a payload is avaialble
						setPrefferedPayloadType(PAYLOAD_TYPES.DYNAMIC);
						sessionStorage.setItem(prefferedPayloadTypeStorageKey, PAYLOAD_TYPES.DYNAMIC);
					}
					setPayload();
				}
			})
			.catch(err => {
				console.log('error getting klaviyo metrics', err);
				setDynamicPayloadError(err.message);
			})
			.finally(() => setLoading(false));
	}, [
		prompt.id,
		klaviyoAccountId,
		localSessionStorageKey,
		prompt.klaviyo_metric_id,
		prefferedPayloadTypeStorageKey,
		dynamicPayloadsSessionStorageKey,
		dynamicPayloadIndexSessionStorageKey,
		setPopulatedPrompt,
		parseAndSetLocalKlaviyoPayload
		// setPayload,
	]);

	/**
	 * On textfield payload change, set the raw text and try to foramt it 
	 * @param {Event} e - The event object representing the payload change event.
	 * @returns {void}
	 */
	function payloadChanged(e) {
		// Set the preffered payload type of static when user starts typing
		if (prefferedPayloadType != PAYLOAD_TYPES.STATIC) {
			setPrefferedPayloadType(PAYLOAD_TYPES.STATIC);
			sessionStorage.setItem(prefferedPayloadTypeStorageKey, PAYLOAD_TYPES.STATIC);
		}

		setStaticPayload(e.target.value);
		if (e.target.value != '') { parseAndSetLocalKlaviyoPayload(e.target.value); }
		else {
			setPopulatedPrompt({});
			setPayloadError(null);
			sessionStorage.setItem(localSessionStorageKey, '');
		}
	}

	function DynamicPayloadNavButton({ direction }) {
		const isBack = direction === 'back';
		const disabled = loading || dynamicPayloadIndex === (isBack ? 0 : dynamicPayloadsLength - 1) || !dynamicPayloadsLength;
		function onClick() {
			if (prefferedPayloadType != PAYLOAD_TYPES.DYNAMIC) {
				setPrefferedPayloadType(PAYLOAD_TYPES.DYNAMIC);
				sessionStorage.setItem(prefferedPayloadTypeStorageKey, PAYLOAD_TYPES.DYNAMIC);
			}
			setPayload(dynamicPayloadIndex + (isBack ? -1 : 1));
		}

		return (
			<Button
				disabled={disabled} variant='contained' sx={{ width: 'fit-content' }}
				onClick={onClick}
			>
				{isBack && (<><ArrowBackIosNewIcon />Back</>)}
				{!isBack && (<>Next<ArrowForwardIosIcon /></>)}
			</Button>
		)
	} DynamicPayloadNavButton.propTypes = { direction: PropTypes.oneOf(['back', 'next']) };

	return (
		<Box>
			{prompt.klaviyo_metric_id && (
				<div style={{ position: 'relative' }}>
					{!dynamicPayloadError && (
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<DynamicPayloadNavButton direction={'back'} />

							{(preview?.payload && dynamicPayloadsLength > 0) && (
								<Typography>
									Payload {dynamicPayloadIndex + 1}/{dynamicPayloadsLength}
								</Typography>
							) || (!dynamicPayloadsLength && prefferedPayloadType) && (
								<Typography sx={{ opacity: loading ? 0.4 : 1 }}>No Payloads Found - try and paste manually</Typography>
							)}

							<DynamicPayloadNavButton direction={'next'} />
						</div>
					)}

					{dynamicPayloadError && (
						<Alert severity='error'>Error getting dynamic payloads: {dynamicPayloadError}</Alert>
					)}

					{loading && <CircularProgress color="inherit" sx={{ position: 'absolute', top: 'calc(50% - 20px)', left: 'calc(50% - 20px)' }} />}
					<Divider sx={{ marginTop: 1 }} />
				</div>
			)}

			{/* If a formatted payload hasn't been set, let the user know */}
			{
				((!preview.payload || isEmpty(preview.payload))) && (
					<Typography sx={{ p: 2 }}>
						Paste a Klaviyo Webhook &quot;Preview&quot; payload to preview
					</Typography>
				) || (
					<ReactJson
						src={preview.payload}
						enableClipboard={getKlaviyoClipboardPath}
						name={false}
						collapsed={3}
						indentWidth={8}
						quotesOnKeys={false}
						displayArrayKey={true}
						displayDataTypes={false}
						collapseStringsAfterLength={30}
						style={{ paddingLeft: '1rem', paddingTop: '1rem' }}
					/>
				)
			}

			<div style={{ display: 'flex', marginTop: '1rem' }}>
				<TextField
					label='Klaviyo Static Webhook Payload'
					variant='filled'
					fullWidth
					style={{ fontFamily: 'Inter' }}
					error={Boolean(payloadError)}
					helperText={payloadError}
					value={staticPayload}
					onChange={payloadChanged}
				/>
				{prompt.klaviyo_metric_id && prefferedPayloadType && (
					<ToggleButtonGroup
						exclusive
						size='small'
						color='primary'
						sx={{ marginLeft: 2 }}
						value={prefferedPayloadType}
						onChange={togglePrefferedPayloadType}
						aria-label='Default Payload Type'
					>
						<ToggleButton value={PAYLOAD_TYPES.DYNAMIC}>Dynamic</ToggleButton>
						<ToggleButton value={PAYLOAD_TYPES.STATIC}>Static</ToggleButton>
					</ToggleButtonGroup>
				)}
			</div >
		</Box>
	);
};
