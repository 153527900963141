import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PropTypes } from 'prop-types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ButtonGroup, Button, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, Box } from "@mui/material";

import { updatePromptPreview } from "services/redux/slicers/slicers.prompt-editor";

export const SelectNewTemplateSplitButton = ({ templates }) => {
	const dispatch = useDispatch();
	const anchorRef = useRef(null);

	const [open, setOpen] = useState(false);
	const [showingFallback, setShowingFallback] = useState(false);
	const [cachedPromptResult, setCachedPromptResult] = useState(null);

	const templateToRender = useSelector((state) => state.promptEditor.preview?.templateToRender);
	const promptResult = useSelector((state) => state.promptEditor.preview?.promptResult);


	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) { return; }
		setOpen(false);
	};

	// When a template has been selected from the dropdown, set it in redux to be rendered
	const templateSelected = (template) => {
		dispatch(updatePromptPreview({ key: 'templateToRender', value: template }));
		setOpen(false);
	};

	/**
	 * Toggle between showing the generated text or the fallback static text
	 * The template will update automatically via hooks when state is changed
	 */
	const toggleFallback = () => {
		if (!cachedPromptResult) { setCachedPromptResult(promptResult); }
		if (showingFallback) {
			dispatch(updatePromptPreview({ key: 'promptResult', value: cachedPromptResult }));
		} else {
			dispatch(updatePromptPreview({ key: 'promptResult', value: null }));
		}
		setShowingFallback(!showingFallback);
	};

	return (
		<Box sx={{ mb: 1 }}>
			<ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
				<Button onClick={() => templateSelected(null)}>Render New</Button>
				<Button onClick={toggleFallback}>{showingFallback ? 'Show AI Text' : 'Show Fallback'}</Button>

				{templates && (
					<Button
						size="small"
						aria-controls={open ? 'split-button-menu' : undefined}
						aria-expanded={open ? 'true' : undefined}
						aria-label="select merge strategy"
						aria-haspopup="menu"
						onClick={handleToggle}
					>
						<ArrowDropDownIcon />
					</Button>
				)}
			</ButtonGroup>

			{templates && (
				<Popper
					sx={{ zIndex: 1 }}
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					disablePortal
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin:
									placement === 'bottom' ? 'center top' : 'center bottom',
							}}
						>
							<Paper>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList id="split-button-menu" autoFocusItem>
										{templates.map(template => (
											<MenuItem
												key={template.id}
												selected={template.id === templateToRender?.id}
												onClick={() => templateSelected(template)}
											>
												{template.attributes.name}
											</MenuItem>
										))}
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			)}
		</Box>
	);

};

SelectNewTemplateSplitButton.propTypes = {
	templates: PropTypes.arrayOf(PropTypes.object)
};