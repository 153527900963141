import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from "lodash";
import {
	Box,
	Modal,
	Paper,
	Button,
	TextField,
	IconButton,
	// Select,
	// MenuItem,
	Grid,
	// InputLabel,
	// FormControl,
	Typography,
	Divider,
	// FormHelperText
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import { createAccount } from "api/account";
import { addNewAccount, setCurrentlySelectedAccount } from "services/redux/slicers/slicers.accounts";
import { setOpenSuccess, setOpenError } from 'services/redux/slicers/slicers.snackbar';
import { messageFromSuspectedXanoError } from "api/axios-api";

const MODAL_BOX_STYLE = {
	position: 'absolute',
	top: '35%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minWidth: 400
};

const TEXTS_INITIAL_STATE = {
	name: '',
	email: '',
	type: 'agency_managed'
};

export const AddNewAccount = () => {
	const dispatch = useDispatch(); // Redux
	const [open, setOpen] = useState(false); // State
	// const agencyBeingInspected = useSelector((state) => state.accounts.agencyBeingInspected); // Redux
	// const userPermissions = useSelector((state) => state.auth.xpermissions); // Redux
	// const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);
	// const user = useSelector((state) => state.auth.user); // Redux
	const userAccount = useSelector((state) => state.auth.userAccount); // Redux
	const [errors, setErrors] = useState({});
	const [texts, setTexts] = useState(TEXTS_INITIAL_STATE); // State

	const handleModalOpen = () => setOpen(true);
	const handleModalClose = () => {
		setTexts(TEXTS_INITIAL_STATE);
		setOpen(false);
	};

	const handleCreate = async () => {
		try {
			const { name, email, type } = texts;

			const valid = validateInputs();
			if (!valid) { return; }

			// If the user is inspecting an agency and creates an account, set that as the managing agency
			// const managingAgency = agencyBeingInspected != null ? agencyBeingInspected.id : user.account_id;

			// Create the account
			const resp = await createAccount({
				name, email, type,
				managing_agency: userAccount.id
			});
			console.log('created account', resp);
			// if (agencyBeingInspected === null) {
			// Add account to redux list of all accounts
			dispatch(addNewAccount(resp.data));

			// Set newly created account as currently selected
			dispatch(setCurrentlySelectedAccount(resp.data));
			// } else {
			// 	// Add the nested account to the agency being inspected
			// 	dispatch(setAgencyBeingInspected({
			// 		...agencyBeingInspected,
			// 		managedAccounts: [...agencyBeingInspected.managedAccounts, resp.data]
			// 	}));
			// }

			// Close creation modal
			handleModalClose();

			// Display success snackbar
			dispatch(setOpenSuccess({ title: "Created New Account" }));

			// Clear the input
			setTexts(TEXTS_INITIAL_STATE);
		} catch (error) {
			// Display snackbar with error
			dispatch(setOpenError({
				title: "Error Creating Account",
				message: messageFromSuspectedXanoError(error)
			})); console.error('error creating account', error);
		}
	};

	const validateInputs = () => {
		const { name, email, type } = texts;
		// console.log({ name, email, type });
		const errors = {};
		if (!name) { errors.name = 'Name Required'; }
		if (!email) { errors.email = 'Email Required'; }
		else if (email.search(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) { errors.email = 'Invalid email'; } // eslint-disable-line
		if (!type) { errors.type = 'Type Requried'; }

		setErrors(errors);
		return isEqual(errors, {});
	};

	const onChange = (e) => {
		const { id, name, value } = e.target;
		const key = id ? id : name;
		const _texts = { ...texts };
		_texts[key] = value;
		setTexts(_texts);
	};

	// // User can create agencies if they have the required permissions and if it ins't nested
	// const canCreateAgency = () => {
	// 	return currentlySelectedAccount?.permissions[PERMISSIONS.] === true
	// 		&& agencyBeingInspected === null;
	// };

	return (
		<>
			<Button
				sx={{
					mb: 2,
					boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px'
				}}
				variant="contained"
				endIcon={<AddIcon />}
				onClick={handleModalOpen}
			>
				Add
			</Button>

			<Modal open={open}>
				<Box sx={MODAL_BOX_STYLE}>
					<IconButton
						aria-label='delete'
						sx={{ color: 'white' }}
						onClick={handleModalClose}
					>
						<CloseIcon fontSize="large" />
					</IconButton>

					<Paper sx={{ p: 3 }}>
						<Grid container flexDirection="column" gap={2}>
							<Divider>
								<Typography variant="h5">
									Account Details
								</Typography>
							</Divider>

							<TextField
								required
								id="name"
								value={texts.name}
								onChange={onChange}
								error={errors.name}
								helperText={errors.name}
								label="Company Name"
							/>
							<TextField
								required
								id="email"
								onChange={onChange}
								value={texts.email}
								error={errors.email}
								helperText={errors.email}
								label="Contact Email"
							/>
							<TextField
								id="agency"
								disabled
								onChange={onChange}
								value={userAccount?.name}
								label="Agency"
							/>

							{/* {canCreateAgency() && (
								<FormControl variant="filled" error={errors.type}>
									<InputLabel id="account_type_select">Type *</InputLabel>
									<Select
										labelId="account_type_select"
										name="type"
										required
										label="Type *"
										value={texts.type}
										onChange={onChange}
									>
										<MenuItem value="agency_managed">Account</MenuItem>
										<MenuItem value="individual">Individual</MenuItem>
										<MenuItem value="agency">Agency</MenuItem>
									</Select>
									{errors.type && <FormHelperText>Error</FormHelperText>}
								</FormControl>
							)} */}

							<Button
								sx={{ mt: 2, py: 2 }}
								variant="contained"
								onClick={handleCreate}
								endIcon={<AddIcon />}
							>
								Create Account
							</Button>
						</Grid>
					</Paper>
				</Box>
			</Modal>
		</>
	);
};
