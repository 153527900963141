// import { useEffect, useState } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { KlaviyoAccountsTable } from 'components/account-detail-page/tabs/klaviyo-page/klaviyo-account/klaviyo-accounts-table';
import { AddNewKlaviyoAccount } from 'components/account-detail-page/tabs/klaviyo-page/klaviyo-account/add-new-klaviyo-account';
// import { KlaviyoPromptBuilder } from 'components/account-detail-page/tabs/klaviyo-page/prompt-editor-page/klaviyo-prompt-builder';
// import { KlaviyoAccountDetails } from 'components/account-detail-page/tabs/klaviyo-page/klaviyo-account/klaviyo-account-details';
// import { SubpageNavigation } from 'components/account-detail-page/sub-page-navigation';
// import { GoBackButton } from 'components/shared-components/prompt-editor/back-button';
// import { CampaignEditorPage } from './campaign-editor-page';
// import { PERMISSIONS } from 'config/permissions';

export const KlaviyoAccounts = () => {
	return (
		<Box>
			<AddNewKlaviyoAccount />
			<KlaviyoAccountsTable />
		</Box>
	);

};
