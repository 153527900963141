import { PromptEditorPage } from 'components/account-detail-page/tabs/csv-parse/prompt-editor-page/prompt-editor-page';
import { UploaderPage } from 'pages/uploader-page';
import { FilesOverviewPage } from 'pages/files-overview-page';
import { SubpageNavigation } from 'components/account-detail-page/sub-page-navigation';

const SUBPAGES = [
	{
		label: 'All Prompts',
		id: 'all_prompts',
		component: <PromptEditorPage />
	},
	{
		label: 'AI Uploader',
		id: 'ai_uploader',
		component: <UploaderPage />
	},
	{
		label: 'Completed Files',
		id: 'completed_files',
		component: <FilesOverviewPage />
	}
];

export const CSVParseMain = () => {
	return <SubpageNavigation subpages={SUBPAGES} />
};
