import { PropTypes } from 'prop-types';
import { Box, Chip, MenuItem, Select, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';

// import ErrorIcon from '@mui/icons-material/Error';
// import ReportIcon from '@mui/icons-material/Report';

// const DOWNGRADE_WARNING = `
// You have currently used more than this usage supports. 
// Subscribing to this tier would mean you can't generate any new content this billing cycle.
// Selecting means the 
// `.trim();

export const formatUsage = (number) => {
	if (!number) return;
	if (number < 1000) {
		return number;
	} else if (number >= 1_000 && number < 1_000_000) {
		return `${number / 1000}K`
	} else if (number >= 1_000_000) {
		return `${number / 1_000_000}M`
	}
};

export const UsageSelector = ({ tier, onUsageChanged, selectedUsage, activeTier, disabled, isLoadingActiveSubscription }) => {
	const usageLevels = tier.priceTiers?.filter(lvl => lvl.upTo !== null);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		// if there is an active usage, and its current usage is larger than the tier's
		// smallets usage, set the lowest possible to select
		// if the activeTier is this tier, set the usage to that of the account's
		if ((activeTier && usageLevels) && !loaded) {
			setLoaded(true)
			if (activeTier.id === tier.id) {
				const subscripbedUsageLevel = usageLevels.find(lvl => lvl.upTo > activeTier.usage);
				onUsageChanged(subscripbedUsageLevel);
			} else {
				const nextUpgradableUsageLevel = usageLevels.find(lvl => lvl.upTo > activeTier.actualUsage);
				onUsageChanged(nextUpgradableUsageLevel);
			}
		}
	}, [activeTier, usageLevels, loaded, tier, onUsageChanged]);

	const handleUsageChange = (e) => {
		console.log('handleUsageChange', e.target.value, usageLevels.find(lvl => lvl.upTo === e.target.value));
		onUsageChanged(usageLevels.find(lvl => lvl.upTo === e.target.value))
	};

	// if (isLoadingActiveSubscription || tier.id === 'good_tier') {
	if (isLoadingActiveSubscription) {
		return (
			<Box sx={{ my: '0.5rem', height: '57px' }}>
				<Skeleton sx={{ maxWidth: 'none', maxHeight: 'none', scale: '1 1.6', height: 'inherit' }} />
			</Box>
		)
	}
	else if (!usageLevels) { return (<Box sx={{ height: 'calc(54px +  1rem)' }} />); }
	else if (usageLevels.length === 1) {
		return (
			<Typography
				variant='body2'
				sx={{
					color: 'white',
					padding: '1rem',
					userSelect: 'none',
					paddingLeft: 0,
					my: '0.75rem'
				}}
			>
				{`${formatUsage(usageLevels[0].upTo)} mails /mo`}
			</Typography>
		);
	} else {
		return (
			<Select
				fullWidth
				value={selectedUsage?.upTo}
				onChange={handleUsageChange}
				disabled={disabled}
				sx={{ my: 1, fontSize: '1.5rem', display: 'flex' }}
			>
				{usageLevels?.map(lvl => (
					<MenuItem
						key={lvl.upTo}
						value={lvl.upTo}
						selected={true}
						// disabled={
						// 	activeTier && (activeTier.actualUsage > lvl.upTo ||
						// 		activeTier.id === tier.id && lvl.upTo === activeTier.usage)
						// }
						disabled={activeTier?.id === tier.id && lvl.upTo === activeTier.usage}
					>
						{formatUsage(lvl.upTo) + ' mails /mo'}
						{activeTier && activeTier.id === tier.id && lvl.upTo === activeTier.usage && (
							<Chip label="Active" icon={<CheckIcon color='success' />} sx={{ ml: 1 }} />
						)}
					</MenuItem>
					// <MenuItem
					// 	key={lvl.usage}
					// 	value={lvl.usage}
					// 	sx={{
					// 		backgroundColor: activeTier.usage > lvl.usage && '#e2e3e5',
					// 	}}
					// >
					// 	{formatNumber(lvl.usage) + ' mails /mo'}
					// 	{activeTier.usage > lvl.usage && (
					// 		<Tooltip title={
					// 			<Typography
					// 				variant='body2'
					// 				style={{ whiteSpace: 'pre-line' }}
					// 			>
					// 				{DOWNGRADE_WARNING}
					// 			</Typography>
					// 		}>
					// 			<Box sx={{ display: 'flex', alignContent: 'center', ml: 1 }}>
					// 				{/* <Chip label='asd' color="warning" size='small' sx={{ ml: 1 }} /> */}
					// 				<ReportIcon color='warning' />
					// 			</Box>
					// 		</Tooltip>
					// 	)}
					// </MenuItem>


					// <Box display={'flex'} sx={{ px: '16px', py: '6px' }} key={lvl.usage} value={lvl.usage}>
					// 	<Typography variant='body2'>
					// 		{formatNumber(lvl.usage) + ' mails /mo'}
					// 	</Typography>
					// 	{!disabled && activeTier.usage > lvl.usage && (
					// 		<button>asd</button>
					// 	)}
					// </Box>
				))}
			</Select>
		);
	}
};

UsageSelector.propTypes = {
	tier: PropTypes.shape({
		id: PropTypes.string.isRequired,
		header: PropTypes.string.isRequired,
		subHeader: PropTypes.string.isRequired,
		featureHeader: PropTypes.string.isRequired,
		features: PropTypes.array.isRequired,
		priceTiers: PropTypes.array,
		level: PropTypes.number,
		extraCardCssClasses: PropTypes.array,
	}).isRequired,
	activeTier: PropTypes.shape({
		id: PropTypes.string.isRequired,
		header: PropTypes.string.isRequired,
		subHeader: PropTypes.string.isRequired,
		featureHeader: PropTypes.string.isRequired,
		features: PropTypes.array.isRequired,
		usageLevels: PropTypes.array,
		level: PropTypes.number,
		actualUsage: PropTypes.number,
		usage: PropTypes.number,
		extraCardCssClasses: PropTypes.array,
	}),
	onUsageChanged: PropTypes.func.isRequired,
	selectedUsage: PropTypes.object,
	disabled: PropTypes.bool,
	isLoadingActiveSubscription: PropTypes.bool,

};
