import { useDispatch, useSelector } from 'react-redux';
import { TextField, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

import { updatePrompt } from "services/redux/slicers/slicers.prompt-editor";
import { useEffect, useState } from 'react';
import { PERMISSIONS } from 'config/permissions';

// If this isn't applied, the top border will be a bright white line, this dulls it abit
const DarkTopBorderAccordion = styled(Accordion)`:before { background-color: #3e3f42 }`;

export const StaticBackupTextEditor = () => {
	const dispatch = useDispatch(); // Redux
	const prompt = useSelector((state) => state.promptEditor.prompt); // Redux
	const [needToUpgrade, setNeedToUpgrade] = useState(false);
	// const userPermissions = useSelector((state) => state.auth.xpermissions); // Redux
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected); // Redux


	useEffect(() => {
		setNeedToUpgrade(!(currentlySelectedAccount?.permissions[PERMISSIONS.promptConfigBackupTextWrite] === true));
	}, [currentlySelectedAccount]);

	const textChanged = (e) => dispatch(updatePrompt({ key: 'backup_text_static', value: e.target.value }));

	if (!prompt) { return null; }

	return (
		<DarkTopBorderAccordion sx={{ color: 'white', bgcolor: '#2a2e35' }} disableGutters={true}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ '& .MuiAccordionSummary-root': { backgroundColor: 'grenn', borderTop: '10px', borderTopWidth: '1rem' } }}>
				<Typography>Backup Static Content</Typography>
			</AccordionSummary>

			<AccordionDetails sx={{ opacity: needToUpgrade ? 0.5 : 1 }}>
				{needToUpgrade && (
					<Typography variant='body2'>
						<i>This feature is not available on your current tier.</i>
					</Typography>
				)}

				<TextField
					value={prompt?.backup_text_static}
					multiline={true}
					maxRows={20}
					disabled={needToUpgrade}
					label="Fallback text for the email incase of missing content..."
					onChange={textChanged}
					sx={{
						width: '100%',
						'& .Mui-disabled': { color: 'gray' },
						'& .Mui-disabled.root': { color: 'gray' },
						'& .MuiInputBase-input': { color: 'white' },
					}}
				></TextField>
			</AccordionDetails>
		</DarkTopBorderAccordion>
	);
};

StaticBackupTextEditor.propTypes = {};