import { useSelector } from 'react-redux';
import { Avatar, Box, IconButton, Typography } from '@mui/material';

import { usePopover } from 'utils/use-popover';
import { AccountPopover } from './account-popover';

export const AccountSelector = () => {
	const accountPopover = usePopover();
	const userAccount = useSelector((state) => state.auth.userAccount);
	const user = useSelector((state) => state.auth.user);

	function stringAvatar(name) {
		if (!name) return;
		const nameParts = name.split(' ');
		const letters = [nameParts[0][0]];
		if (nameParts[1]) { letters.push(nameParts[1][0]); }
		else { letters.push(nameParts[0][0]); }
		return letters.join('');
	}

	return (
		<>
			<Box
				p={1} marginTop={'auto'} display={'flex'} alignItems={'center'}
				ref={accountPopover.anchorRef}
				onClick={accountPopover.handleOpen}
			>
				<IconButton
					sx={{
						borderRadius: 1,
						display: 'flex',
						flex: 1,
						overflow: 'hidden',
						justifyContent: 'start',
						backgroundColor: 'rgba(255, 255, 255, 0.04)',
						'&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.04)' }
					}}
				>
					<Avatar sx={{ width: 20, height: 20, fontSize: '0.6rem', bgcolor: '#7e57c2' }} alt={user.name}>
						{stringAvatar(user.name)}
					</Avatar>
					<Typography
						variant='body2'
						fontWeight={600}
						sx={{ ml: 1, color: '#9da4ae', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
					>
						{userAccount.name}
					</Typography>
				</IconButton>
			</Box>

			<AccountPopover
				open={accountPopover.open}
				onClose={accountPopover.handleClose}
				anchorEl={accountPopover.anchorRef.current}
			/>
		</>
	);
};