import React, { useState } from "react";
import { Unstable_Grid2 as Grid } from "@mui/material";

import { ColumnMapper } from "components/account-detail-page/tabs/csv-parse/uploader-page/column-mapper";
import { KeywordOverview } from "components/account-detail-page/tabs/csv-parse/uploader-page/keyword-overview";
import { CSVTablePreview } from "components/account-detail-page/tabs/csv-parse/uploader-page/csv-table-preview";
import { UploadFileButton } from "components/account-detail-page/tabs/csv-parse/uploader-page/upload-file-button";
import { PromptSelectorModal } from "components/account-detail-page/tabs/csv-parse/uploader-page/prompt-selector-modal";
import { ParseAndUploadButton } from "components/account-detail-page/tabs/csv-parse/uploader-page/parse-and-send-button";

export const UploaderPage = () => {
	const [papaCSV, setPapaCSV] = useState(null); // State
	const [selectedPrompt, setSelectedPrompt] = useState(null); // State
	const [keywordColumnMap, setKeywordColumnMap] = useState({}); // State. keyword: column

	return (
		<Grid container spacing={2}>
			<Grid xs={12}>
				<PromptSelectorModal setSelectedPrompt={setSelectedPrompt} />
				<UploadFileButton setPapaCSV={setPapaCSV} />
			</Grid>

			<Grid xl={6} md={12} xs={12}>
				<CSVTablePreview papaCSV={papaCSV} />
			</Grid>

			<Grid xl={4} md={8} xs={12}>
				<ColumnMapper
					papaCSV={papaCSV}
					prompt={selectedPrompt}

					keywordColumnMap={keywordColumnMap}
					setKeywordColumnMap={setKeywordColumnMap}
				/>
			</Grid>

			<Grid xl={2} md={4} xs={12}>
				<KeywordOverview
					prompt={selectedPrompt}
					keywordColumnMap={keywordColumnMap}
				/>

				<ParseAndUploadButton
					papaCSV={papaCSV}
					prompt={selectedPrompt}
					keywordColumnMap={keywordColumnMap}
				/>
			</Grid>
		</Grid>
	);
};
