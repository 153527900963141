import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { KlaviyoAccountsTable } from 'components/account-detail-page/tabs/klaviyo-page/klaviyo-account/klaviyo-accounts-table';
import { AddNewKlaviyoAccount } from 'components/account-detail-page/tabs/klaviyo-page/klaviyo-account/add-new-klaviyo-account';
import { KlaviyoPromptBuilder } from 'components/account-detail-page/tabs/klaviyo-page/prompt-editor-page/klaviyo-prompt-builder';
import { KlaviyoAccountDetails } from 'components/account-detail-page/tabs/klaviyo-page/klaviyo-account/klaviyo-account-details';
import { SubpageNavigation } from 'components/account-detail-page/sub-page-navigation';
import { GoBackButton } from 'components/shared-components/prompt-editor/back-button';
// import { CampaignEditorPage } from './campaign-editor-page';
import { PERMISSIONS } from 'config/permissions';

const DETAIL_SUBPAGES = [
	{
		label: 'Flows',
		id: 'flows',
		permissionsAny: [PERMISSIONS.klaviyoCampaignRead, PERMISSIONS.klaviyoWebhookRead],
		component: <KlaviyoPromptBuilder />
	},
	{
		label: 'Details',
		id: 'account_details',
		component: <KlaviyoAccountDetails />
	},
	// {
	// 	label: 'Campaigns',
	// 	id: 'campaigns',
	// 	permissionsAny: [PERMISSIONS.klaviyoCampaignRead, PERMISSIONS.klaviyoCampaignWrite],
	// 	component: <CampaignEditorPage />
	// }
];

export const KlaviyoPage = () => {
	const navigate = useNavigate();
	const { accountId, klaviyoAccountId } = useParams();
	const [viewToRender, setViewToRender] = useState();
	// const userAccount = useSelector((state) => state.auth.userAccount); // Redux

	useEffect(() => {

		if (accountId && !klaviyoAccountId) { setViewToRender('table-overview'); }
		else if (accountId && klaviyoAccountId) { setViewToRender('klaviyo-detail'); }
		else {
			// console.log(123);
			setViewToRender('non-managed')
		}
	}, [accountId, klaviyoAccountId]);

	// Go back to account selection
	const goBack = () => navigate(`/accounts/${accountId}/klaviyo`);

	if (viewToRender === 'table-overview') {
		return (
			<Box>
				<AddNewKlaviyoAccount />
				<KlaviyoAccountsTable />
			</Box>
		);
	} else if (viewToRender === 'klaviyo-detail') {
		return (
			<Box>
				<GoBackButton goBack={goBack} text={'Accounts'} />
				<SubpageNavigation subpages={DETAIL_SUBPAGES} />
			</Box>
		);
	} else if (viewToRender === 'non-managed') {
		return (
			<SubpageNavigation subpages={DETAIL_SUBPAGES} />
		);
	} else return (<div>Missing account and/or klaviyo account</div>);
};
