import { axiosInstance } from 'api/axios-api';


/*
	Get the Stripe products and their current default prices
*/
export const getStripeProducts = (productIds) =>
	new Promise((resolve, reject) => {
		const path = '/stripe/products';
		axiosInstance.get(path, { params: { internal_product_ids: productIds } })
			.then(resp => resolve(resp))
			.catch(error => reject(error));
	});
