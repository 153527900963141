import { useSelector } from 'react-redux';
import { Alert, Typography, Unstable_Grid2 as Grid } from '@mui/material';

import { Limits } from 'components/home-page/limits';

export const OverviewPage = () => {
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);

	return (
		<Grid container sx={{ backgroundColor: '' }} pt={'7rem'}>
			<Grid xs={12}>
				<Typography variant='h3' textAlign={'center'}>
					Welcome to Leaddrive.ai
				</Typography>
			</Grid>

			{currentlySelectedAccount?.stripe_subscription_status === 'canceled' && (
				<Alert variant="filled" severity="error" sx={{ maxWidth: '400px', mt: 2 }}>
					Your Leaddrive subscription has unfortunately been revoked <br />
					This could be due to a variety of reasons such as declined payment, expired payment information, or other issues related to your chosen payment method.

					<br /><br />
					What Now:
					<br />
					1. <b>Update Payment Information</b>: Please update your payment details at your earliest convenience to restore full access. You can do this by navigating to the &quot;Billing&quot; section within your account settings.
					<br /><br />
					2. <b>Contact Support:</b> If you believe this to be an error or if you&apos;re facing any difficulties updating your payment information, our support team is here to assist. Please reach out to us through the &quot;Help&quot; section or via <a href='mailto:support@leaddrive.ai?subject=Cancelled%20Subscription%20Question'>support</a>
					<br /><br />
					We understand the inconvenience this may cause and are dedicated to assisting you in regaining access to Leaddrive as swiftly as possible.
					<br /><br />

					Thank you for your prompt attention to this matter.
				</Alert>
			)}

			{['trialing', 'active', 'past_due'].includes(currentlySelectedAccount?.stripe_subscription_status) && (
				<>
					{currentlySelectedAccount?.stripe_subscription_status === 'past_due' && (
						<Alert variant="filled" severity="warning" sx={{ maxWidth: '400px', mt: 2 }}>
							Your payment for Leaddrive subscription is past due. <br />
							To ensure uninterrupted access to all the features and benefits of our service, we kindly request that you update your payment information at your earliest convenience.
						</Alert>
					)}
					
					<Limits />
				</>
			)}
		</Grid>
	);
};
