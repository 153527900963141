// import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	// Alert, AlertTitle, FormControl, InputLabel, MenuItem, Select,
	TextField, Typography
} from "@mui/material";
import { updatePrompt } from "services/redux/slicers/slicers.prompt-editor";

export const ProductIdPathSelector = () => {
	const dispatch = useDispatch();
	const prompt = useSelector((state) => state.promptEditor.prompt);
	// const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);

	// const [error, setError] = useState(null);
	// const [models, setModels] = useState(null);


	function textChanged(e) {
		dispatch(updatePrompt({ key: 'gpt_config.payload_product_identifier', value: e.target.value }));
	}

	// if (!models && !error || (models && models.length === 0)) { return null; }
	return (
		<div>

			<Typography variant="body2" sx={{ mt: 1 }}>
				The searchable identifier for the product
			</Typography>
			<TextField
				label="ex. event.extra.line_items.product_id"
				sx={{ width: '20rem', '& .MuiInputBase-input': { color: 'white' } }}
				value={prompt?.gpt_config.payload_product_identifier}
				onChange={textChanged}

			// color={'#fff'}
			// value={klaviyoAccount.shopify_credentials.store_url}
			// onChange={e => onChange(e, 'shopify_credentials.store_url')}
			/>
		</div>
	);
};

ProductIdPathSelector.propTypes = {};
