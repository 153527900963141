// import { useEffect, useRef, useState } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { fetchEventSource } from '@microsoft/fetch-event-source';

import { updatePromptPreview } from 'services/redux/slicers/slicers.prompt-editor';
import './pulse-bar.css';

const REACT_APP_DO_BACKEND_DROPLET_BASE_URL = process.env.REACT_APP_DO_BACKEND_DROPLET_BASE_URL;

export const PromptAnswerPreview = () => {
	const dispatch = useDispatch();
	const [error, setError] = useState(null);
	const [streamToolCalls, setStreamToolCalls] = useState(null);

	const prompt = useSelector((state) => state.promptEditor.prompt);
	const promptPreview = useSelector((state) => state.promptEditor.preview);
	const accessToken = useSelector((state) => state.auth.accessToken);
	const previewText = useSelector((state) => state.promptEditor.preview.promptResult);
	const populatedPrompt = useSelector((state) => state.promptEditor.preview?.populatedPrompt);
	const generatingPromptResult = useSelector((state) => state.promptEditor.preview.generatingPromptResult);
	// const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);

	useEffect(() => {
		console.log('stream stuff', { prompt, populatedPrompt });
		const setLoading = (state) => {
			dispatch(updatePromptPreview({ key: 'generatingPromptResult', value: state }));
		};

		setLoading(true);

		// Stream Request body
		const body = {};
		body.aiPromptId = prompt.id;
		body.accountId = prompt.account_id;
		body.gpt_config = prompt.gpt_config;
		body.populatedPromptText = populatedPrompt.text;
		body.promptRules = populatedPrompt.prompt_rules || [];

		// For now, only add webhook data when developing the prompt chaining backend
		if (prompt.gpt_config?.payload_product_identifier) {
			body.klaviyoWebhookData = promptPreview.payload;
			body.prompt = prompt;
		}

		console.log('streaming....');

		// For closing the stream
		const abortController = new AbortController();

		// Open a connection for SSE to stream the AI response
		const streamUrl = REACT_APP_DO_BACKEND_DROPLET_BASE_URL + '/stream/prompt_preview';
		fetchEventSource(streamUrl,
			{
				method: 'post',
				body: JSON.stringify(body),
				headers: { 'xano-access-token': accessToken, 'Content-Type': 'application/json' },
				onmessage(event) {
					const { content, completed, toolCalls, error: streamError } = JSON.parse(event.data);
					dispatch(updatePromptPreview({ key: 'promptResult', value: content?.trim() }));
					if (streamError) { setError(JSON.stringify(streamError)); setLoading(false); }
					if (toolCalls != streamToolCalls) { setStreamToolCalls(toolCalls); }
					if (completed) { 
						console.log('streamToolCalls',streamToolCalls);
						setLoading(false); 
					}
				},
				onopen(res) { if (res.status !== 200) { setError('Error creating connection'); } },
				onclose() { setLoading(false); },
				signal: abortController.signal
			});

		// Cleanup function - clear the prompted result
		return () => {
			console.log('clearing');
			abortController.abort();
			setStreamToolCalls(null);
			dispatch(updatePromptPreview({ key: 'promptResult', value: null }));
		};
		// }, [dispatch, prompt, accessToken, generatingPromptResult, populatedPrompt, manualPopulatedPrompt]);
		// }, [dispatch, prompt, accessToken, generatingPromptResult, populatedPrompt, abortController]);
	}, []);

	return (
		<Box>
			{!error && (
				<Typography
					component={'div'}
					sx={{
						whiteSpace: 'pre-wrap',
						maxWidth: 'max-content',
						width: 'fit-content',
						// font: '1.2rem/1.4 Georgia, serif;',
						borderRadius: 1,
						backgroundColor: '#eee',
						padding: '1rem',
					}}>
					{previewText} {generatingPromptResult === true && <div className='bar pulse' />}
				</Typography>
			)}

			{streamToolCalls && (
				<div>
					<br />
					<Typography variant='body2'>AI Actions:</Typography>
					{streamToolCalls.map(call => (
						<div key={call.id} style={{ marginTop: '0.5rem' }}>
							<code>
								<b>Tool:</b> {call?.tool?.description} <br />
								<b>Param:</b> {call.call?.function?.arguments} <br />
							</code>
							<Typography variant='body2' sx={{
								backgroundColor: '#eee', whiteSpace: 'break-spaces',
								padding: '0.5rem', borderRadius: 1,
							}}>
								{call.resp}
							</Typography>
							{/* <br /> */}
						</div>
					))}
				</div>
			)}

			{error != null && (
				<Typography>
					<b>Error Occured:</b> <br />
					{error}
				</Typography>
			)}
		</Box >
	);
};

PromptAnswerPreview.propTypes = {
	// manualPopulatedPrompt: PropTypes.string
}