import React from 'react';
import PropTypes from 'prop-types'
import { Box, Link, Typography, Unstable_Grid2 as Grid } from '@mui/material';

export const WebsiteLayout = ({ children }) => {
	return (
		<Grid container xs={12} flexDirection={'column'} alignContent={'center'}>
			<Grid xs={12} container flexDirection={'row'}
				marginTop={-1}
				justifyContent={'center'}
				justifySelf={'center'}
			>
				<Box
					sx={{
						maxWidth: '1280px',
						height: '100px',
						backgroundColor: '',
						width: '-webkit-fill-available',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center'
					}}>
					<a href="https://leaddrive.ai">
						<img width={'200px'} height={'100px'} src="https://leaddrive.ai/wp-content/uploads/2023/11/Leaddrive-Logo-Sort.png" />
					</a>

					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Link marginRight={'15px'} color={'#000000'} href="https://app.leaddrive.ai/">
							<Typography fontSize={'14px'} fontWeight={500}>Login</Typography>
						</Link>

						{/* <Button
							variant='contained'
							onClick={() => window.location.href = ''}
							sx={{ padding: '12px 24px 12px 24px', borderRadius: '50px', fontWeight: 500 }}
						>
							Get Started
						</Button> */}
					</Box>
				</Box>
			</Grid>



			<Grid
				xs={12}
				marginTop={'2rem'}
				container flexDirection={'row'}
				justifyContent={'center'}
				justifySelf={'center'}
			>
				{children}
			</Grid>

			{/* Footer */}
			<Grid xs={12} sx={{
				display: 'flex',
				marginTop: '13rem',
				alignItems: 'center',
				marginBottom: '-1rem',
				justifyContent: 'center',
				width: 'calc(100% + 1rem)',
				// width: '100%',
				// position: 'absolute',
				left: 0,
				bottom: 0,
				backgroundColor: '#0b0b0b',
			}}>
				<Grid
					container
					xs={12}
					sx={{
						maxWidth: '1280px',
						paddingY: '8rem',
						width: '-webkit-fill-available',
						alignItems: 'center'
					}}>

					<Grid
						xs={12}
						sm={4}
						textAlign={'center'}
					>
						<a href="https://leaddrive.ai">
							<img width={"400px"} height={"200px"} src="https://leaddrive.ai/wp-content/uploads/2023/11/Leaddrive-Logo-hvid-1024x512.png" />
						</a>
						<Typography fontSize={'12px'} color={'white'}>
							Leaddrive.ai – © 2024 All rights reserved.
						</Typography>
					</Grid>

					<Grid
						xs={12}
						sm={2}
						display={'flex'}
						flexDirection={'column'}
						smOffset={'auto'}
						color={'white'}
					>
						<Typography fontSize={'24px'} pb={'12px'}>Join The Club</Typography>
						<Link color={'#ffffff'} href="https://app.leaddrive.ai/"><Typography fontSize={'16px'} fontWeight={400}>Login</Typography></Link>
						<Link color={'#ffffff'} href="https://app.leaddrive.ai/pricing"><Typography fontSize={'16px'} fontWeight={400}>Get Started</Typography></Link>
						<Link color={'#ffffff'} href="https://leaddrive.ai/#contact"><Typography fontSize={'16px'} fontWeight={400}>Contact Us</Typography></Link>
					</Grid>
				</Grid>
			</Grid>
		</Grid >
	);
};

WebsiteLayout.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.arrayOf(PropTypes.object)
	])
};
