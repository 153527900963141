import { useEffect } from "react";
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { Layout as DashboardLayout } from "layouts/dashboard/layout";
import { ErrorBoundary } from 'react-error-boundary'

import { ErrorBoundaryFallback } from "utils/error-boundary-fallback";
import { GlobalSnackbar } from "utils/global-snackbar";
import { ACCOUNT_TYPES } from "config/permissions";
import { setCurrentlySelectedAccount } from "services/redux/slicers/slicers.accounts";
// import { setUserPermissions } from "services/redux/slicers/slicers.auth";

export const AuthenticationGuard = ({ Component }) => {
	const navigate = useNavigate(); //  React Router 
	const dispatch = useDispatch(); // State 
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Redux
	const userAccount = useSelector((state) => state.auth.userAccount); // Redux

	// If someone isn't authenticated and they're trying to access secured page, redirect them
	useEffect(() => {
		if (!isAuthenticated) {
			// Redirect to /login
			navigate('/login');
		}

		// If the user's account is managed or individual, set the "currentlySelectedAccount" to the user's
		if (userAccount && [ACCOUNT_TYPES.individual, ACCOUNT_TYPES.managed].includes(userAccount.type)) {
			// console.log('setting user account as selected because its a managed or indv acc');
			dispatch(setCurrentlySelectedAccount(userAccount));
		}
	}, [navigate, isAuthenticated]);

	if (isAuthenticated) {
		return (
			<ErrorBoundary
				FallbackComponent={ErrorBoundaryFallback}
				onError={(error, errorInfo) => console.log({ error, errorInfo })}
				onReset={(details) => {
					// TODO: reset the state of your app
					console.log("reset state", details);
				}}
			>
				<DashboardLayout>
					<Component />
					<GlobalSnackbar />
				</DashboardLayout>
			</ErrorBoundary>
		);
	} else return (<div>not authed, but not caught in effect? Screenshot and send to admin</div>);
};

AuthenticationGuard.propTypes = {
	Component: PropTypes.func.isRequired
};
