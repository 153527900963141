import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { Alert, Button, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { getAccountDetails } from "api/account";
import { messageFromSuspectedXanoError } from "api/axios-api";

const MAX_RETRIES = 13;

/**
 * Constructs a URL for upgrading the waiting room based on the provided parameters.
 *
 * @param {number} targetQuantity - The target quantity for the upgrade.
 * @param {string} targetStripeProductId - The Stripe product ID for the target upgrade.
 * @returns {string} The constructed URL for upgrading the waiting room.
 *
 * @example
 * // Usage example:
 * const targetQuantity = 5;
 * const targetStripeProductId = 'stripeProductId123';
 * const upgradeUrl = upgradeWaitingRoomUrl(targetQuantity, targetStripeProductId);
 * // Output: "/pricing/confirmation?target_quantity=5&target_product=stripeProductId123"
 */
export const getUpgradeWaitingRoomUrl = (targetQuantity, targetStripeProductId, targetAccountId) => {
	const url = new URLSearchParams();
	url.set('target_quantity', targetQuantity);
	url.set('target_product', targetStripeProductId);
	url.set('target_account', targetAccountId);
	return '/pricing/confirmation?' + url.toString();
}

export const AccountUpgradeWaitingRoom = () => {
	// const user = useSelector((state) => state.auth.user);
	// const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);

	const [searchParams] = useSearchParams(); // React Rotuer Dom
	// const [nRetries, setNRetries] = useState(0);
	const [isUpdateDone, setUpdateDone] = useState(false);

	// const getAndCheckAccount = useCallback((params) => {
	// 	const { intervalKey, targetAccount, targetProduct, targetQuantity } = params;
	// 	console.log('getAndCheckAccount', { nRetries, isUpdateDone });
	// 	if (nRetries < MAX_RETRIES) {
	// 		if (isUpdateDone) { return; }
	// 		setNRetries(nRetries => nRetries + 1);
	// 		// setNRetries(nRetries + 1);
	// 		getAccountDetails(targetAccount)
	// 			.then(resp => {
	// 				console.log('getAccountDetails', resp);
	// 				const { product, klaviyo_usage_limit, stripe_subscription_status } = resp.data;
	// 				// if (
	// 				// 	(
	// 				// 		klaviyo_usage_limit == targetQuantity &&
	// 				// 		product.stripe_product_id === targetProduct
	// 				// 	)
	// 				// 	&& stripe_subscription_status === 'active'
	// 				// ) {
	// 				// 	console.log('clearing123');
	// 				// 	// setUpdateDone(true);
	// 				// 	// clearInterval(intervalKey);
	// 				// } else {
	// 				// 	setTimeout(() => {
	// 				// 		getAndCheckAccount(params);
	// 				// 	}, 5000)
	// 				// }
	// 				if (
	// 					(
	// 						klaviyo_usage_limit == targetQuantity && true === false &&
	// 						product.stripe_product_id === targetProduct
	// 					)
	// 					&& stripe_subscription_status === 'active'
	// 				) {
	// 					console.log('clearing123');
	// 					// setUpdateDone(true);
	// 					// clearInterval(intervalKey);
	// 				} else {
	// 					setTimeout(() => {
	// 						getAndCheckAccount(params);
	// 					}, 5000)
	// 				}
	// 			})
	// 			.catch(err => {
	// 				console.error('error getting accuont details', err);
	// 			})
	// 	} else {
	// 		clearInterval(intervalKey);
	// 		console.error('Checked account too many times');
	// 	}
	// }, [isUpdateDone, nRetries])

	// useEffect(() => {
	// 	const targetQuantity = searchParams.get('target_quantity');
	// 	const targetProduct = searchParams.get('target_product');
	// 	const targetAccount = searchParams.get('target_account');
	// 	if (!targetQuantity || !targetProduct) {
	// 		// TODO: redirect to homepage?
	// 		return;
	// 	}

	// 	// assert valid sub when updating
	// 	// 		Higher tier
	// 	// 		Higher usage max than the month's current
	// 	// calc usage based on billing period

	// 	let intervalKey;

	// 	// console.log('starting', nRetries, intervalKey);
	// 	if (!intervalKey && nRetries === 0) {
	// 		getAndCheckAccount({ intervalKey, targetAccount, targetProduct, targetQuantity });
	// 		// intervalKey = setInterval(() => {
	// 		// 	console.log('retry');
	// 		// 	getAndCheckAccount({ intervalKey, targetAccount, targetProduct, targetQuantity });
	// 		// }, 5000);
	// 		console.log('starting inverval', intervalKey);
	// 	}

	// 	// return () => {
	// 	// 	console.log('cleanup clearing');
	// 	// 	clearInterval(intervalKey);
	// 	// }
	// }, [setNRetries, searchParams, isUpdateDone, getAndCheckAccount, nRetries]);

	const [error, setError] = useState(null);
	const [intervalId, setIntervalId] = useState(null);
	const [attemps, setAttempts] = useState(0);

	useEffect(() => {
		const targetQuantity = searchParams.get('target_quantity');
		const targetProduct = searchParams.get('target_product');
		const targetAccount = searchParams.get('target_account');

		async function fetchData() {
			try {
				const response = await getAccountDetails(targetAccount);
				console.log('response', att, response.data);

				const { product, klaviyo_usage_limit, stripe_subscription_status } = response.data;
				if (
					targetQuantity == klaviyo_usage_limit
					&& targetProduct === product.stripe_product_id
					&& stripe_subscription_status === 'active'
				) {
					console.log('success clear');
					setUpdateDone(true);
					clearInterval(_intervalId);
				}
			} catch (error) {
				console.log('error', error);
				setError(messageFromSuspectedXanoError(error));
			}
		}

		let att = 0;
		function fetchOrClear() {
			if (att < MAX_RETRIES) {
				att += 1;
				setAttempts(atts => atts + 1);
				fetchData(att);
			}
			else { clearInterval(_intervalId); }
		}

		let _intervalId;
		if (att === 0 && !intervalId) {
			_intervalId = setInterval(fetchOrClear, 3000);
			setIntervalId(_intervalId);

			// Fetch on component mount
			fetchData();
		}
	}, [intervalId, searchParams]);


	const goHome = () => { window.location.href = '/overview'; }

	return (
		<Grid container sx={{ mt: '5rem' }} flexDirection={'column'} alignContent={'center'}>
			{isUpdateDone === false && (
				<Grid>
					<Typography variant="h5" textAlign={'center'}>
						Updating...
						{/* <Box sx={{ mb: '-0.9rem', pl: '5px', display: 'inline' }}>
						<svg xmlns="http://www.w3.org/2000/svg" 'margin- width="1em" height="1em" viewBox="0 0 24 24"><circle cx={4} cy={12} r={3} fill="currentColor"><animate id="svgSpinners3DotsBounce0" attributeName="cy" begin="0;svgSpinners3DotsBounce1.end+0.25s" calcMode="spline" dur="0.6s" keySplines=".33,.66,.66,1;.33,0,.66,.33" values="12;6;12"></animate></circle><circle cx={12} cy={12} r={3} fill="currentColor"><animate attributeName="cy" begin="svgSpinners3DotsBounce0.begin+0.1s" calcMode="spline" dur="0.6s" keySplines=".33,.66,.66,1;.33,0,.66,.33" values="12;6;12"></animate></circle><circle cx={20} cy={12} r={3} fill="currentColor"><animate id="svgSpinners3DotsBounce1" attributeName="cy" begin="svgSpinners3DotsBounce0.begin+0.2s" calcMode="spline" dur="0.6s" keySplines=".33,.66,.66,1;.33,0,.66,.33" values="12;6;12"></animate></circle></svg>
					</Box> */}
					</Typography>
					<Grid>
						<svg xmlns="http://www.w3.org/2000/svg" width={'25rem'} height={'25rem'} viewBox="0 0 24 24"><path fill="#1c2536" d="M2,12A10.94,10.94,0,0,1,5,4.65c-.21-.19-.42-.36-.62-.55h0A11,11,0,0,0,12,23c.34,0,.67,0,1-.05C6,23,2,17.74,2,12Z"><animateTransform attributeName="transform" dur="4s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"></animateTransform></path></svg>
					</Grid>

					<Typography variant="h5" textAlign={'center'}>
						Hang on
						{attemps > 4 && (
							<div>
								<br /> Its taking a little longer than usual.
							</div>
						)}
					</Typography>

					{error && (<Alert variant="filled" severity="error" sx={{ mt: '2rem' }}>{error}</Alert>)}
				</Grid>
			)}

			{isUpdateDone === true && (
				<Grid textAlign={'center'}>
					<Typography variant="h5" textAlign={'center'}>
						Congratulations
					</Typography>
					<Typography variant="h6" textAlign={'center'} mt={'3rem'}>
						Migration was successfull !
					</Typography>

					<Button onClick={goHome} variant="contained" sx={{ px: 4, py: 2, mt: 4 }}>
						Take me home
					</Button>
				</Grid>
			)}
		</Grid >
	);
}