import { useState } from 'react';
import { PropTypes } from 'prop-types';
import { IconButton, Stack, SvgIcon, Unstable_Grid2 as Grid, useMediaQuery } from "@mui/material";

// import { DevTools } from 'components/dev-tools/dev-tools';
import { SideNav } from './side-nav';
// import { TopNav } from './top-nav';
import Bars3Icon from '@heroicons/react/24/solid/Bars3Icon';

// const NODE_ENV = process.env.NODE_ENV;

export const Layout = (props) => {
	const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
	const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
	const { children } = props;
	const [openNav, setOpenNav] = useState(false);

	return (
		<>
			<Stack alignItems="center" direction="row" paddingX={2}>
				{!mdUp && (
					<IconButton onClick={() => setOpenNav(true)}>
						<SvgIcon fontSize="small"><Bars3Icon /></SvgIcon>
					</IconButton>
				)}
			</Stack>

			<Grid container sx={{ paddingLeft: smUp ? '1rem' : '0px', paddingTop: '1rem' }}>
				<Grid xs='auto'>
					<SideNav
						onSetOpenNav={(bool) => setOpenNav(bool)}
						open={openNav}
					/>
				</Grid>

				<Grid xs>{children}</Grid>

				{/* {NODE_ENV === 'staging' || NODE_ENV === 'development' && (
				<DevTools />
			)} */}

			</Grid >
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.array.isRequired
};