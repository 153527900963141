import { useState } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { Box, Modal, Paper, Button, IconButton, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Alert, FormHelperText } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { sendUserInvite } from "api/account";
import { setOpenError, setOpenSuccess } from 'services/redux/slicers/slicers.snackbar';
import { capitalize, isEqual } from "lodash";

const REDIRECT_URI = process.env.REACT_APP_AUTH0_REDIRECT_URI;
const MODAL_BOX_STYLE = {
	position: 'absolute',
	left: '50%',
	transform: 'translate(-50%, 30%)',
	minWidth: '35vw',
	maxWidth: '90vw',
};

const INVITE_ROLES = {
	READ: 'read',
	WRITE: 'write',
	ADMIN: 'admin'
};

export const InviteMember = (props) => {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [email, setEmail] = useState('');
	// const [error, setError] = useState();
	const [errors, setErrors] = useState({});
	const [inviteRole, setInviteRole] = useState(INVITE_ROLES.READ);
	// const user = useSelector((state) => state.auth.user);
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);


	const handleModalOpen = () => setOpen(true);
	const handleModalClose = () => setOpen(false);
	const mailChange = (e) => setEmail(e.target.value);

	const validateInputs = () => {
		const errors = {};
		if (!inviteRole) { errors.role = 'Role Required'; }
		if (!email) { errors.email = 'Email Required'; }
		else if (email.search(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) { errors.email = 'Invalid email'; } // eslint-disable-line
		setErrors(errors);
		return isEqual(errors, {});
	};

	const onInvite = () => {
		const valid = validateInputs();
		if (!valid) { return; }

		sendUserInvite(currentlySelectedAccount.id, email, inviteRole, REDIRECT_URI)
			.then(resp => {
				console.log('resp', resp);
				setErrors({});
				setOpen(false);
				setEmail('');
				props.refreshUsers();
				dispatch(setOpenSuccess({ title: 'Invited User' }))
			})
			.catch(error => {
				console.log('error', error);
				setErrors({ request: error.response.data.message || error.message });
				dispatch(setOpenError({ title: 'Error Inviting User' }));
			})
	};

	const roleChanged = (e) => setInviteRole(e.target.value);

	return (
		<div>
			<Button variant="contained" onClick={handleModalOpen} sx={{ mb: 3 }}>
				Invite Member
			</Button>

			{/* <Modal open={open} onClose={handleModalClose}> */}
			<Modal open={open}>
				<Box sx={MODAL_BOX_STYLE} role='preview-modal'>
					<IconButton
						size='small'
						aria-label='delete'
						sx={{ color: 'white' }}
						onClick={handleModalClose}
					>
						<CloseIcon />
					</IconButton>

					<Paper sx={{ p: 2, maxHeight: 'calc(90vh)', overflow: 'auto' }}>
						<Typography sx={{ mb: 2 }} variant='h6'>
							Invite team member
						</Typography>

						<Box display={'flex'}>
							<TextField
								label="Email"
								fullWidth
								error={errors.email}
								helperText={errors.email}
								value={email}
								onChange={mailChange}
								variant="filled"
							/>

							<Box sx={{ minWidth: '8rem', ml: '0.75rem' }}>
								<FormControl fullWidth error={errors.role}>
									<InputLabel id="role-label">Role</InputLabel>
									<Select
										value={inviteRole}
										labelId="role-label"
										label="Role"
										error={errors.role}
										onChange={roleChanged}
									>
										{Object.values(INVITE_ROLES).map(role => (
											<MenuItem key={role} value={role}>{capitalize(role)}</MenuItem>
										))}
									</Select>
									<FormHelperText>{errors.role}</FormHelperText>
								</FormControl>
							</Box>

							<Button onClick={onInvite} variant="contained" sx={{ ml: '2rem', minWidth: '6rem' }}>
								Invite
							</Button>
						</Box>

						{errors?.request && (<Alert variant="filled" severity="error">{errors.request}</Alert>)}
					</Paper>
				</Box>
			</Modal >
		</div >
	);
};

InviteMember.propTypes = {
	refreshUsers: PropTypes.func.isRequired
};