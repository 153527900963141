import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Box, Link } from "@mui/material";

import { getManagedAccountsForAccount } from "api/account";
import { messageFromSuspectedXanoError } from "api/axios-api";
import { StyledDataGrid } from "assets/styles/components/styled-data-grid";

import { setAllAccounts, setCurrentlySelectedAccount } from "services/redux/slicers/slicers.accounts";
import { setOpenError } from 'services/redux/slicers/slicers.snackbar';

const COLUMNS = ({ accountClicked }) => [
	{ field: 'id', headerName: 'ID', width: 120, headerAlign: 'center', align: 'center' },
	{
		field: 'name',
		headerName: 'Name',
		width: 250,
		renderCell: (params) => {
			return (<Link onClick={() => accountClicked(params.row)} component="button" variant="body2">{params.row.name}</Link>);
		}
	},
	// {
	// 	field: 'type',
	// 	headerName: 'Type',
	// 	width: 150,
	// 	valueGetter: ({ value }) => {
	// 		switch (value) {
	// 			case 'agency': return 'Agency'; // eslint-disable-line
	// 			case 'agency_managed': return 'Managed'; // eslint-disable-line
	// 			case 'individual': return 'Individual'; // eslint-disable-line
	// 			default: return 'Unkown'; // eslint-disable-line
	// 		}
	// 	}
	// },
	{
		field: 'created_at',
		headerName: 'Created At',
		type: 'dateTime',
		width: 200,
		valueGetter: ({ value }) => value && new Date(value)
	},
	// {
	// 	field: 'actions',
	// 	type: 'actions',
	// 	getActions: (params) => [
	// 		<GridActionsCellItem icon={<GridDeleteIcon />} onClick={() => deleteAccount(params.row)} label="Delete" key={0} />
	// 	]
	// }
];

export const AccountsTable = () => {
	const dispatch = useDispatch(); // Redux
	const navigate = useNavigate(); // Router
	const [loading, setLoading] = useState(false);
	const [columns, setColumns] = useState([]);

	const allAccounts = useSelector((state) => state.accounts.all); // Redux
	// const userPermissions = useSelector((state) => state.auth.xpermissions); // Redux
	// const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected); // Redux
	const user = useSelector((state) => state.auth.user); // Redux
	// const agencyBeingInspected = useSelector((state) => state.accounts.agencyBeingInspected); // Redux

	useEffect(() => {
		const getAndSetCurrentUsersAccounts = async () => {
			try {
				// Show spinner in table
				setLoading(true);

				// Make request to get the user's accounts
				const resp = await getManagedAccountsForAccount(user.account_id);
				console.log('getManagedAccountsForAccount', resp);
				// Set received accounts in Redux
				dispatch(setAllAccounts(resp.data));
			} catch (error) {
				// Display snackbar with error
				console.log('error', error);
				dispatch(setOpenError({ title: "Error Getting Your Accounts", message: messageFromSuspectedXanoError(error) }));
			} finally {
				// Hide loading spinner in table 
				setLoading(false);
			}
		};
		getAndSetCurrentUsersAccounts();
	}, [setLoading, dispatch]);


	useEffect(() => {
		const accountClicked = (account) => {
			// if (account.type === 'agency_managed' || account.type === 'individual') {
			// Set account in state and got to it
			dispatch(setCurrentlySelectedAccount(account));
			navigate(`/accounts/${account.id}/klaviyo`);
			// } else if (account.type === 'agency') {
			// 	// Set the selected agency in state to retrieve and set it's managed accounts
			// 	getAndSetAgencyAccounts(account);
			// }
		};

		setColumns(COLUMNS({ accountClicked }))
	}, [dispatch, navigate])


	// For the selected agency account, get it's managed accounts and show them in the table
	// const getAndSetAgencyAccounts = async (agency) => {
	// 	try {
	// 		// Show spinner in table
	// 		setLoading(true);

	// 		// Get the agency's managed accounts
	// 		const resp = await getManagedAccountsForAccount({ accountId: agency.id });

	// 		// Set the inspected agency (from the selected account) + it's retrieved managed accounts
	// 		// dispatch(setAgencyBeingInspected({ ...agency, managedAccounts: resp.data }));
	// 	} catch (error) {
	// 		// Display snackbar with error
	// 		console.log('error', error);
	// 		dispatch(setOpenError({ title: "Error Getting Agency's Accounts", message: messageFromSuspectedXanoError(error) }));
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };


	// const deleteAccount = async (account) => {
	// 	if (!window.confirm('Are you sure you want to deactivate this account')) { return; }
	// 	try {
	// 		// Show spinner in table
	// 		setLoading(true);
	// 		console.log('deac', account);
	// 		// Make the async request to deactivate the account	
	// 		await deactivateAccount({ accountId: account.id });

	// 		// Get and set the accounts to reflect the new change
	// 		getAndSetCurrentUsersAccounts();
	// 	} catch (error) {
	// 		// Display snackbar with error
	// 		console.error('error on deactivating/getting and setting accounts', error);
	// 		dispatch(setOpenError({ title: "Error Deleting Accounts", message: error.message }));
	// 	} finally {
	// 		// Hide loading spinner in table 
	// 		setLoading(false);
	// 	}
	// };

	// const goBackToUsersAccounts = () => {
	// 	dispatch(setAgencyBeingInspected(null));
	// }

	// const rows = get(agencyBeingInspected, 'managedAccounts', allAccounts);
	// const columns = COLUMNS({ accountClicked, userPermissions });

	return (
		<Box>
			{/* {agencyBeingInspected && (
				<Grid container alignItems="center">
					<IconButton onClick={goBackToUsersAccounts} size="large">
						<ArrowBackIcon fontSize="inherit" />
					</IconButton>
					<Typography variant="body1" fontSize='1.25rem'>
						You are viewing {agencyBeingInspected.name}{"'"}s accounts
					</Typography>
				</Grid>
			)} */}

			<StyledDataGrid
				rows={allAccounts || []}
				columns={columns}
				loading={loading}
				invalid
				initialState={{
					sorting: { sortModel: [{ field: 'created_at', sort: 'desc' }] },
					pagination: { paginationModel: { pageSize: 10 } }
				}}
				autoHeight
				pageSizeOptions={[10]}
				disableRowSelectionOnClick
			/>
		</Box>
	);
};
