import { useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import { get, split } from 'lodash';
import DownloadIcon from '@mui/icons-material/Download';
import { GridActionsCellItem } from '@mui/x-data-grid';

import { downloadPromptResultFile } from 'api/ai-prompt-result';
import { setOpenError } from "services/redux/slicers/slicers.snackbar";
import { messageFromSuspectedXanoError } from 'api/axios-api';

export const DownloadFileButton = ({ params, setLoading }) => {
	const dispatch = useDispatch();

	const downloadCsvFile = async () => {
		try {
			// Show spinner in datatable
			setLoading(true);

			const promptResultId = get(params, 'row.id', null);
			if (!promptResultId) { throw new Error('Can\'t download file. Missing Prompt Result Id'); }

			// Retrieve the raw csv data
			const res = await downloadPromptResultFile({ promptResultId });
			console.log('res', res);

			// Check for error
			if (get(res, 'data.status') !== 200) { throw new Error('Couldn\'t find file in request'); }

			// Get filename and data
			const { fileData, fileName } = get(res, 'data.result');

			// Create a blob with the data we want to download as a file
			const blob = new Blob([fileData], { type: 'text/csv' });

			const parsedFileName = split(fileName, '/', 2)?.[1] || fileName;
			// console.log('parsedFileName', parsedFileName);

			// Create an anchor element and dispatch a click event on it to trigger a download
			const a = document.createElement('a');
			a.download = parsedFileName;
			a.href = window.URL.createObjectURL(blob);
			const clickEvt = new MouseEvent('click', {
				view: window,
				bubbles: true,
				cancelable: true
			});
			a.dispatchEvent(clickEvt);
			a.remove();
		} catch (error) {
			console.log('Error download file', error, params);
			dispatch(setOpenError({ title: 'Error Downloading File', message: messageFromSuspectedXanoError(error) }));
		} finally {
			// Hide spinner in datatable
			setLoading(false);
		}
	}

	if (get(params, 'row.job_status') === 'completed') {
		return <GridActionsCellItem icon={<DownloadIcon color='' />} onClick={downloadCsvFile} label="Download" />;
	} else return null;
};

DownloadFileButton.propTypes = {
	params: PropTypes.shape({
		row: PropTypes.shape({
			droplet_file_path: PropTypes.string.isRequired
		}).isRequired
	}).isRequired,
	setLoading: PropTypes.func.isRequired
};
