import { useState } from "react";
import { useSelector } from "react-redux";
import { PropTypes } from 'prop-types';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { get } from "lodash";

const PRE_MADE_OPTIONS = [
	{
		label: 'Webshop #1',
		company_website_title: 'ComfySoles Shoe Boutique - Where Comfort Meets Style',
		company_slogan: "Our footwear selection is inspired by iconic designs, offering the utmost comfort for your feet. Step into a world of cozy and stylish shoes that have been handpicked to provide comfort that lasts all day."
	},
	{
		label: 'SaaS #1',
		company_website_title: 'SoundCraft Producers | Elevate Your Mix with Top-Notch Tools',
		company_slogan: "Elevate your audio productions with SoundCraft Producers, your go-to source for top-quality plugins, drum samples, impulse responses, and more. Experience the sound of excellence with SoundCraft Producers."
	},
	{
		label: 'Marketing Agency #1',
		company_website_title: 'MarketBoost Marketing Solutions | Amplify Your Business Growth',
		company_slogan: 'We specialize in crafting tailored marketing strategies and solutions designed exclusively for your business. With our expertise, your business will reach new heights, expanding your reach and boosting your success.'
	},
	{
		label: 'Bad Data #1',
		company_website_title: 'Title',
		company_slogan: 'Homepage'
	}
];

export const PreviewPayloadSiteInfo = ({ updatePayload, setNewKeywordText }) => {
	const prompt = useSelector((state) => state.promptEditor.prompt); // Redux
	const previewPayload = useSelector((state) => state.promptEditor.preview.payload); // Redux
	const [preMadeOption, setPreMadeOption] = useState('');

	// When a new pre-made option has been selected
	const handlePreMadeOptionChange = (event) => {
		const value = event.target.value;
		setPreMadeOption(value);
		const preMade = PRE_MADE_OPTIONS.find(option => option.label === value);

		const _testValuesForKeywords = { ...previewPayload };
		if (value === '') {
			// If "None" was selected, empty the values
			Object.assign(_testValuesForKeywords, { company_slogan: '', company_website_title: '' });
		} else if (preMade) {
			// If a pre made option could be found, store it in the redux payload
			const { company_slogan, company_website_title } = preMade;
			Object.assign(_testValuesForKeywords, { company_slogan, company_website_title });
		}

		updatePayload(_testValuesForKeywords);
	};

	if (!previewPayload) { return null; }
	else return (
		<Box>
			{prompt.keywords
				.filter(kw => kw.inputType === 'scraper')
				.map(keyword => (
					<TextField
						label={keyword.name}
						variant="filled"
						fullWidth
						multiline
						value={get(previewPayload, keyword.key, '')}
						onChange={e => setNewKeywordText(e.target.value, keyword.key)}
						sx={{ mt: 1 }}
						key={keyword.id}
					></TextField>
				))}

			<FormControl fullWidth variant="filled" sx={{ mt: 1 }}>
				<InputLabel id="pre_made_select_label">Pre Made Options</InputLabel>
				<Select
					labelId='pre_made_select_label'
					id="dasd"
					value={preMadeOption}
					label="Pre Made Options"
					onChange={handlePreMadeOptionChange}
				>
					<MenuItem value=""><em>None</em></MenuItem>
					{PRE_MADE_OPTIONS.map(option => (
						<MenuItem value={option.label} key={option.label}>{option.label}</MenuItem>
					))}

				</Select>
			</FormControl>


		</Box>
	);
};

PreviewPayloadSiteInfo.propTypes = {
	setNewKeywordText: PropTypes.func.isRequired,
	updatePayload: PropTypes.func.isRequired
};
