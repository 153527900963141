import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { getCsvParsePromptResultsForAccount } from "api/account";

import { DownloadFileButton } from "./download-button";
import { SSEStreamColumn } from "./sse-stream-column";
import { setOpenError } from "services/redux/slicers/slicers.snackbar";
import { messageFromSuspectedXanoError } from "api/axios-api";
import { StyledDataGrid } from "assets/styles/components/styled-data-grid";

const GET_COLUMNS = (refreshAccountResults, setLoading) => [
	{
		field: 'ai_prompt.name',
		headerName: 'Prompt Name',
		width: 250,
		headerAlign: 'center',
		align: 'center'
	},
	{
		field: 'created_at',
		headerName: 'Created',
		type: 'dateTime',
		width: 200,
		valueGetter: ({ value }) => value && new Date(value)
	},
	{
		field: 'total_parsed_rows',
		headerName: 'Total Rows',
		width: 110
	},
	{
		field: 'total_successfully_parsed_rows',
		headerName: 'Good Rows',
		width: 110
	},
	{
		field: 'job_status',
		headerName: 'Job Status',
		width: 110
	},
	{
		field: 'actions',
		type: 'actions',
		getActions: (params) => [
			<DownloadFileButton key={0} params={params} setLoading={setLoading} />
		]
	},
	{
		width: 150,
		field: 'sse',
		type: 'actions',
		getActions: (params) => [
			<SSEStreamColumn
				key={1}
				row={params.row}
				refreshResults={refreshAccountResults}
				nResults={params.row.total_parsed_rows}
			/>
		]
	}
];

export const FilesOverviewTable = () => {
	const dispatch = useDispatch();
	const [promptResults, setPromptResults] = useState([]); // State
	const [loading, setLoading] = useState(false); // State
	const [columns, setColumns] = useState([]);
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected); // Redux

	const refreshAccountResults = useCallback(() => {
		setLoading(true);

		getCsvParsePromptResultsForAccount({ accountId: currentlySelectedAccount.id })
			.then(resp => setPromptResults(resp.data))
			.catch(err => {
				console.log('prompt err', err);
				dispatch(setOpenError({ title: 'Error Getting Files', message: messageFromSuspectedXanoError(err) }))
			})
			.finally(() => setLoading(false))
	}, [currentlySelectedAccount, dispatch]);

	useEffect(() => {
		setColumns(GET_COLUMNS(setLoading, refreshAccountResults));
		refreshAccountResults();
	}, [refreshAccountResults]);

	return (
		<StyledDataGrid
			rows={promptResults}
			columns={columns}
			loading={loading}
			autoHeight
			initialState={{
				sorting: { sortModel: [{ field: 'created_at', sort: 'desc' }] },
				pagination: { paginationModel: { pageSize: 10 } }
			}}
			pageSizeOptions={[10]}
			disableRowSelectionOnClick
			localeText={{ noRowsLabel: 'No files were found' }}
		/>
	);
};
