import { PropTypes } from 'prop-types';
import Papa from 'papaparse';
import Button from "@mui/material/Button";
import UploadFileIcon from '@mui/icons-material/UploadFile';

export const UploadFileButton = ({ setPapaCSV }) => {
	let fileInput = null;

	const fileUploaded = (e) => {
		const files = e.target.files; // FileList object

		const reader = new FileReader();
		reader.onload = (f => {
			// Papa-Parse the uploaded .csv file
			// Check docs, but it is pretty intelligent with regards to identifying headers, newlines, etc. 
			const papaParsed = Papa.parse(f.target.result, { header: true });

			// Set file name on papa object.
			papaParsed.name = reader.fileName;
			console.log('papaParsed', papaParsed);
			// Set it in the parent
			setPapaCSV(papaParsed);

			// Confirm page reloads to avoid deleting mapping / progress
			window.onbeforeunload = () => { return ""; };
		});
		console.log('reader', { setPapaCSV, readAsText: reader.readAsText }, files, files[0] instanceof Blob);
		
		// Read in the image file as a data URL.
		reader.fileName = files[0].name;
		reader.readAsText(files[0]);
	};


	return (<>
		<Button
			variant="contained"
			endIcon={<UploadFileIcon />}
			onClick={(e) => { e.preventDefault(); fileInput.click(); }} // Don't trigger standard callback when button has been clicked
		>
			Upload .csv
		</Button>

		<input
			type="file"
			accept=".csv"
			multiple={false}
			onChange={fileUploaded}
			style={{ display: 'none' }}
			ref={input => fileInput = input}
		/>
	</>
	);
};

UploadFileButton.propTypes = {
	setPapaCSV: PropTypes.func.isRequired,
};
