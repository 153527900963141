import React, { useEffect } from "react";
import { PropTypes } from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton, InputAdornment, OutlinedInput, Typography } from "@mui/material";

import { SortableList } from "./sortable-list";
import { useDispatch, useSelector } from "react-redux";
import { updatePrompt } from "services/redux/slicers/slicers.prompt-editor";
import { calculateTextInputWords, N_MAX_TEXT_INPUT_WORDS } from "./text-editor";
import { uniqueId } from "lodash";

function Item(props) {
	return (
		<SortableList.Item id={props.rule.id}>
			<OutlinedInput
				required
				value={props.rule.rule_text}
				onChange={e => props.updateText(e.target.value)}
				variant={'outlined'}
				endAdornment={
					<InputAdornment position="end">
						<IconButton onClick={props.deleteRule} aria-label="toggle password visibility" edge="end">
							<DeleteIcon />
						</IconButton>
					</InputAdornment>
				}
				sx={{
					width: '-webkit-fill-available',
					'& .MuiInputBase-input': { padding: '0.6rem', color: 'white' }
				}}
			></OutlinedInput>
			<SortableList.DragHandle />
		</SortableList.Item>
	);
}

Item.propTypes = {
	rule: PropTypes.shape({
		id: PropTypes.string,
		rule_text: PropTypes.string,
	}),
	deleteRule: PropTypes.func,
	updateText: PropTypes.func,
};


export function newPromptRule(rules) {
	const order = rules?.length || 0;
	return { order, rule_text: '', id: uniqueId() };
}

export default function RulesListBuilder() {
	const dispatch = useDispatch();
	const prompt = useSelector((state) => state.promptEditor.prompt);

	useEffect(() => {
		const rulesWithIds = prompt?.prompt_rules?.map(rule => ({ ...rule, id: uniqueId() }));
		recalculateRuleOrders(rulesWithIds);
	}, []);

	function updatePromptRules(updatedRules) {
		recalculateRuleOrders(updatedRules);
	}

	function newRule() {
		const rules = prompt?.prompt_rules;
		if (rules.length > 0 && !!rules.find(r => r.rule_text === '')) { return; }
		const newEmptyRule = newPromptRule(rules);
		recalculateRuleOrders([...rules, newEmptyRule]);
	}

	function recalculateRuleOrders(rules) {
		if (!rules || !Array.isArray(rules)) { return; }
		const updatedRules = rules.map((rule, i) => ({ ...rule, order: i }));
		dispatch(updatePrompt({ key: 'prompt_rules', value: updatedRules }));
	}

	function deleteRule(rule) {
		const updatedRules = prompt?.prompt_rules?.filter(r => r.order !== rule.order);
		recalculateRuleOrders(updatedRules);
	}

	function updateRule(text, rule) {
		let updatedRules = [...(prompt?.prompt_rules || [])];
		for (let i = 0; i < updatedRules.length; i++) {
			if (updatedRules[i]?.order === rule.order) {
				updatedRules[i] = { ...updatedRules[i], rule_text: text };
			}
		}

		// ensure the words dont break the max words limit
		const wordsSum = calculateTextInputWords({ key: 'prompt_rules', value: updatedRules }, prompt);
		if (wordsSum <= N_MAX_TEXT_INPUT_WORDS) {
			dispatch(updatePrompt({ key: 'prompt_rules', value: updatedRules }));
		}
	}

	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.5rem', alignItems: 'center', marginBottom: '0.5rem' }}>
				<Typography variant="body1" color={'white'}>
					Rules
				</Typography>

				<Button
					size="small"
					variant="contained"
					onClick={newRule}
					startIcon={<AddIcon />}
				>New Rule</Button>
			</div>

			{prompt?.prompt_rules && (
				<SortableList
					items={prompt?.prompt_rules}
					onChange={updatePromptRules}
					renderItem={(rule) =>
						<Item
							updateText={text => updateRule(text, rule)}
							deleteRule={() => deleteRule(rule)}
							rule={rule}
						/>
					}
				/>
			)}
		</div>
	);
}
