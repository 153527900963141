import { PropTypes } from 'prop-types';
import { Box, Chip } from "@mui/material";

export const KeywordChip = ({ keyword, color, deletable, deleteKeyword }) => {
	const label = `${keyword.name} (${keyword.key})`;

	if (deletable) {
		return (
			<Box>
				<Chip
					color={color}
					sx={{ my: 1, mr: 1, userSelect: 'auto' }} // onDelete causes text to become unselectable ?
					label={label}
					onDelete={() => deleteKeyword(keyword, deletable)}
				/>
			</Box>
		)
	} else return (
		<Box>
			<Chip
				color={color}
				sx={{ my: 1, mr: 1 }}
				label={label}
			/>
		</Box>
	);
};

KeywordChip.propTypes = {
	keyword: PropTypes.shape({
		name: PropTypes.string.isRequired,
		key: PropTypes.string.isRequired,
	}).isRequired,
	color: PropTypes.string.isRequired,
	deletable: PropTypes.bool,
	deleteKeyword: PropTypes.func
};
