import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertTitle, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { updatePrompt } from "services/redux/slicers/slicers.prompt-editor";

import { getGptModels } from 'api/gpt-models';

export const GptModelSelector = () => {
	const dispatch = useDispatch();
	const prompt = useSelector((state) => state.promptEditor.prompt);
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);

	const [error, setError] = useState(null);
	const [models, setModels] = useState(null);

	/**
	 * Fetch the GPT models and update the state with the models,
	 * and set a default model in the prompt's GPT configuration if one is not already set.
	 * 
	 * @param {Object} prompt - The prompt
	 */
	useEffect(() => {
		if (!currentlySelectedAccount?.id) { return; }
		getGptModels(currentlySelectedAccount.id)
			.then(resp => {
				setModels(resp.data);
				if (!prompt?.gpt_config?.model) {
					const defaultModel = resp.data.find(model => model.is_default);
					dispatch(updatePrompt({ key: 'gpt_config.model', value: defaultModel.id }));
				}
			})
			.catch(err => { console.log('error', err); setError(err) })
	}, [dispatch, prompt.gpt_config?.model, currentlySelectedAccount?.id]);

	const handleModelChange = (e) => {
		dispatch(updatePrompt({ key: 'gpt_config.model', value: e.target.value }));
	};

	if (!models && !error || (models && models.length === 0)) { return null; }
	if (error) {
		return (
			<Alert severity="error" sx={{ mb: 2 }}>
				<AlertTitle>Error getting models - contact admin.</AlertTitle>
				{error?.message}
			</Alert>
		);
	} else return (
		<FormControl variant='filled' sx={{ mb: 1 }}>
			<InputLabel id="select-model">Model</InputLabel>
			<Select
				labelId="select-model"
				id="select-model"
				value={prompt?.gpt_config?.model || models[0]?.id}
				label="Model"
				sx={{ '& .MuiInputBase-input': { color: 'white' } }}
				onChange={handleModelChange}
			>
				{models.map(model => (
					<MenuItem value={model.id} key={model.id}>{model.label}</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

GptModelSelector.propTypes = {};
