import { PropTypes } from 'prop-types';
import { DragOverlay, defaultDropAnimationSideEffects } from "@dnd-kit/core";

const dropAnimationConfig = {
	sideEffects: defaultDropAnimationSideEffects({
		styles: { active: { opacity: "0.4" } }
	})
};

export function SortableOverlay({ children }) {
	return (<DragOverlay dropAnimation={dropAnimationConfig}>{children}</DragOverlay>);
}

SortableOverlay.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	])
};