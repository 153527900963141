import React from 'react';
import { Box, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';

const STYLING = { width: '120px', height: '40px' };

export const TierIsActiveSign = ({ tier, activeTier }) => {

	const isActive = activeTier && activeTier?.id == tier?.id;
	return (
		<Box sx={{
			opacity: isActive ? 1 : 0,
			transition: '0.3s opacity',
			position: 'absolute',

			width: STYLING.width,
			height: STYLING.height,
			top: `calc(${STYLING.height} * -0.5 - 1px)`, // 1px for border width
			left: `calc(50% - ${STYLING.width} * 0.5 - 1px)`, // 1px for border width

			borderRadius: '0.75rem',
			backgroundColor: 'white',
			border: '1px solid lightgray',

			display: 'flex',
			textAlign: 'center',
			alignItems: 'center',
			justifyContent: 'center'
		}}><Typography>Active</Typography></Box>
	);
};

TierIsActiveSign.propTypes = {
	activeTier: PropTypes.shape({
		currency: PropTypes.string,
		id: PropTypes.string,
	}),
	tier: PropTypes.shape({
		id: PropTypes.string
	})
};
