import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { WebsiteLayout } from 'layouts/website/layout';

export const PaymentLinkLandingPage = () => {
	return (
		<WebsiteLayout>
			<Box height={'33vh'} marginTop={'4rem'} textAlign={'center'}>
				<Typography variant='h2'>
					Successfully Completed Subscription Update
				</Typography>

				<Typography variant='h6' marginTop={'5rem'}>
					You can now <Link href="https://app.leaddrive.ai/">login</Link> or kick you feet up and relax.<br /> <br />Nothing more to do.
				</Typography>
			</Box>
		</WebsiteLayout>
	);
};

