import React from 'react';
import { PropTypes } from 'prop-types';
import { Unstable_Grid2 as Grid } from '@mui/material';

import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title, SubTitle } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend, Title, SubTitle);

export const LimitGauge = ({ title, tooltipLabels, usage, limit }) => {
	return (
		<Grid container xs={12} justifyContent={'center'}>
			<Grid xs={6} sm={5} md={4} lg={4} xl={3}>
				<Doughnut
					options={{
						rotation: -90,
						circumference: 180,
						cutout: '80%',
						plugins: {
							title: {
								display: true,
								text: [`${usage?.toLocaleString()} / ${limit?.toLocaleString()}`, '', title],
								position: 'bottom',
								font: { size: 15 },
								padding: { top: -120 }
							},
							legend: { display: false }
						}
					}}
					data={{
						labels: tooltipLabels,
						datasets: [
							{
								data: [usage, limit - usage], // set the usage and then the remaning as the second data point
								borderWidth: 0,
								backgroundColor: ['#6466f1', 'lightgray']
							}
						]
					}}
				/>
			</Grid>
		</Grid>
	);
};

LimitGauge.propTypes = {
	title: PropTypes.string.isRequired,
	usage: PropTypes.number.isRequired,
	limit: PropTypes.number.isRequired,
	tooltipLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};
