import { cloneElement, useState } from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { Box, Modal, Paper, Button, IconButton, Divider } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import { PromptNavigator } from "components/shared-components/prompt-editor/prompt-result-preview/prompt-preview-navigator";
import { clearPromptPreview } from "services/redux/slicers/slicers.prompt-editor";

const MODAL_BOX_STYLE = {
	position: 'absolute',
	left: '50%',
	transform: 'translate(-50%, 0%)',
	minWidth: '40vw',
	maxWidth: '90vw',
};

export const PreviewNavigationModal = ({ stages }) => {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false); // State
	const [activeStage, setActiveStage] = useState(stages.find(stage => stage.order === 0)); // State
	const preview = useSelector((state) => state.promptEditor.preview); // Redux
	const promptId = useSelector((state) => state.promptEditor.prompt?.id); // Redux

	const handleModalOpen = () => setOpen(true);
	const handleModalClose = () => {
		if (
			(preview?.generatingPromptResult && confirm('Seems like you are waiting for at GPT preview respose, sure you want to quit?') === true)
			|| (preview?.generatingPromptResult === false)
		) {
			dispatch(clearPromptPreview());
			setActiveStage(stages.find(stage => stage.order === 0));
			setOpen(false);
		}
	};

	// Only show the preview button if the prompt has been inserted (exists in database) and it doensn't have any uncommited changes
	if (!promptId) { return null; }
	else return (
		<div>
			<Button
				variant="contained"
				endIcon={<RemoveRedEyeIcon />}
				onClick={handleModalOpen}
			>
				Preview
			</Button>

			<Modal open={open} onClose={handleModalClose}>
				<Box sx={MODAL_BOX_STYLE} role='preview-modal'>
					<IconButton
						size='small'
						aria-label='delete'
						sx={{ color: 'white' }}
						onClick={handleModalClose}
					>
						<CloseIcon />
					</IconButton>

					<Paper sx={{ p: 1, maxHeight: 'calc(90vh)', overflow: 'auto', maxWidth: '60vw' }}>
						<PromptNavigator
							stage={activeStage}
							stages={stages}
							closeModal={handleModalClose}
							stageChanged={setActiveStage}
						/>

						<Divider />

						{/* <Box sx={{ p: 1 }}>{activeStage.component}</Box> */}
						<Box sx={{ p: 1 }}>
							{cloneElement(activeStage.component, { customProp: 'someValue' })}

						</Box>

					</Paper>
				</Box>
			</Modal>
		</div>
	);
};

PreviewNavigationModal.propTypes = {
	stages: PropTypes.arrayOf(PropTypes.shape({
		order: PropTypes.number.isRequired,
		id: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		icon: PropTypes.element,
		component: PropTypes.element.isRequired,
	}))
};