import { useState } from 'react';
import { PropTypes } from 'prop-types';
import {
	Button,
	Typography,
	Popover,
} from "@mui/material";

export const HoverToPreviewPrompt = (props) => {
	const prompt = props.row;
	const [anchorEl, setAnchorEl] = useState(null);

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	if (!prompt || !prompt.text) { return null; }

	return (<>
		<Button
			aria-owns={open ? 'mouse-over-popover' : undefined}
			aria-haspopup="true"
			sx={{ p: 1 }}
			onClick={e => { e.preventDefault(); e.stopPropagation() }}
			variant='outlined'
			onMouseEnter={handlePopoverOpen}
			onMouseLeave={handlePopoverClose}
		>
			Preview
		</Button>

		<Popover
			id="mouse-over-popover"
			sx={{
				pointerEvents: 'none',
				maxWidth: '60vw',
				'& .MuiPopover-paper': { width: 'max-content' }
			}}
			open={open}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			onClose={handlePopoverClose}
			disableRestoreFocus
		>
			<Typography sx={{ p: 1, whiteSpace: 'pre-wrap', textWrap: 'balance', width: '100%' }}>{prompt.text}</Typography>
		</Popover>
	</>);
};

HoverToPreviewPrompt.propTypes = {
	row: PropTypes.shape({ text: PropTypes.string.isRequired }),
};
