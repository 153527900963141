import { PropTypes } from 'prop-types';
import { StyledDataGrid } from 'assets/styles/components/styled-data-grid';

export const CSVTablePreview = ({ papaCSV }) => {
	if (!papaCSV) { return null; }

	// Add a "id" field to the rows
	const rows = papaCSV.data.map((row, i) => ({ ...row, id: i }));

	// Format the columns by converting thmem into objects with a "field" and "id"
	const columns = papaCSV.meta.fields.map(column => ({ id: column, field: column }));

	return (
		<StyledDataGrid
			rows={rows}
			columns={columns}
			autoHeight
			initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
			pageSizeOptions={[5]}
			disableRowSelectionOnClick
		/>
	);
};

CSVTablePreview.propTypes = {
	papaCSV: PropTypes.shape({
		data: PropTypes.arrayOf(PropTypes.object).isRequired,
		meta: PropTypes.shape({ fields: PropTypes.array.isRequired }).isRequired
	})
};
