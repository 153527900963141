import { Box, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePromptPreview } from "services/redux/slicers/slicers.prompt-editor";
import { PreviewPayloadSiteInfo } from "./preview-payload-site-info";

export const PreviewPayload = () => {
	const dispatch = useDispatch();
	const prompt = useSelector((state) => state.promptEditor.prompt); // Redux
	const [sessionStorageKey, setSessionStorageKey] = useState(null);
	const previewPayload = useSelector((state) => state.promptEditor.preview.payload); // Redux

	// Update the redux state payload, but also store it in sessionstorage
	const updatePayload = useCallback((value) => {
		dispatch(updatePromptPreview({ key: 'payload', value }));
		if (sessionStorageKey) { sessionStorage.setItem(sessionStorageKey, JSON.stringify(value)); }
	}, [dispatch, sessionStorageKey]);

	useEffect(() => {
		// If the prompt's id is set create the payload session storage key
		if (!prompt.id) { return; }
		const key = `${prompt.id}_prompt_preview_payload`;
		setSessionStorageKey(key);

		// If a payload has been stored for this prompt, set it, else set an empty obj
		const storedPayload = sessionStorage.getItem(key);
		if (storedPayload) { updatePayload(JSON.parse(storedPayload)); }
		else { updatePayload({}); }
	}, [updatePayload, prompt]);


	const setNewKeywordText = (value, keywordKey) => {
		const _testValuesForKeywords = { ...previewPayload };
		_testValuesForKeywords[keywordKey] = value;
		updatePayload(_testValuesForKeywords);
	};

	if (!previewPayload) { return null; }
	else return (
		<Box>
			{/* Here show the user-made keywords */}
			{prompt.keywords
				.filter(kw => kw.inputType === 'user' && !kw.isCompanyWebsite && kw.type === 'prompt_referenced')
				.map(keyword => (
					<TextField
						label={keyword.name}
						variant="filled"
						fullWidth
						value={previewPayload[keyword.key]}
						onChange={e => setNewKeywordText(e.target.value, keyword.key)}
						sx={{ mt: 1 }}
						key={keyword.id}
					></TextField>
				))}

			{/* Instead of manually typing site info, give the user a handfull of options */}
			<PreviewPayloadSiteInfo updatePayload={updatePayload} setNewKeywordText={setNewKeywordText} />
		</Box>
	);
};
