import { PropTypes } from 'prop-types';
import { Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

export const StartNewPromptButton = ({ startNew, buttonText = 'Create New' }) => (
	<Button
		onClick={startNew}
		variant="contained"
		endIcon={<AddIcon />}
		sx={{ width: 'fit-content' }}
	>
		{buttonText}
	</Button>
);

StartNewPromptButton.propTypes = {
	startNew: PropTypes.func.isRequired,
	buttonText: PropTypes.string
};
