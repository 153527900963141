import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
// import { useParams } from "react-router-dom";
import {
	Box,
	Modal,
	Paper,
	Button,
	TextField,
	IconButton
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import { setOpenSuccess, setOpenError } from 'services/redux/slicers/slicers.snackbar';
import { addKlaviyoAccount } from "services/redux/slicers/slicers.klaviyo-page";
import { createKlaviyoAccount } from "api/klaviyo-accounts";
import { messageFromSuspectedXanoError } from "api/axios-api";
import { PERMISSIONS } from "config/permissions";

const MODAL_BOX_STYLE = {
	position: 'absolute',
	top: '25%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 500,
	p: 3
};

export const AddNewKlaviyoAccount = () => {
	const dispatch = useDispatch(); // React Router
	// const { accountId: currentlySelectedAccountId } = useParams();
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);
	const [open, setOpen] = useState(false);
	const [texts, setTexts] = useState({ name: '', apiKey: '' });
	const klaviyoAccounts = useSelector((state) => state.klaviyo.accounts);
	// const userPermissions = useSelector((state) => state.auth.xpermissions);
	// console.log('klaviyoAccounts', klaviyoAccounts);
	const handleModalOpen = () => setOpen(true);
	const handleModalClose = () => setOpen(false);

	const handleCreate = async () => {
		try {
			const { name, apiKey } = texts;

			// TODO: Fix this - show error message on the textfields and validate valid email
			if (!name.length || !apiKey.length) { return; }

			// Create the Klaviyo Account record
			const resp = await createKlaviyoAccount({
				apiKey,
				name,
				accountId: currentlySelectedAccount.id,
			});

			// Add account to redux list of all accounts
			dispatch(addKlaviyoAccount(resp.data));
			// dispatch(addKlaviyoAccount({ "id": 10, "name": "test fake", "created_at": 1695843018513, "account_id": 10, "private_api_key": "asdasdasd123", "droplet_validation_key": "1bf65b14-7e77-4a9b-b55a-ff7607c359ca" }));

			// Close creation modal
			handleModalClose();

			// Display success snackbar
			dispatch(setOpenSuccess({ title: "Created Klaviyo Account" }));

			// Clear the input
			setTexts({ name: '', api_key: '' });

		} catch (error) {
			// Display snackbar with error
			dispatch(setOpenError({
				title: "Error Creating Klaviyo Account",
				message: messageFromSuspectedXanoError(error)
			}));
			console.error('error creating klaviyo account', error);
		}
	};

	const onChange = (e) => {
		const key = e.target.id;
		const text = e.target.value;
		const _texts = { ...texts };
		_texts[key] = text;
		setTexts(_texts);
	};

	return (
		<div>
			<Button
				sx={{ mb: 2 }}
				variant="contained"
				disabled={
					!(
						currentlySelectedAccount.permissions[PERMISSIONS.multipleKlaviyoAccountsWrite] === true
						|| (!currentlySelectedAccount.permissions[PERMISSIONS.multipleKlaviyoAccountsWrite] === true && klaviyoAccounts?.length < 1)
					)
				}
				endIcon={<AddIcon />}
				onClick={handleModalOpen}
			>
				Create Klaviyo Account
			</Button>

			<Modal open={open}>
				<Box sx={MODAL_BOX_STYLE}>
					<IconButton
						size='small'
						aria-label='delete'
						sx={{ color: 'white' }}
						onClick={handleModalClose}
					>
						<CloseIcon />
					</IconButton>

					<Paper sx={{ p: 3 }}>
						<TextField
							required
							id="name"
							sx={{ mr: 2 }}
							value={texts.name}
							onChange={onChange}
							label="Name / Desc."
						/>

						<TextField
							required
							id="apiKey"
							onChange={onChange}
							value={texts.email}
							label="API Key"
						/>

						<Button
							sx={{ mt: 2 }}
							variant="contained"
							onClick={handleCreate}
							endIcon={<AddIcon />}
						>
							Create
						</Button>
					</Paper>
				</Box>
			</Modal>
		</div>
	);
};
