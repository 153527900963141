import React, { useEffect, useState } from 'react';
import { Box, Typography, } from '@mui/material';
import { getAllStoreProducts } from 'api/account';
import { useSelector } from 'react-redux';
import { Blacklist } from 'components/store-products-page/blacklist';

export const StoreProductsPage = () => {
	const [loaded, setLoaded] = useState(false);
	const [products, setProducts] = useState(null);
	const [error, setError] = useState(null);
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);

	useEffect(() => {
		if (loaded) { return; }
		if (!currentlySelectedAccount?.store_bucket_folder_name) { setLoaded(true); return; }

		getAllStoreProducts(currentlySelectedAccount.id)
			.then(resp => {
				console.log('all products', resp.data);
				setProducts(resp.data);
			})
			.catch(err => {
				console.log('error getting all products', err);
				setError(err.message);
			})
			.finally(() => setLoaded(true));
	}, [loaded, currentlySelectedAccount]);


	if (error) { return (<Typography>{error}</Typography>); }
	if (currentlySelectedAccount?.store_bucket_folder_name) {
		return (<Box>

			{loaded == true && (
				<Blacklist products={products} />
			)}

			{loaded == false && (
				<Typography>
					Loading...
				</Typography>
			)}
		</Box>)
	}

	return (
		<Box>
			<Typography>
				Missing products bucket identifier (products probably haven&apos;t been saved yet)
			</Typography>
		</Box>
	);

};