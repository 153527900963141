import axios from 'axios';
import { get } from 'lodash';

import { logout, startLoading } from "services/redux/slicers/slicers.auth";
import { clearAccountsState } from 'services/redux/slicers/slicers.accounts';

const XANO_LEADDRIVE_BASE_URL = process.env.REACT_APP_XANO_LEADDRIVE_API_BASE_URL || 'http://localhost';
const {
	REACT_APP_XANO_BRANCH,
	// REACT_APP_XANO_DATA_SOURCE
} = process.env;
export let axiosInstance = null;

export const setupAxiosInstance = (store) => {
	const baseURL = XANO_LEADDRIVE_BASE_URL;
	// console.log('baseURL', baseURL);
	const headers = {
		'Content-Type': 'application/json',
		'Authorization': store.getState().auth.access_token
	};

	// if (REACT_APP_XANO_DATA_SOURCE) { headers['X-Data-Source'] = REACT_APP_XANO_DATA_SOURCE; }
	if (REACT_APP_XANO_BRANCH) { headers['X-Branch'] = REACT_APP_XANO_BRANCH; }

	axiosInstance = axios.create({ baseURL, headers });
	axiosInstance.interceptors.response.use(
		response => response,
		error => {
			console.log('interceptor error', error, error.response?.status);
			if (error.response?.status !== 401) { return Promise.reject(error); }

			// Statuscode is 401
			const { code, message } = error.response.data;

			if (
				code === "ERROR_CODE_UNAUTHORIZED" &&
				message === "This token is expired."
			) {
				const { dispatch } = store;
				dispatch(logout());
				dispatch(startLoading());
				dispatch(clearAccountsState());
				window.location.href = '/login';
			}

			/* 
				This will get caught in the api function with "rejectIfNotExpiredToken" and wont be passed back to the 
				original request as we're about to redirect and we want to avoid showing a error message to the user 
					(because we're dealing with the issue)
			*/
			return Promise.reject(error);
		}
	);

	axiosInstance.interceptors.request.use(
		(config) => {

			// Get the token from the headers if already set
			const accessToken = config.headers['Authorization'] || null;

			if (!accessToken) {
				// Modify the request configuration to add the access token.
				const token = store.getState().auth.accessToken
				if (token) { config.headers['Authorization'] = token; }
			}

			return config;
		},
		(error) => { return Promise.reject(error); }
	);
};

export const rejectIfNotExpiredToken = (error, reject) => {
	/* 
		If the error is not a "expired token" error, just reject it as a normal error
		If it is a "expired token" error, dont reject it as we're redirecting the user to the login page
			and we dont wan't to show a error snackbar to the user for an expired token error.
	*/
	const { code, message } = get(error, 'response.data', {});

	if (
		get(error, 'response.status') !== 401 &&
		!(
			code === "ERROR_CODE_UNAUTHORIZED" &&
			message === "The token expired."
		)
	) { reject(error); }
	console.warn('rejectIfNotExpiredToken caught expired token error');
};

export const messageFromSuspectedXanoError = (error) => {
	if (error.name != 'AxiosError') { return error.message; }
	else {
		return get(error, 'response.data.message', error.message);
	}
}