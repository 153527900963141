import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, Typography } from "@mui/material";
import { PropTypes } from 'prop-types'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { setOpenSuccess } from 'services/redux/slicers/slicers.snackbar';

/**
 * Render a click-to-copy value and label for it.
 * Params allow for displaying passwords with the "clickToShow" 
 * @param {string} label - The label to display.
 * @param {string} value - The value to display that is stored in clipboard when copied.
 * @param {object} sx - Styling to pass along to the root element
 * @param {boolean} fullWidth - Should the width be fullWidth or fit the content
 * @param {boolean} clickToShow - Should the value be password-like. Click to show.
 * @param {boolean} isKey - isKey changes styling to make it look like normal text. Good for table keys
 */
export const ClickToCopyText = ({ label, value, sx, fullWidth, clickToShow, isKey }) => {
	const dispatch = useDispatch();
	const [showText, setShowText] = useState(false);

	const textCopied = (e) => {
		navigator.clipboard.writeText(e.target.innerText);
		dispatch(setOpenSuccess({ title: `Copied "${label}"` }));
		if (clickToShow) { clickToHideHandler(); }
	};

	const clickToShowHandler = () => { if (clickToShow) { setShowText(true); } };
	const clickToHideHandler = () => { setShowText(false); };

	const textValueStyle = {
		...sx,
		p: '0.75rem',
		backgroundColor: '#c8c6c638',
		borderRadius: '10px',
		userSelect: 'none',
		cursor: 'copy',
		whiteSpace: 'pre-wrap',
		display: 'flex',
		width: fullWidth ? 'auto' : 'fit-content',
		fontStyle: 'italic'
	};

	const keyStyleValue = {
		...sx,
		userSelect: 'none',
		whiteSpace: 'pre-wrap',
		cursor: 'copy',
		width: fullWidth ? 'auto' : 'fit-content'
	};

	if (clickToShow === true && showText === false && value) {
		return (
			<Typography
				sx={{ ...textValueStyle, cursor: 'default' }}>
				<IconButton sx={{ p: 0, pr: 1 }}><VisibilityIcon onClick={clickToShowHandler} /></IconButton>
				{value.split('').map(() => '*')}
			</Typography>
		);
	} else {
		return (
			<Typography
				sx={isKey ? keyStyleValue : textValueStyle}
				onClick={!clickToShow ? textCopied : () => { }}
			>
				{clickToShow && <IconButton sx={{ p: 0, pr: 1 }}><VisibilityOffIcon onClick={clickToHideHandler} /></IconButton> || null}
				<div
					onClick={clickToShow ? textCopied : () => { }}
				>{value}</div>
			</Typography>
		);
	}

};

ClickToCopyText.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	sx: PropTypes.object,
	fullWidth: PropTypes.bool,
	clickToShow: PropTypes.bool,
	isKey: PropTypes.bool,
};
