import { PropTypes } from 'prop-types';
import { Button, Box } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export const GoBackButton = ({ goBack, text }) => {
	return (
		<Button
			onClick={goBack}
			variant="contained"
			sx={{ width: 'fit-content' }}
		>
			<ArrowBackIosNewIcon />
			{text != null && (<Box sx={{ ml: 1 }}>{text}</Box>)}
		</Button>
	);
};

GoBackButton.propTypes = {
	goBack: PropTypes.func.isRequired,
	text: PropTypes.string
};
