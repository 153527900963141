import { PropTypes } from 'prop-types';
import { Button, Unstable_Grid2 as Grid } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const PromptNavigator = ({ stage, stageChanged, stages, closeModal }) => {
	const stageClicked = () => {
		if (stage.id === 'DONE') { closeModal(); }
		else if (stage.order + 1 < stages.length) {
			const nextStage = stages.find(stg => stg.order === stage.order + 1);
			stageChanged(nextStage);
		}
	};

	const backButton = () => {
		const prevStage = stages.find(stg => stg.order === stage.order - 1);
		stageChanged(prevStage);
	};

	return (
		<Grid container>
			{stage.order > 0 && (
				<Grid flexGrow={0} marginRight={1}>
					<Button
						fullWidth
						sx={{ p: 2, mb: 2 }}
						variant="contained"
						startIcon={<ArrowBackIosIcon />}
						onClick={backButton}
					>
						Back
					</Button>
				</Grid>
			)}

			<Grid flexGrow={1}>
				<Button
					fullWidth
					sx={{ p: 2, mb: 2 }}
					variant="contained"
					endIcon={stage.icon}
					onClick={stageClicked}
				>
					{stage.label}
				</Button>
			</Grid>
		</Grid>
	);
};

const propTypeStage = {
	order: PropTypes.number.isRequired,
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	icon: PropTypes.object
};

PromptNavigator.propTypes = {
	stage: PropTypes.shape(propTypeStage).isRequired,
	stages: PropTypes.arrayOf(PropTypes.shape(propTypeStage)).isRequired,
	stageChanged: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired
};
