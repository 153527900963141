import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { find, get, uniq } from 'lodash';
import { Breadcrumbs, Link, Typography } from '@mui/material';

export const BreadcrumbNavigator = () => {
	const params = useParams();
	const navigate = useNavigate();

	const [breadcrumbs, setBreadcrumbs] = useState([]);

	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);
	const promptEditorType = useSelector((state) => state.promptEditor.editorType);
	const promptBeingUpserted = useSelector((state) => state.promptEditor.prompt);

	useEffect(() => {
		const { accountId, klaviyoAccountId, tab } = params;
		const _breadcrumbsObs = [
			{
				linkPath: '/accounts',
				label: 'Accounts'
			},
			{
				linkPath: `/accounts/${accountId}/${tab}`,
				label: get(currentlySelectedAccount, 'name', 'Account')
			}
		];

		if (klaviyoAccountId) {
			const klaviyoAccount = find(get(currentlySelectedAccount, 'klaviyo_accounts', []), { 'id': parseInt(klaviyoAccountId) });
			_breadcrumbsObs.push({
				linkPath: `/accounts/${accountId}/klaviyo/${klaviyoAccountId}/account_details`,
				label: get(klaviyoAccount, 'name', 'Klaviyo Account')
			});
		}

		if (promptEditorType && promptBeingUpserted) {
			_breadcrumbsObs.push({
				linkPath: `/accounts/${accountId}/csv_parse`,
				label: promptBeingUpserted.name
			});
		}

		// If breadcrumb is not last, render it as link, else unclickable text
		const _breadcrumbs = _breadcrumbsObs.map((breadcrumb, index) => {
			if (index != _breadcrumbsObs.length - 1) {
				return (<Link
					underline="hover" key={uniq()} color="inherit" href=""
					onClick={() => navigate(breadcrumb.linkPath)}
				>
					{breadcrumb.label}
				</Link>)
			} else {
				return (
					<Typography key={uniq()}>
						{breadcrumb.label}
					</Typography>
				);
			}
		})

		setBreadcrumbs(_breadcrumbs);
	}, [navigate, params, promptEditorType, promptBeingUpserted, currentlySelectedAccount]);

	return (
		// z index: 1205
		<Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ pl: 2 }}>
			{breadcrumbs}
		</Breadcrumbs>
	);
};
