import { PropTypes } from 'prop-types';
// import DeleteIcon from '@mui/icons-material/Delete';
import {
	FormControl,
	Typography,
	// IconButton,
	Select,
	MenuItem,
	Unstable_Grid2 as Grid,
	Box,
	// NativeSelect,
	// InputLabel
} from "@mui/material";

/**
 * Component for mapping CSV columns to prompt keywords.
 *
 * @component
 * @param {Object} props - The properties of the component.
 * @param {Object} props.papaCSV - Object containing CSV data and metadata.
 * @param {Array} props.papaCSV.data - Array of CSV rows.
 * @param {Array} props.papaCSV.meta.fields - Array of CSV column headers.
 * 
 * @param {Object} props.prompt - The prompt.
 * @param {number} props.prompt.id - The prompt's id
 * @param {string} props.prompt.text - The prompt's text
 * @param {Array} props.prompt.keywords - Array of keywords for the prompt
 * 
 * @param {Object} props.keywordColumnMap - Object mapping CSV columns to keywords.
 * @param {Function} props.setKeywordColumnMap - Function to set the keywordColumnMap state.
 * @returns {JSX.Element|null} The rendered ColumnMapper component.
 */
export const ColumnMapper = ({ prompt, papaCSV, keywordColumnMap, setKeywordColumnMap }) => {
	if (!papaCSV) { return null; }

	const onMappedColumn = (column, keyword) => {
		// Make a copy of the object so that we can delete pairs
		const keywordColumnMapCopy = { ...keywordColumnMap };

		const clearKeywordMapForColumn = () => { delete keywordColumnMapCopy[getKeywordByColumn(column)]; }

		// If the user is selecting "none" - clear the keyword from the map
		if (keyword === "") { clearKeywordMapForColumn(); }
		else {
			// If the column already has been mapped to another keyword, clear the keyword first, before mapping it to the newly selected keyword
			if (Object.values(keywordColumnMapCopy).includes(column)) { clearKeywordMapForColumn(); }

			// Map the column to the keyword
			keywordColumnMapCopy[keyword] = column;
		}

		// Set state in parent
		setKeywordColumnMap(keywordColumnMapCopy);
	};

	// Get the keywords to be mapped with the headers
	const userInputKeywords = prompt?.keywords.filter(keyword => keyword.inputType === 'user');

	// Gets the keyword by it's selected column - (get key by value)
	const getKeywordByColumn = (column) => {
		const keyword = Object.keys(keywordColumnMap).find(key => keywordColumnMap[key] === column);
		return keyword ? keyword : "";
	};

	return (
		<Box
			sx={{
				bgcolor: '#2a2e35',
				borderRadius: 2,
				display: 'flex', p: 2,
				boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px',
			}}
		>
			<Grid container>
				<Typography color="white" variant="h5" sx={{ mr: 2, textAlign: 'center', width: 1, mb: 1 }}>
					Column Mapper
				</Typography>

				{papaCSV.meta.fields.map(column => (
					<Grid xs={12} display="flex" alignItems="center" key={column} sx={{ mt: 1.5 }}>
						<Typography color="white" variant="subtitle1" sx={{ mr: 2 }} id={column}>
							{column}
						</Typography>

						{prompt?.keywords && (
							<FormControl size="small">
								<Select
									displayEmpty
									sx={{ color: 'white' }}
									value={getKeywordByColumn(column)}
									onChange={(e) => onMappedColumn(column, e.target.value)}

									role='select-keyword'
									aria-describedby={column}
									aria-label={column}
								>
									<MenuItem value="" aria-describedby={column} role="empty-option"><em>none</em></MenuItem>

									{userInputKeywords.map(keyword => (
										<MenuItem
											value={keyword.key}
											label={column}
											key={keyword.key}
											aria-describedby={column}
											role="keyword-option"
										>
											{keyword.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						)}
					</Grid>
				))}
			</Grid>
		</Box >
	);
};

ColumnMapper.propTypes = {
	papaCSV: PropTypes.shape({
		data: PropTypes.arrayOf(PropTypes.object),
		meta: PropTypes.shape({ fields: PropTypes.array.isRequired }).isRequired
	}),
	prompt: PropTypes.shape({
		text: PropTypes.string.isRequired,
		keywords: PropTypes.arrayOf(PropTypes.object).isRequired,
		id: PropTypes.number.isRequired,
	}),
	keywordColumnMap: PropTypes.object.isRequired,
	setKeywordColumnMap: PropTypes.func.isRequired
};
