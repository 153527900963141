import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get, compact } from 'lodash';
import RocketIcon from '@mui/icons-material/Rocket';
import LoadingButton from '@mui/lab/LoadingButton';
import HandlebarsExtended from 'handlebarsld'; 

import { createPromptResults } from 'api/ai-prompt-result';
import { setOpenSuccess, setOpenError } from 'services/redux/slicers/slicers.snackbar';
import { messageFromSuspectedXanoError } from 'api/axios-api';

export const ParseAndUploadButton = ({ papaCSV, prompt, keywordColumnMap }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate(); 
	const [loading, setLoading] = useState(false);
	const [isReadyToSend, setIsReadyToSend] = useState(false);

	useEffect(() => {
		//  If a prompt has been selected and all the user-input keywords have been mapped: show send button
		if (!prompt?.keywords || !keywordColumnMap) { return; }
		const userKeywords = prompt.keywords.filter(keyword => keyword.inputType === "user");
		const mappedKeywords = Object.keys(keywordColumnMap);
		setIsReadyToSend(userKeywords.length === mappedKeywords.length);
	}, [keywordColumnMap, prompt]);

	/**
	 * Run through the rows and replace the values in the prompt with rows' actual values.
	 * Also, format the row so that the backend can easily grab the website url to scrape
	 * 
	 * @returns {Array}
	 * @throws {Error} Throws an error if a row has a missing or invalid website.
	 */
	const createRowsToParse = () => {
		const websiteToScrapeColumn = prompt.keywords.find(keyword => keyword.isCompanyWebsite).key;

		// Loop through the csv file's rows and format them properly
		const foramttedRowsWithPopulatedPrompts = papaCSV.data.map((row, index) => {
			const rowValueForKeyword = {
				company_website_title: '{{company_website_title}}',
				company_slogan: '{{company_slogan}}'
			};

			/* 
				For each mapped column, set the value so the template engine can replace them
						"company_website_title" and "company_slogan" will get set in the backend, so to avoid them being
					removed from the template (as missing tokens will be), replace the token with their own placeholders
			*/
			Object.keys(keywordColumnMap).forEach(keyword => {
				const column = keywordColumnMap[keyword];
				rowValueForKeyword[keyword] = row[column];
			});

			// Generate the prompt
			// console.log('createRowsToParse', handlebarCompiler);
			const compiler = new HandlebarsExtended(prompt);
			// compiler.compile(prompt.text);
			const { text: promptTextForRow } = compiler.renderTemplate(rowValueForKeyword);

			// Get the website (the scraper needs to have specific access to this)
			const website = get(row, keywordColumnMap[websiteToScrapeColumn], '');

			// Validate the website is valid
			const domainMatcherRegex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;
			const domainFound = website.match(domainMatcherRegex);
			if (!website || !domainFound) {
				throw new Error(`Missing or invalid website on row ${index + 1}.\nRemove row or fix website value.\nThe column might be mapped incorrectly.`);
			}

			// Return the formatted data to be sent to Xano
			return {
				prompt_with_csv_data: promptTextForRow,
				website_url_to_scrape: website,
				original_csv_row: { ...row }
			};
		});

		// Remove falsey values
		return compact(foramttedRowsWithPopulatedPrompts);
	};

	/**
	 * Parses the current prompt with the rows and sends the formatted data to the backend.
	 * If succesful, navigates to the download-page
	 *
	 * @async
	 * @returns {Promise<void>} A Promise that resolves when the parsing and sending are successful.
	 */
	const parsePromptWithRows = async () => {
		try {
			if (isReadyToSend === false || loading === true) { return; }

			//const compiler = new HandlebarsExtended();
			// setHandlebarCompiler(compiler);


			// Avoid double-click and show spinner on post-button
			setLoading(true);

			// Format the rows and prompt into a format that the backend requires
			// const compiler = new HandlebarsExtended();
			// const rowsWithReadyPrompts = createRowsToParse(compiler);
			const rowsWithReadyPrompts = createRowsToParse();
			// console.log('prompt', {
			// 	rowsToParse: rowsWithReadyPrompts,
			// 	promptId: prompt.id,
			// 	keywordColumnMap
			// });

			const formattedFilename = papaCSV?.name?.replace('.csv', '');

			// Create 
			const resp = await createPromptResults({
				rowsToParse: rowsWithReadyPrompts,
				promptId: prompt.id,
				fileName: formattedFilename,
				keywordColumnMap
			});
			// console.log(resp);

			if (get(resp, 'data.status') !== 200) {
				throw new Error(get(resp, 'data.result.errors[0]', 'Unkown'));
			}

			// Clear the page-reload warning
			window.onbeforeunload = null;

			// Display success snackbar
			dispatch(setOpenSuccess({
				title: 'Sent Parsed File',
				message: 'View progress in the "Completed Files" section',
			}));

			/* 
				Go to files download page.
				The backend returns the above POST resp after the empty "ai_prompt_result" has been created,
				so we can stream the progress
			*/
			navigate('../completed_files', { relative: 'path' });
		} catch (err) {
			console.error('err', err);
			dispatch(setOpenError({ title: "Error Parsing and Sending File", message: messageFromSuspectedXanoError(err) }));
		} finally {
			setLoading(false);
		}
	};

	if (isReadyToSend) {
		return <LoadingButton
			fullWidth
			loading={loading}
			onClick={parsePromptWithRows}
			sx={{ mt: 2, p: 2 }}
			endIcon={<RocketIcon />}
			size="large"
			variant="contained"
			loadingPosition="end"
		>
			Send to AI
		</LoadingButton>
	} else { return null; }

};

ParseAndUploadButton.propTypes = {
	papaCSV: PropTypes.shape({
		data: PropTypes.arrayOf(PropTypes.object),
		name: PropTypes.string.isRequired
	}),
	prompt: PropTypes.shape({
		text: PropTypes.string.isRequired,
		keywords: PropTypes.arrayOf(PropTypes.object).isRequired,
		id: PropTypes.number.isRequired
	}),
	keywordColumnMap: PropTypes.object.isRequired
};
