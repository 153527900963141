// import { useEffect, useState } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Typography, Accordion, AccordionSummary, AccordionDetails, Popover } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/Help';

import { updatePrompt } from "services/redux/slicers/slicers.prompt-editor";
// import { PERMISSIONS } from 'config/permissions';
import { calculateTextInputWords, N_MAX_TEXT_INPUT_WORDS } from './text-editor';

const SYSTEM_INFO_HINT_TEXT = `This could include:
Company Profile:

Name: Full company name.
Business Activities: Description of operations and services.
Products/Services: List of main products or services.
Unique Selling Points (Optional):

Distinctive Features: Characteristics distinguishing your products/services.
Startup Story (If applicable):

Founding Background: Company's origin story.
Growth and Evolution: Key milestones and achievements.
Current Inquiry Context:

Specify desired tone, voice, context, and length`;

export const SystemInfoEditor = () => {
	const dispatch = useDispatch();
	const prompt = useSelector((state) => state.promptEditor.prompt);
	const [anchorEl, setAnchorEl] = useState(null);
	// const [needToUpgrade, setNeedToUpgrade] = useState(false);
	// const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);

	// useEffect(() => {
	// 	setNeedToUpgrade(!(currentlySelectedAccount?.permissions[PERMISSIONS.promptConfigStaticTextWrite] === true));
	// }, [currentlySelectedAccount]);

	const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
	const handlePopoverClose = () => setAnchorEl(null);
	const textChanged = (e) => {
		const wordsSum = calculateTextInputWords({ key: 'gpt_config.system_info', value: e.target.value }, prompt);

		if (wordsSum <= N_MAX_TEXT_INPUT_WORDS) {
			dispatch(updatePrompt({ key: 'gpt_config.system_info', value: e.target.value }));
		}
	};

	if (!prompt) { return null; }

	return (
		<Accordion sx={{ color: 'white', bgcolor: '#2a2e35' }} disableGutters={true}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography>System Info</Typography>
			</AccordionSummary>

			<AccordionDetails sx={{
				p: 0, m: 2, position: 'relative',
				// opacity: needToUpgrade ? 0.5 : 1,
			}}>
				<HelpIcon
					aria-owns={open ? 'mouse-over-popover' : undefined}
					aria-haspopup="true"
					onMouseEnter={handlePopoverOpen}
					onMouseLeave={handlePopoverClose}
					fontSize="inherit" color='primary'
					sx={{ position: 'absolute', right: '0.5rem', bottom: '0.5rem', zIndex: 1000 }}
				/>

				<Popover
					id="mouse-over-popover"
					sx={{ pointerEvents: 'none' }}
					open={Boolean(anchorEl)}
					anchorEl={anchorEl}
					anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
					transformOrigin={{ vertical: 'top', horizontal: 'right' }}
					onClose={handlePopoverClose}
					disableRestoreFocus
				>
					<Typography paddingX={2} component='div' paddingY={1} sx={{
						width: 'auto',
						maxWidth: '30rem',
						whiteSpace: 'pre-wrap'
					}}>{SYSTEM_INFO_HINT_TEXT}</Typography>
				</Popover>

				{/* {needToUpgrade && (
					<Typography variant='body2'>
						<i>This feature is not available on your current tier.</i>
					</Typography>
				)} */}

				<TextField
					value={prompt?.gpt_config?.system_info}
					multiline={true}
					// disabled={needToUpgrade}
					maxRows={20}
					label="Let the AI know something about itself..."
					onChange={textChanged}
					sx={{
						width: '100%',
						'& .MuiInputBase-input': { color: 'white' },
						'& .Mui-disabled': { color: 'gray' },
						'& .Mui-disabled.root': { color: 'gray' },
					}}
				>
				</TextField>
			</AccordionDetails>
		</Accordion>
	);
};

SystemInfoEditor.propTypes = {};
