import { axiosInstance, rejectIfNotExpiredToken } from 'api/axios-api';

/*
	Create a new Klaviyo Account
*/
export const createKlaviyoAccount = ({ apiKey, accountId, name }) =>
	new Promise((resolve, reject) => {
		const path = "/klaviyo_account/create";
		const params = {
			private_api_key: apiKey,
			account_id: accountId,
			name
		};

		// TODO: yup validate all api inputs
		axiosInstance.post(path, params)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});

/**
 * Get all the webhook prompts for a klaviyo account
 * 
 * @param {Object} param 
 * @param {string} param.klaviyoAccountId - klaviyo account to get webhook prompts for
 * @returns {Promise<Array<Object>>} webhook ai_prompts
 */
export const getWebhookPromptsForKlaviyoAccount = ({ klaviyoAccountId }) =>
	new Promise((resolve, reject) => {
		if (!klaviyoAccountId) { return; }
		const path = `/klaviyo_account/${klaviyoAccountId}/prompts/klaviyo_webhook`;

		// TODO: yup validate all api inputs
		axiosInstance.get(path)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});


/**
 * Get all the campaign prompts for a klaviyo account
 * 
 * @param {Object} param 
 * @param {string} param.klaviyoAccountId - klaviyo account to get campaign prompts for
 * @returns {Promise<Array<Object>>} campaign ai_prompts
 */
export const getCampaignPromptsForKlaviyoAccount = ({ klaviyoAccountId }) =>
	new Promise((resolve, reject) => {
		const path = `/klaviyo_account/${klaviyoAccountId}/prompts/klaviyo_campaign`;

		// TODO: yup validate all api inputs
		axiosInstance.get(path)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});


/*
	Get Klaviyo Templates
*/
export const getKlaviyoTemplates = ({ klaviyoAccountId, searchName }) =>
	new Promise((resolve, reject) => {
		// const path = `/klaviyo/accounts/${klaviyoAccountId}/get_templates?search_name=${encodeURIComponent(searchName)}`;
		const queryParams = new URLSearchParams();
		if (searchName) { queryParams.set('search_name', searchName); }

		const url = `/klaviyo_account/${klaviyoAccountId}/get_templates?${queryParams.toString()}`;

		axiosInstance.get(url)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});

/*
	Render Klaviyo Template
*/
export const renderKlaviyoTemplate = ({ klaviyoAccountId, templateId, data }) =>
	new Promise((resolve, reject) => {
		const path = `/klaviyo_account/${klaviyoAccountId}/templates/${templateId}/render`;

		axiosInstance.post(path, data)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});


/**
 * Searches Klaviyo for campaigns
 * 
 * @param {string} klaviyoAccountId - klaviyo account to search for
 * @param {string} searchTerm - optional search term
 * @returns {Promise<Array[Object]>}
 */
export const searchCampaignsForKlaviyoAccount = (klaviyoAccountId, searchTerm) =>
	new Promise((resolve, reject) => {
		const path = `/klaviyo_account/${klaviyoAccountId}/get_campaigns?search_term=${searchTerm || ''}`;

		axiosInstance.get(path)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});


/**
 * Gets a campaign from a Klaviyo account
 * 
 * @param {string} klaviyoAccountId - klaviyo account to get campaigns for
 * @param {string} campaignId - the campaign's id
 * @returns {Promise<Array[Object]>}
 */
export const getCampaignForKlaviyoAccount = (klaviyoAccountId, campaignId) =>
	new Promise((resolve, reject) => {
		const path = `/klaviyo_account/${klaviyoAccountId}/campaigns/${campaignId}/get`;

		axiosInstance.get(path)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});


/**
 * Searches for profiles related to a specific campaign in Klaviyo.
 *
 * @param {string} klaviyoAccountId - The ID of the Klaviyo account.
 * @param {string} campaignId - The ID of the campaign.
 * @param {object} options - Additional options for the search.
 * @param {string[]} options.includedAudiences - An array of audience IDs to include in the search.
 * @param {string[]} [options.excludedAudiences] - An optional array of audience IDs to exclude from the search.
 * @param {string} [options.searchTerm=''] - An optional search term.
 * @returns {Promise} A promise that resolves with the response from the API or rejects with an error.
 * @throws Will throw an error if the API request fails.
 */
export const searchCampaignProfiles = (
	klaviyoAccountId,
	campaignId, {
		includedAudiences,
		excludedAudiences,
		searchTerm = ''
	}
) =>
	new Promise((resolve, reject) => {
		const path = `/klaviyo_account/${klaviyoAccountId}/campaigns/${campaignId}/profiles`;
		const params = {
			included_campaign_audience: includedAudiences,
			excluded_campaign_audience: excludedAudiences || null,
			search_term: searchTerm
		};
		console.log('params', params);
		axiosInstance.get(path, { params })
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});

/**
 * Renders the templates for a campaign
 * 
 * @param {number} klaviyoAccountId
 * @param {string[]} campaignMessageIds 
 * @param {Object} data 
 * @returns 
 */
export const renderCampaignTemplates = (klaviyoAccountId, campaignMessageIds, data = {}) =>
	new Promise((resolve, reject) => {
		const path = `/klaviyo_account/${klaviyoAccountId}/campaign/render`;

		axiosInstance.post(path, { campaign_message_ids: campaignMessageIds, ...data })
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});


export const klaviyoCampaignRun = (klaviyoAccountId, promptId, isPreview) =>
	new Promise((resolve, reject) => {
		const path = `/klaviyo_account/${klaviyoAccountId}/prompts/${promptId}/campaign_run`;

		axiosInstance.post(path, { is_preview: isPreview })
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});


export const getKlaviyoCampaignSizeEstimate = (klaviyoAccountId, promptId) =>
	new Promise((resolve, reject) => {
		const path = `/klaviyo_account/${klaviyoAccountId}/prompts/${promptId}/campaign_size`;

		axiosInstance.get(path)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});


export const getKlaviyoPromptEvents = (klaviyoAccountId, promptId) =>
	new Promise((resolve, reject) => {
		const path = `/klaviyo_account/${klaviyoAccountId}/prompts/${promptId}/events`;

		axiosInstance.get(path)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});


export const getKlaviyoMetrics = (klaviyoAccountId) =>
	new Promise((resolve, reject) => {
		const path = `/klaviyo_account/${klaviyoAccountId}/metrics`;

		axiosInstance.get(path)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});


export const getKlaviyoEventsForMetric = (klaviyoAccountId, metricId) =>
	new Promise((resolve, reject) => {
		const path = `/klaviyo_account/${klaviyoAccountId}/events`;

		const params = { klaviyo_metric_id: metricId };
		axiosInstance.get(path, { params })
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});

/*
	Updates a klaviyo account's details
*/
export const putKlaviyoAccountDetails = (klaviyoAccount) =>
	new Promise((resolve, reject) => {
		const path = `/klaviyo_account/${klaviyoAccount.id}`;

		axiosInstance.put(path, klaviyoAccount)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});
