import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Tab, Tabs } from "@mui/material";

import { PERMISSIONS } from 'config/permissions';
import { BreadcrumbNavigator } from "components/account-detail-page/breadcrumbs";
import { CSVParseMain } from "components/account-detail-page/tabs/csv-parse/main/csv-parse-main";
import { KlaviyoPage } from "./klaviyo-page";
import { SettingsOrganizationPage } from "components/settings-page/organization/settings-organization-page";
import { SettingsTeamPage } from "components/settings-page/team/settings-team-page";
import { SettingsBillingPage } from "components/settings-page/billing/settings-billing-page";
import { StoreProductsPage } from "./store-products-page";

const TABS = [
	{
		label: 'Klaviyo',
		id: 'klaviyo',
		permissionsAny: [PERMISSIONS.klaviyoCampaignRead, PERMISSIONS.klaviyoWebhookRead],
		mainPage: <KlaviyoPage />
	},
	{
		label: 'CSV Parse',
		id: 'csv_parse',
		permissionsAny: [PERMISSIONS.csvParseRead],
		mainPage: <CSVParseMain />
	},
	{
		label: 'Products',
		id: 'products',
		requiredFeaturesAny: [PERMISSIONS.klaviyoCampaignRead, PERMISSIONS.klaviyoWebhookRead],
		mainPage: <StoreProductsPage />
	},
	{
		label: 'Details',
		id: 'details',
		requiredFeaturesAny: [PERMISSIONS.manageAccount],
		mainPage: <SettingsOrganizationPage />
	},
	{
		label: 'Team',
		id: 'team',
		requiredFeaturesAny: [PERMISSIONS.manageAccount],
		mainPage: <SettingsTeamPage />
	},
	{
		label: 'Billing',
		id: 'billing',
		requiredFeaturesAny: [PERMISSIONS.manageAccount],
		mainPage: <SettingsBillingPage />
	}
];

export const AccountDetailpage = () => {
	const { accountId, tab } = useParams();
	const navigate = useNavigate();
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);

	const [activeTabId, setActiveTabId] = useState(tab ? tab : TABS[0].id);
	const [filteredSubpages, setFilteredSubpages] = useState([]);

	const handleTabChange = (_, tabId) => {
		navigate(`/accounts/${accountId}/${tabId}`);
		setActiveTabId(tabId);
	};

	useEffect(() => {
		console.log('AccountDetailpage currentlySelectedAccount', currentlySelectedAccount);
		if (!currentlySelectedAccount?.permissions) { return; }
		const _filteredTabs = [];
		for (const tab of TABS) {
			if (tab.permissionsAny) {
				for (const perm of tab.permissionsAny) {
					if (currentlySelectedAccount?.permissions[perm] === true) {
						_filteredTabs.push(tab); break;
					}
				}
			} else {
				_filteredTabs.push(tab);
			}
		}
		setFilteredSubpages(_filteredTabs);

		const tab = _filteredTabs.find(page => page.id === activeTabId);
		if (tab) { handleTabChange(null, tab.id); }
		else {
			// Since theres no "tabSubpage", we go back up one route instead of two
			// otherwise we'd remove the actual tab page we're on's path
			handleTabChange(null, _filteredTabs[0].id);
		}
	}, [currentlySelectedAccount]);

	return (<>
		<BreadcrumbNavigator />

		{/* Add the Tabs */}
		<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
			<Tabs value={activeTabId} onChange={handleTabChange} scrollButtons="auto" variant="scrollable">
				{filteredSubpages.map(tab => (
					<Tab
						label={tab.label} value={tab.id} key={tab.id}
						sx={{ p: 2, borderRadius: 1 }}
					/>
				))}
			</Tabs>
		</Box>

		{/* Add the views for the Tabs */}
		{filteredSubpages
			.filter(tab => tab.id === activeTabId)
			.map(tab => (<Box sx={{ pl: 1, pt: 1 }} key={tab.id}>{tab.mainPage}</Box>))
		}
	</>);
};
