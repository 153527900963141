import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";

import { updatePromptPreview } from "services/redux/slicers/slicers.prompt-editor";
// import HandlebarsExtended from "utils/handlebar-compiler";
import HandlebarsExtended from "handlebarsld";

const TEXT_STYLE = {
	whiteSpace: 'pre-wrap',
	maxWidth: 'fit-content',
	width: 'max-content',
	// {font: '1.2rem/1.4 Georgia, serif;',}
	borderRadius: 1,
	backgroundColor: '#eee',
	padding: '1rem'
};

export const PromptPreview = () => {
	const dispatch = useDispatch();
	const [error, setError] = useState(null);
	const { prompt, preview } = useSelector((state) => state.promptEditor);

	// Compile the template (prompt) on page render
	useEffect(() => {
		try {
			// Create a handlebars template
		
			const compiler = new HandlebarsExtended(prompt);
			const populatedPrompt = compiler.renderTemplate(preview.payload);
			
			// compiler.compile(prompt.text);
			console.log('populatedPrompt', populatedPrompt);
			dispatch(updatePromptPreview({ key: 'populatedPrompt', value: populatedPrompt }));
		} catch (error) {
			console.error('error', error);
			setError(error.message);
		}
	}, [dispatch, preview.payload, prompt]);


	if (error) {
		return (
			<Typography>
				<b>Error Occured:</b> <br />
				{error}
			</Typography>
		);
	} else return (
		<Box sx={{ p: 0 }}>
			<Typography sx={TEXT_STYLE}>{preview.populatedPrompt?.text}</Typography>
			<br />

			{Array.isArray(preview.populatedPrompt?.prompt_rules) && preview.populatedPrompt.prompt_rules.length > 0 && (
				<Typography sx={TEXT_STYLE}>
					Rules
					{preview.populatedPrompt.prompt_rules.map(rule => <div key={rule.order}>{rule.rule_text}</div>)}
				</Typography>
			) || (<Typography>No Rules Specified</Typography>)}
		</Box>
	);
};
