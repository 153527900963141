import { Box, Divider, Grid, Skeleton } from "@mui/material";

export const LoadSkeleton = () => (
	<Box>
		{[1, 2, 3].map(index => (
			<Grid container flexDirection={'row'} key={index} alignSelf={'center'}>
				<Grid md={6} xs={12} item>
					<Skeleton width={'150px'} />
					<Skeleton width={'200px'} />
				</Grid>
				<Grid md={3} xs={12} alignSelf={'center'} item>
					<Skeleton width={'100px'} />
				</Grid>
				<Grid md={3} xs={12} item>
					<Grid xs={12} justifyContent={'flex-end'} container item>
						<Skeleton variant="rounded" width={90} height={45} />
					</Grid>
				</Grid>

				<Grid xs={12} paddingY={2.5} item><Divider /></Grid>
			</Grid>
		))}
	</Box>
);
