import axios from "axios";
import { Box, Link, Typography } from "@mui/material";
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from "react-router-dom";

import { PageLoader } from "components/page-loader";

import {
	setUser,
	stopLoading,
	startLoading,
	setAccessToken,
	setAuthenticated,
	setUserAccount,
	setMultiTenancyAccounts
} from "services/redux/slicers/slicers.auth";
import { getCustomerCheckoutSession } from "api/account";
import { getUpgradeWaitingRoomUrl } from "./account-upgrade-waiting-room";
import { messageFromSuspectedXanoError } from "api/axios-api";

const XANO_API_GROUP_BASE_URL = process.env.REACT_APP_XANO_LEADDRIVE_AUTH_URL;
const REDIRECT_URI = process.env.REACT_APP_AUTH0_REDIRECT_URI;
const ERRORS = {
	emailConf: "EMAIL_CONFIRMATION_ERROR",
	reusedLink: "CONFIRMATION_LINK_REUSED",
};

export const AuthCallbackPage = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const DEVELOPMENT_HOOK_LOADING = useRef(false);

	const loading = useSelector((state) => state.auth.loading);
	const [searchParams] = useSearchParams();
	const [error, setError] = useState(null);

	useEffect(() => {
		async function handleCallback() {
			if (DEVELOPMENT_HOOK_LOADING.current) { return; }

			try {
				// Ref Hook for strict mode in development
				DEVELOPMENT_HOOK_LOADING.current = true;

				// Get the "code" from the url. Is present on successful auth when Auth0 does their callback
				const authCode = searchParams.get('code');
				const error = searchParams.get('error');
				const errorDesc = searchParams.get('error_description');
				console.log('error', error, errorDesc);

				if (error && errorDesc && errorDesc.includes('Please verify your email before logging in')) {
					console.log('redirect to email conf page');
					setError(ERRORS.emailConf);
					return;
				} else if (error && errorDesc && errorDesc.includes('This URL can be used only once')) {
					setError(ERRORS.emailConf);
					return;
				} else if (!authCode) {
					console.error('Visited callback page without an authcode');
					setError('Visited callback page without an authcode');
					return;
				}

				// Start loading
				dispatch(startLoading());

				const type = searchParams.get('type');
				const quantity = searchParams.get('quantity');
				const priceId = searchParams.get('price_id');
				const stripeProductId = searchParams.get('stripe_product_id');
				const accountId = searchParams.get('account_id');

				// Check user
				const params = {
					code: authCode,
					redirect_uri: REDIRECT_URI,
					invitation_uuid: searchParams.get('invitation_uuid'),
				};

				console.log('auth callback type', { type, quantity, priceId });
				const authResp = await axios.get(XANO_API_GROUP_BASE_URL + '/oauth/auth0/continue', { params })
				const { token, user, account, multi_tenancy_accounts } = authResp.data;
				console.log('authResp.data', authResp.data);
				// Set token, user and isAuthenticated
				dispatch(setAccessToken(token));
				dispatch(setUserAccount(account));
				dispatch(setUser(user));
				dispatch(setAuthenticated(true));
				dispatch(setMultiTenancyAccounts(multi_tenancy_accounts));

				// Based on the props passed along during the login, redirect to the correct page
				if (type === 'pricing_table') {
					// TODO dont let the 
					if (accountId === 'undefined' && account.stripe_subscription_status) {
						// user got started anonymously, but already has an account with a subscription
						// Dont upgrade them from the public pricing page, as its not clear to 
						// them which account they're upgrading
						console.log('user came anonymously, but is already connected to an accuont', account, account.stripe_subscription_status);
						window.location.href = window.location.origin + '/settings/billing';
						return;
					} else {
						const accountUpgradeId = (accountId !== "undefined" && accountId) || user.account_id;
						const upgradeUrl = getUpgradeWaitingRoomUrl(quantity, stripeProductId, accountUpgradeId);
						const params = {
							quantity: quantity,
							price_id: priceId,
							success_url: window.location.origin + upgradeUrl,
							account_id: accountUpgradeId
						};

						console.log('pricing table', params);
						const sessionResp = await getCustomerCheckoutSession(accountUpgradeId, params)
						console.log('resp', sessionResp);
						window.open(sessionResp.data.customer_checkout_session.url, '_self');
					}
				} else {
					// Redirect to /overview
					navigate('/overview');
				}
			} catch (error) {
				console.error('Error checking user callback', error);
				setError(messageFromSuspectedXanoError(error));
			} finally {
				dispatch(stopLoading());
			}
		}
		handleCallback();
	}, [dispatch, searchParams, navigate]);

	if (error) {
		return (
			<Box width={'100%'} textAlign={'center'}>
				<Typography fontSize={'10rem'}>
					Oh No :(
				</Typography>
				<Typography variant="body1" fontSize={'1.5rem'} sx={{ paddingTop: '1rem' }}>
					Error Signin In
					<br /><br />
					{error}
				</Typography>

				<Link href="mailto:support@leaddrive.ai?subject=Error%20signing%20in" variant="body1" fontSize={'1.5rem'} sx={{ paddingTop: '1rem' }}>
					<Typography>
						Contact Support
					</Typography>
				</Link>
			</Box>
		);
	} else if (loading) {
		return <PageLoader />
	} else { return null; }
};

