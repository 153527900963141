// import { useEffect, useState } from "react";
// import { PropTypes } from 'prop-types';
// import { get } from "lodash";
// import {
// 	Typography,
// 	Unstable_Grid2 as Grid
// } from "@mui/material";

// import { PulseIcon } from "./pulse-icon";

// const REACT_APP_DO_BACKEND_DROPLET_BASE_URL = process.env.REACT_APP_DO_BACKEND_DROPLET_BASE_URL;

// export const SSEStreamColumn = ({ params, refreshAccountResults }) => {
// 	const [sseValue, setSseValue] = useState(null); // State
// 	const [isSubscribed, setIsSubscribed] = useState(false); // State

// 	const { id, droplet_file_path, job_status, total_parsed_rows } = params.row;

// 	useEffect(() => {
// 		// Only subscribe to a row if it been created and no file has been attached to it
// 		if (!(job_status === 'created' && droplet_file_path === '')) return;

// 		const SSEKey = id;
// 		// console.log('subscribed to ' + SSEKey);

// 		const eventSource = new EventSource(`${REACT_APP_DO_BACKEND_DROPLET_BASE_URL}/stream/${SSEKey}/start`);
// 		// const eventSource = new EventSource(`http://localhost:3001/stream/test/${SSEKey}/start`);

// 		eventSource.onmessage = (e) => {
// 			const parsedRows = get(JSON.parse(e.data), SSEKey);
// 			if (parsedRows === undefined) { return eventSource.close(); }
// 			setSseValue(parsedRows);

// 			if (parsedRows === total_parsed_rows && total_parsed_rows > 0) {
// 				// Get and set the new ai prompt result to show the completed status
// 				refreshAccountResults();
// 				setIsSubscribed(false);
// 			}
// 		};

// 		setIsSubscribed(true);

// 		// Clean up by closing subscription to the backend (when column is hidden / screen closed)
// 		return () => { eventSource.close(); setIsSubscribed(false); };

// 	}, [
// 		job_status, droplet_file_path, id, total_parsed_rows,
// 		setSseValue, setIsSubscribed,
// 		refreshAccountResults
// 	]);


// 	if (isSubscribed) {
// 		return (
// 			<Grid container alignItems={'center'} spacing={1.5} direction={'row'}>
// 				<Grid xs={'auto'}>
// 					{sseValue !== null && (
// 						<Typography>
// 							{sseValue}&nbsp;\&nbsp;{total_parsed_rows}
// 						</Typography>
// 					)}
// 				</Grid>

// 				<Grid xs={3}><PulseIcon /></Grid>
// 			</Grid>
// 		);
// 	} else return null;
// };

// SSEStreamColumn.propTypes = {
// 	params: PropTypes.shape({
// 		row: PropTypes.shape({
// 			id: PropTypes.number.isRequired,
// 			droplet_file_path: PropTypes.string.isRequired,
// 			job_status: PropTypes.string.isRequired,
// 			total_parsed_rows: PropTypes.number.isRequired
// 		}).isRequired
// 	}).isRequired,
// 	refreshAccountResults: PropTypes.func.isRequired
// };

import { useEffect, useState } from "react";
import { PropTypes } from 'prop-types';
import { get } from "lodash";
import { Typography, Unstable_Grid2 as Grid } from "@mui/material";

import { PulseIcon } from "./pulse-icon";
import { useDispatch } from "react-redux";
import { updateCampaignJobRunning } from "services/redux/slicers/slicers.prompt-editor";

const REACT_APP_DO_BACKEND_DROPLET_BASE_URL = process.env.REACT_APP_DO_BACKEND_DROPLET_BASE_URL;

export const SSEStreamColumn = ({ row = {}, refreshResults, nResults }) => {
	const dispatch = useDispatch();
	const [sseValue, setSseValue] = useState(null); // State
	const [isSubscribed, setIsSubscribed] = useState(false); // State

	useEffect(() => {
		const { id, droplet_file_path, job_status } = row;

		// Only subscribe to a row if it been created and no file has been attached to it
		// if (!(job_status === 'created' && droplet_file_path === '')) return;
		if (!(job_status === 'created' && !droplet_file_path)) { return; }

		const SSEKey = id;
		// console.log('subscribed to ' + SSEKey);

		const eventSource = new EventSource(`${REACT_APP_DO_BACKEND_DROPLET_BASE_URL}/stream/${SSEKey}/start`);
		// const eventSource = new EventSource(`http://localhost:3001/stream/test/${SSEKey}/start`);

		eventSource.onmessage = (e) => {
			const parsedRows = get(JSON.parse(e.data), SSEKey);
			if (parsedRows === undefined) { return eventSource.close(); }
			console.log('parsedRows', parsedRows);
			setSseValue(parsedRows);

			if (parsedRows === nResults && nResults > 0) {
				// Get and set the new ai prompt result to show the completed status
				refreshResults();
				setIsSubscribed(false);
				eventSource.close();
				dispatch(updateCampaignJobRunning(false));
			}
		};

		setIsSubscribed(true);

		// Clean up by closing subscription to the backend (when column is hidden / screen closed)
		return () => { eventSource.close(); setIsSubscribed(false); };
	}, [dispatch, row, nResults, refreshResults]);

	if (isSubscribed) {
		return (
			<Grid container alignItems={'center'} spacing={1.5} direction={'row'}>
				<Grid xs={'auto'}>
					{sseValue !== null && (
						<Typography>
							{sseValue}&nbsp;\&nbsp;{nResults}
						</Typography>
					)}
				</Grid>

				<Grid xs={3}><PulseIcon /></Grid>
			</Grid>
		);
	} else return null;
};

SSEStreamColumn.propTypes = {
	row: PropTypes.shape({
		id: PropTypes.number.isRequired,
		droplet_file_path: PropTypes.string.isRequired,
		job_status: PropTypes.string.isRequired,
	}).isRequired,
	refreshResults: PropTypes.func.isRequired,
	nResults: PropTypes.number.isRequired
};
