import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { PropTypes } from 'prop-types';

export const StyledDataGrid = (props) => <DataGrid
	{...props}
	sx={{
		'& .MuiDataGrid-columnHeaders': { backgroundColor: 'rgb(248, 249, 250)' },
		'& .MuiDataGrid-withBorderColor': { borderBottom: '1px solid rgb(242, 244, 247)', },
		'& .MuiDataGrid-footerContainer': { borderTop: '1px solid rgb(242, 244, 247)' },
		boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px',
		borderRadius: '1.25rem',
		bgcolor: 'background.paper',
		...props.sx
	}}
/>

StyledDataGrid.propTypes = {
	sx: PropTypes.object
};
