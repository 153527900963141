import { toString } from 'lodash';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';

import { clearAccountsState } from 'services/redux/slicers/slicers.accounts';
import { clearPrompt } from 'services/redux/slicers/slicers.prompt-editor';
import { clearSnackbar } from 'services/redux/slicers/slicers.snackbar';

export const ErrorBoundaryFallback = ({ error, resetErrorBoundary }) => {
	const dispatch = useDispatch();

	function clearState() {
		dispatch(clearAccountsState());
		dispatch(clearPrompt());
		dispatch(clearSnackbar());
		localStorage.clear();
		resetErrorBoundary();
	}

	return (
		<Box sx={{ p: 2 }}>
			<Typography component='div'>
				Damn. Sorry about that. Grab a screenshot with a description of what you did.

				<br />
				<Button variant='contained' onClick={clearState}>Clear State and try again</Button>

				<Typography sx={{ color: 'red', mt: 3 }}>{toString(error.message)}</Typography>
			</Typography>
		</Box>
	);
};

ErrorBoundaryFallback.propTypes = {
	error: PropTypes.shape({ message: PropTypes.string.isRequired }).isRequired,
	resetErrorBoundary: PropTypes.func.isRequired
};
