import { axiosInstance, rejectIfNotExpiredToken } from 'api/axios-api';

/*
	Get the currently signed in user's Accounts
*/
export const getAuthedUsersAccounts = () =>
	new Promise((resolve, reject) => {

		const path = '/user/accounts';
		axiosInstance.get(path)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});


/*
	Update the authed user's details
*/
export const putUserDetails = (data) =>
	new Promise((resolve, reject) => {

		const path = '/user/details';
		axiosInstance.put(path, data)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});