import { useDispatch, useSelector } from "react-redux";
import { Unstable_Grid2 as Grid } from "@mui/material";

import { AccountsTable } from "components/accounts-page/accounts-table";
import { AddNewAccount } from "components/accounts-page/add-new-account";
import { ACCOUNT_TYPES } from "config/permissions";
import { useEffect } from "react";
import { setCurrentlySelectedAccount } from "services/redux/slicers/slicers.accounts";

export const AccountsPage = () => {
	const dispatch = useDispatch();
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);
	const userAccount = useSelector((state) => state.auth.userAccount); // Redux

	useEffect(() => {
		// Set user's account as currently selected
		dispatch(setCurrentlySelectedAccount(userAccount));
	}, [userAccount, dispatch]);

	return (
		<Grid container>
			<Grid xs={12} lg={8}>
				{/* Only show Create Acc button if the user has access */}

				{currentlySelectedAccount?.type === ACCOUNT_TYPES.agency && (
					<AddNewAccount />
				)}
				<AccountsTable />
			</Grid>
		</Grid>
	);
};