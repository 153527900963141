import { useSelector } from 'react-redux';
import { Typography, Unstable_Grid2 as Grid } from "@mui/material";
import { FilesOverviewTable } from "components/account-detail-page/tabs/csv-parse/files-overview-page/files-overview-table";

export const FilesOverviewPage = () => {
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected); // Redux

	if (!currentlySelectedAccount) {
		return (
			<Typography>
				Select an Account
			</Typography>
		)
	} else return (
		<Grid container>
			<Grid xs={12}>
				<FilesOverviewTable />
			</Grid>
		</Grid>
	);
};
