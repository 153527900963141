import { PropTypes } from 'prop-types';
import { Alert, Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, MenuList, Popover, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';

import { logout } from 'services/redux/slicers/slicers.auth';
import { clearAccountsState } from 'services/redux/slicers/slicers.accounts';
import { putUserDetails } from 'api/user';
import { useState } from 'react';
import { messageFromSuspectedXanoError } from 'api/axios-api';

export const AccountPopover = (props) => {
	const dispatch = useDispatch();
	const { anchorEl, onClose, open } = props;
	const user = useSelector((state) => state.auth.user);
	const multiTenancyAccounts = useSelector((state) => state.auth.multiTenancyAccounts);
	const userAccount = useSelector((state) => state.auth.userAccount);
	const [error, setError] = useState(null);

	const handleSignout = (accountId) => {
		if (accountId == user.account_id) { return; }
		if (accountId && accountId != user.account_id) {
			// Update the users active account and clear account state to auto-sign in again
			setError(null);
			putUserDetails({ account_id: accountId })
				.then(() => {
					dispatch(clearAccountsState());
					dispatch(logout());
				}).catch(err => {
					console.error('error signing out', err);
					setError(messageFromSuspectedXanoError(err));
				});
		} else {
			dispatch(clearAccountsState());
			dispatch(logout());
			window.location.href = process.env.REACT_APP_AUTH0_LOGOUT_URI;
		}
	};

	return (
		<Popover
			open={open}
			onClose={onClose}
			anchorEl={anchorEl}
			sx={{ maxWidth: '280px' }}
			anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
			transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
		>
			<Box sx={{ py: 1.5, px: 2 }}>
				<Typography variant="overline">{user.name}</Typography>
				<Typography color="text.secondary" variant="body2">
					{user.email}
				</Typography>
			</Box>

			{Array.isArray(multiTenancyAccounts) && multiTenancyAccounts.length > 0 && (<>
				<Divider />
				<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
					{multiTenancyAccounts?.map((account) => {
						return (
							<ListItem key={account.id} disablePadding dense>
								<ListItemButton onClick={() => handleSignout(account.id)} selected={account.id === userAccount.id}>
									<ListItemText primary={account.name} />
									{account.id === userAccount.id && (
										<ListItemIcon><CheckIcon color='success' /></ListItemIcon>
									) || (<ListItemIcon></ListItemIcon>)}

								</ListItemButton>
							</ListItem>
						);
					})}
				</List >
			</>)}

			<Divider />

			<MenuList disablePadding dense sx={{ p: '0px', '& > *': { borderRadius: 1 } }}>
				<MenuItem sx={{ px: 2, py: 1.5 }} onClick={() => handleSignout()}> {/* <-pass no account id  */}
					Sign out
				</MenuItem>
			</MenuList>

			{error && (<Alert variant="filled" severity="error" sx={{ margin: 1 }}>{error}</Alert>)}
		</Popover >
	);
};

AccountPopover.propTypes = {
	anchorEl: PropTypes.any,
	onClose: PropTypes.func,
	open: PropTypes.bool.isRequired
};
