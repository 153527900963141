import React, { useEffect, useState } from 'react';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { LimitGauge } from 'components/home-page/limit-gauge';
import { getUsageLimits } from 'api/account';
// import { setOpenError } from 'services/redux/slicers/slicers.snackbar';
// import { messageFromSuspectedXanoError } from 'api/axios-api';

const GAUGES = {
	klaviyo: { // key must match what is returned from the backend
		title: 'Monthly Klaviyo Usage',
		labels: ['Monthly Usage', 'Remaining'],
		limit: null,
		usage: null
	},
	csv_parse: { // key must match what is returned from the backend
		title: 'Monthly CSV Parse',
		labels: ['Monthly Usage', 'Remaining'],
		limit: null,
		usage: null
	}
};

export const Limits = () => {
	const dispatch = useDispatch();
	// const user = useSelector((state) => state.auth.user);
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);
	const [gauges, setGauges] = useState([]);

	useEffect(() => {
		if (!currentlySelectedAccount?.id) { return; }
		getUsageLimits(currentlySelectedAccount.id)
			.then(resp => {
				// console.log('limits', resp);
				// For the usage limits returned, update the state with the values returned
				// The GAUGES object's keys should match the ids returned in from the backend
				const _gauges = [];
				// resp.data.forEach(usageLimit => {
				for (let i = 0; i < resp.data.length; i++) {
					const usageLimit = resp.data[i];
					const gauge = GAUGES[usageLimit.id];
					gauge.limit = usageLimit.limit;
					gauge.usage = usageLimit.usage;
					if (usageLimit.limit) { _gauges.push(gauge); }
				}
				// });
				setGauges(_gauges);
			})
			.catch(err =>
				console.error('Error Getting Usage Limits', err)
				// dispatch(setOpenError({ title: 'Error Getting Usage Limits', message: messageFromSuspectedXanoError(err) })
			);
	}, [setGauges, dispatch, currentlySelectedAccount?.id])

	return (
		<Grid container xs={12} justifyContent={'center'}>
			{gauges.map(gauge =>
				<LimitGauge
					key={gauge.title}
					title={gauge.title}
					usage={gauge.usage}
					limit={gauge.limit}
					tooltipLabels={gauge.labels}
				/>
			)}
		</Grid>
	);
};
