import { axiosInstance, rejectIfNotExpiredToken } from 'api/axios-api';

/*
	Get all the models 
*/
export const getGptModels = (accountId) =>
	new Promise((resolve, reject) => {
		const path = '/gpt_models';
		const params = { account_id: accountId };
		axiosInstance.get(path, { params })
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});
