import { Box, Typography } from "@mui/material";
import { PropTypes } from 'prop-types'

import { ClickToCopyText } from './click-to-copy-text';

/**
 * Renders a <ClickToCopyText /> component with a header attached
 * @param {string} label - The header for value
 * @param {string} value - The value to copy
 * @param {object} sx - Styling to be applied to root element
 */
export const ClickToCopyTextAndLabel = ({ label, value, sx }) => (
	<Box sx={{ ...sx }}>
		<Typography><b>{label}</b></Typography>
		<ClickToCopyText
			label={label}
			value={value}
		/>
	</Box>
);

ClickToCopyTextAndLabel.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	sx: PropTypes.object
};