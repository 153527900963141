import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AuthenticationGuard } from 'utils/authentication-guard';
import { AuthLoginPage } from 'pages/auth-login-page';
import { AuthCallbackPage } from 'pages/auth-callback';
import { OverviewPage } from 'pages/overview-page';
import { AccountsPage } from 'pages/accounts-page';
import { PricingPage } from 'pages/pricing-page';
import { AccountDetailpage } from 'pages/account-detail-page';
import { PaymentLinkLandingPage } from 'pages/payment-link-landing-page';
import { SettingsPage } from 'pages/settings-page';
import { AccountUpgradeWaitingRoom } from 'pages/account-upgrade-waiting-room';
import { KlaviyoAccounts } from 'pages/klaviyo-accounts';
import { KlaviyoAccountDetailPage } from 'pages/klaviyo-account-detail-page';
import { StoreProductsPage } from 'pages/store-products-page';

import { CSVParseMain } from 'components/account-detail-page/tabs/csv-parse/main/csv-parse-main';

// Create the secure axios instance before any requests are made
import { store } from 'services/redux/stores/index';
import { setupAxiosInstance } from 'api/axios-api';
setupAxiosInstance(store);

// const logo =
// 	'88       888888      .d888b.      88888b.   88888b.   88888b   88  9b.     .db  888888         .d888b.      88' + '\n' +
// 	'88       88         .d8P 98b.     88   "8b  88   "8b  88 .dP   88   9b.   .db   88            .d8` `8b.     88' + '\n' +
// 	'88       8888      .d8P___98b.    88    88  88    88  889b.    88    9b. .db    8888         .d8P___98b.    88' + '\n' +
// 	'88       88       .d8P     98b.   88   "8b  88   "8b  88  8    88     9..db     88          .d8P     98b.   88' + '\n' +
// 	'8888888  888888  .d8P       98b.  88888P`   88888P`   88  9b   88      98P      888888  o  .d8P       98b.  88';
// console.log(logo);

export const App = () => {
	document.title = 'Leaddrive';

	return (
		<Routes>
			<Route path='/' element={<Navigate to='/overview' />} />
			<Route path='/pricing' element={<PricingPage />} />
			<Route path='/payment-link-success' element={<PaymentLinkLandingPage />} />
			<Route path='/pricing/confirmation' element={<AuthenticationGuard Component={AccountUpgradeWaitingRoom} />} />

			{/* <Route path='/invites/:inviteUuid/accept' element={<InviteAcceptPage />} /> */}

			<Route path='/login' element={<AuthLoginPage />} />
			<Route path='/login/callback' element={<AuthCallbackPage />} />

			{/* TODO: validate the page with the user's feature access like in the side nav */}
			<Route path='/overview' element={<AuthenticationGuard Component={OverviewPage} />} />
			<Route path='/settings' element={<AuthenticationGuard Component={SettingsPage} />} />
			<Route path='/products' element={<AuthenticationGuard Component={StoreProductsPage} />} />
			<Route path='/settings/:tab' element={<AuthenticationGuard Component={SettingsPage} />} />
			<Route path='/klaviyo' element={<AuthenticationGuard Component={KlaviyoAccounts} />} />
			<Route path='/csv/:tabSubpage' element={<AuthenticationGuard Component={CSVParseMain} />} />
			<Route path='/klaviyo/:klaviyoAccountId' element={<AuthenticationGuard Component={KlaviyoAccountDetailPage} />} />
			<Route path='/klaviyo/:klaviyoAccountId/:tabSubpage' element={<AuthenticationGuard Component={KlaviyoAccountDetailPage} />} />

			<Route path='/accounts/:accountId/:tab/:klaviyoAccountId/:tabSubpage' element={<AuthenticationGuard Component={AccountDetailpage} />} />
			<Route path='/accounts/:accountId/:tab/:tabSubpage' element={<AuthenticationGuard Component={AccountDetailpage} />} />
			<Route path='/accounts/:accountId/:tab' element={<AuthenticationGuard Component={AccountDetailpage} />} />
			<Route path='/accounts' element={<AuthenticationGuard Component={AccountsPage} />} />


			{/* TODO: Create 404 page */}
			{/* <Route path="*" element={<NotFoundPage />} /> */}
			<Route path='*' element={<Navigate to='/' />} />

		</Routes>
	);
};