import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	open: false,
	title: null,
	message: null,
	type: null // warning, success, error, info    
};

export const snackbarSlice = createSlice({
	name: 'snackbar',

	initialState,

	reducers: {
		setOpen: (state, action) => {
			state.open = true;
			state.title = action.payload.title;
			state.message = action.payload.message;
			state.type = action.payload.type;
		},
		setOpenError: (state, action) => {
			state.open = true;
			state.title = action.payload.title;
			state.message = action.payload.message;
			state.type = "error";
		},
		setOpenWarning: (state, action) => {
			state.open = true;
			state.title = action.payload.title;
			state.message = action.payload.message;
			state.type = "warning";
		},		
		setOpenSuccess: (state, action) => {
			state.open = true;
			state.title = action.payload.title;
			state.message = action.payload.message;
			state.type = "success";
		},
		setOpenInfo: (state, action) => {
			state.open = true;
			state.title = action.payload.title;
			state.message = action.payload.message;
			state.type = "info";
		},
		setClose: (state) => {
			state.open = false;
		},
		clearSnackbar: (state) => {
			Object
				.keys(initialState)
				.forEach(key => state[key] = initialState[key]);
		}
	}
});

// Action creators are generated for each case reducer function
export const {
	setOpen,
	setOpenError,
	setOpenWarning,
	setOpenSuccess,
	setOpenInfo,
	setClose,
	clearSnackbar
} = snackbarSlice.actions;

export default snackbarSlice.reducer;
