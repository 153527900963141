import { snakeCase } from 'lodash';

import HandlebarsExtended from 'handlebarsld';

/**
 * Creates an empty prompt object with predefined keywords and default configuration.
 */
export const createEmptyPrompt = () => {
	const websiteSloganKeyword = {
		isCompanyWebsite: false,
		inputType: "scraper",
		name: "Company Website Title",
		key: "company_website_title",
		type: "prompt_referenced"
	};

	const websiteTitleKeyword = {
		isCompanyWebsite: false,
		inputType: "scraper",
		name: "Company Website Slogan",
		key: "company_slogan",
		type: "prompt_referenced"
	};

	const websiteSiteKeyword = {
		isCompanyWebsite: true,
		inputType: "user",
		name: "Company Website Url",
		key: "company_website_url",
		type: "prompt_referenced"
	};

	const keywords = [websiteSloganKeyword, websiteTitleKeyword, websiteSiteKeyword];
	return {
		name: '',
		description: '',
		text: '',
		type: 'manual_csv_parse',
		keywords,
		gpt_config: {
			temperature: 1,
			top_p: 1,
			presence_penalty: 0,
			frequency_penalty: 0
		}
	};
};


/**
 * Creates a keyword object based on the provided name and type.
 *
 * @param {string} name - The name of the keyword.
 * @param {'prompt_referenced' | 'prompt_extra'} type - The type of the keyword.
 * @returns {Object | null} Returns a keyword object or null if the key cannot be generated.
 * @throws {Error} Throws an error if the key cannot be generated and caught above.
 */
export const createKeyword = (name, type) => {
	// Turn the name into an id
	const key = snakeCase(name);

	// We need a key to add a keyword
	// TODO: throw error here - make sure it gets caught above though
	if (!key) { return null; }

	// Construct the keyword, matching the Xano "Prompt Keyword" structure
	return {
		name, key, type,
		inputType: 'user',
		isCompanyWebsite: false,
	};
};

/**
 * Asynchronously validates a prompt template.
 *
 * @param {Object} prompt - The prompt object to validate.
 * @param {Array<Object>} prompt.keywords - An array of keyword objects associated with the prompt.
 * @param {string} prompt.text - The text of the prompt containing Handlebars placeholders.
 * @returns {Promise<{ valid: boolean, error: Error | null }>} A promise that resolves to an object with validation results.
 * @throws {Error} Throws an error if validation fails.
 */
export const validateTemplate = async (prompt) => {
	let valid = true;
	try {
		// Ensure all keywords that are for the user to map and are required, are in the prompt
		// "type" is do distinquish the keywords that are used for stuff like Zapier integrations where a field is required, 
		// 		but might not be used in the prompt text
		const requiredKeywordsInPrompt = {};
		prompt.keywords
			.filter(keyword => !keyword.isCompanyWebsite && keyword.type != 'prompt_extra')
			.forEach(keyword => requiredKeywordsInPrompt[keyword.key] = keyword.name);

		// Generate the prompt
		const compiler = new HandlebarsExtended(prompt)
			.throwOnMissingKeywords();

		const populatedPrompt = compiler.renderTemplate(requiredKeywordsInPrompt);

		/* 
			Check if a keyword is saved, but not used in the text.
			We do this by checking if we can find the keyword's name in the text as this would be present if the
				keyword was correctly referenced
		*/
		// TODO: fix how we valdiate. if keyword "asd" is created and "asd" is in the text, the user can skip adding the {{asd}} which is not good
		Object.keys(requiredKeywordsInPrompt).forEach(keywordId => {
			if (!populatedPrompt.text.includes(requiredKeywordsInPrompt[keywordId])) {
				throw new Error(`Missing keyword reference in text: {{${keywordId}}}`);
			}
		});

		return { valid, error: null };
	} catch (error) {
		return { valid: false, error };
	}
};

/**
 * Determines whether the prompt is ready to be upserted (inserted or updated).
 * Validate if the create/update button should be shown
 *
 * @param {Object} params - The parameters for the upsert decision.
 * @param {'insert' | 'update'} params.actionType - The type of action to perform ('insert' or 'update').
 * @param {Object} params.currentPrompt - The current prompt
 * @param {boolean} params.arePromptsEqual - Indicates whether the current prompt is equal to the original prompt.
 * @param {number} params.minKeywords - The minimum number of keywords required for insertion.
 * @returns {boolean} Returns true if the prompt is ready to be upserted, otherwise false.
 */
export const isPromptReadyToBeUpserted = ({ actionType, currentPrompt, arePromptsEqual, minKeywords }) => {
	if (actionType === 'insert') {
		// If all the below requirements have been met, show the create button
		return Boolean(
			currentPrompt.text
			&& currentPrompt.name
			&& currentPrompt.keywords.length >= minKeywords
		);
	} else if (actionType === 'update') {
		// If any of the values have been changed, show the update button
		return !arePromptsEqual;
	}

	return false;
};
