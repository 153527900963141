import { axiosInstance, rejectIfNotExpiredToken } from 'api/axios-api';

/*
	POST the rows to be parsed in the backend and create prompt results
*/
export const createPromptResults = ({ rowsToParse, promptId, keywordColumnMap, fileName }) =>
	new Promise((resolve, reject) => {
		const path = "/parsed_file/create";
		const params = {
			rows_to_parse: rowsToParse,
			ai_prompt_id: promptId,
			keyword_column_map: keywordColumnMap,
			file_name: fileName
		};

		axiosInstance.post(path, params)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});

/*
	GET the file data for the download url
*/
export const downloadPromptResultFile = ({ promptResultId }) =>
	new Promise((resolve, reject) => {
		const path = "/parsed_file/get";
		const params = { ai_prompt_result_id: promptResultId };

		axiosInstance.get(path, { params })
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});


/*
	GET the file data for the download url
*/
export const getPromptResultsForPrompt = (promptId, type) =>
	new Promise((resolve, reject) => {
		const path = `/prompts/${promptId}/results/${type}/get_all`;

		axiosInstance.get(path)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});
