import { configureStore, combineReducers } from '@reduxjs/toolkit';

// Slicers
import authReducer from 'services/redux/slicers/slicers.auth';
import accountsReducer from 'services/redux/slicers/slicers.accounts';
import snackbarReducer from 'services/redux/slicers/slicers.snackbar';
import propmptEditorReducer from 'services/redux/slicers/slicers.prompt-editor';
import klaviyoPageSlice from 'services/redux/slicers/slicers.klaviyo-page';

// Persist Storage
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
const persistConfig = { key: 'root', storage };

// Combine Reducers
export const rootReducer = combineReducers({
	auth: authReducer,
	accounts: accountsReducer,
	snackbar: snackbarReducer,
	promptEditor: propmptEditorReducer,
	klaviyo: klaviyoPageSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: [thunk]
});

export const persistor = persistStore(store);
