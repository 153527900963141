import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
	Typography,
	Paper,
	TextField,
	Unstable_Grid2 as Grid,
	Box,
} from "@mui/material";

import { updatePrompt } from "services/redux/slicers/slicers.prompt-editor";
import { KeywordChip } from "components/account-detail-page/tabs/csv-parse/prompt-editor-page/keyword-manager-chip";
import { KeywordManagerAddButton } from './keyword-manager-add-button';
import { createKeyword } from "./utilities";

export const KeywordManager = () => {
	const dispatch = useDispatch(); // Redux
	const [newKeywordText, setNewKeywordText] = useState(''); // State
	const prompt = useSelector((state) => state.promptEditor.prompt); // Redux
	// Deconstruct the keyword into this object of the different keyword types. Makes rendering and editing a lot easier
	const [keywords, setKeywords] = useState({ user: [], scraper: [], extra: [] });

	useEffect(() => {
		// Get the prompt's keywords and add them to the deconstructe object
		const _keywords = { user: [], scraper: [], extra: [] };
		// console.log('prompt.keywords', prompt.keywords);
		prompt?.keywords?.forEach(keyword => {
			if (keyword.type === 'prompt_extra') { _keywords.extra.push(keyword); }
			else { _keywords[keyword.inputType].push(keyword); }
		});

		setKeywords(_keywords);
	}, [prompt]);

	// We need a prompt with keywords to display this component
	if (!prompt || !prompt.keywords) { return null; }


	// Add a new keyword to the local, deconstruct list and the parent's prompt
	const addNewKeyword = (type) => {
		const keyword = createKeyword(newKeywordText, type);
		if (!keyword) { return; }

		// TODO: validate that there doesn't exist a keyword with the same label and, or key
		const _keywords = { ...keywords };
		_keywords.user.push(keyword);
		updateKeywords(_keywords);
	};

	// Delete the keyword from local and parent prompt
	const deleteKeyword = (keywordToDelete, deletable) => {
		if (!deletable) { return; }
		// TODO: Create some sort of logic here. Maybe if the keyword is used, show an alarm. Show a confirmation dialog ?
		const updatedKeywords = {};
		Object
			.keys({ ...keywords })
			.forEach(group => updatedKeywords[group] = keywords[group].filter(keyword => keyword.key !== keywordToDelete.key))

		updateKeywords(updatedKeywords);
	};

	// Utility function for updating the keywords
	const updateKeywords = (_keywords) => {
		setKeywords(_keywords);
		setNewKeywordText('');
		dispatch(updatePrompt({ key: 'keywords', value: Object.values(_keywords).flat() }));
	};

	return (
		<Paper
			elevation={24}
			sx={{ p: 2, borderRadius: 2, bgcolor: '#2a2e35' }}
		>
			<Grid container flexDirection="column">
				{/* User Keywords */}
				<Typography color="white" variant="h5" sx={{ textAlign: 'center', mb: 1 }}>
					Keywords
				</Typography>

				<Grid container>
					{keywords.user.map(keyword =>
						<KeywordChip
							keyword={keyword}
							key={keyword.key}
							color="secondary"
							deleteKeyword={deleteKeyword}
							deletable={!keyword.isCompanyWebsite}
						/>)
					}
				</Grid>

				<Grid xs={12}>
					<TextField
						label="New Keyword"
						variant="filled"
						fullWidth
						value={newKeywordText}
						onChange={e => setNewKeywordText(e.target.value)}
						sx={{ mt: 1, '& .MuiInputBase-input': { color: 'white' } }}
					/>
				</Grid>

				<Grid xs={12}>
					<KeywordManagerAddButton newKeywordAdded={addNewKeyword} />
				</Grid>


				{/* Extra Keywords */}
				{keywords.extra.length > 0 && (
					<Box>
						<Typography color="white" variant="subtitle1" sx={{ textAlign: 'center' }}>
							Extra
						</Typography>

						<Grid container>
							{keywords.extra.map(keyword =>
								<KeywordChip
									keyword={keyword}
									key={keyword.key}
									color="success"
									deleteKeyword={deleteKeyword}
									// TODO: same as keyword-manager. Actually implement this feature
									// deletable={userPermissions.CAN_CREATE_NON_REFERENCED_KEYWORDS} // only allow deletion if user can create them
								/>
							)}
						</Grid>
					</Box>
				)}


				{/* Scraper Keywords */}
				{keywords.scraper.length > 0 && (
					<Box>
						<Typography color="white" variant="subtitle1" sx={{ textAlign: 'center' }}>
							From Scraper
						</Typography>

						{keywords.scraper.map(keyword =>
							<KeywordChip
								keyword={keyword}
								key={keyword.key}
								color="info"
							/>
						)}
					</Box>
				)}

			</Grid >
		</Paper >
	);
};

KeywordManager.propTypes = {

};
