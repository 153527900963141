import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Unstable_Grid2 as Grid } from "@mui/material";

import { TextEditor } from "components/shared-components/prompt-editor/text-editor";
import { FinishButton } from "components/shared-components/prompt-editor/finish-button";
import { KeywordManager } from "components/account-detail-page/tabs/csv-parse/prompt-editor-page/keyword-manager";
import { MetadataEditor } from "components/shared-components/prompt-editor/metadata-editor";
import { ConfigurationSliders } from "components/shared-components/prompt-editor/prompt-configuration-sliders";
import { PromptSelectorTable } from "components/shared-components/prompt-editor/prompt-selector-table";
import { GoBackButton } from "components/shared-components/prompt-editor/back-button";
import { StartNewPromptButton } from "components/shared-components/prompt-editor/start-new-prompt-button";
import { CsvParseResultPreview } from "./csv-parse-result-preview";
import { GptModelSelector } from "components/shared-components/prompt-editor/gpt-model-selector";
import { SystemInfoEditor } from "components/shared-components/prompt-editor/system-info-editor";
import { AccordionCard } from "components/shared-components/accordion-card";

import { getCsvParsePromptsForAccount } from "api/account";
import { createEmptyPrompt, validateTemplate, isPromptReadyToBeUpserted } from "./utilities";
import { setPrompt, clearPrompt, setEditorType, EDITOR_TYPES } from "services/redux/slicers/slicers.prompt-editor";

export const PromptEditorPage = () => {
	const dispatch = useDispatch(); // Redux
	const promptToUpsert = useSelector((state) => state.promptEditor.prompt); // Redux
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected); // Redux

	useEffect(() => {
		// Setting the prompt editor type first!
		dispatch(setEditorType(EDITOR_TYPES.CSV_PARSE));

		// Cleanup function - clear prompt state
		return () => dispatch(clearPrompt());
	}, [dispatch]);

	const goToPromptTableOverview = () => dispatch(clearPrompt());
	const startNewPrompt = () => {
		const prompt = createEmptyPrompt();
		dispatch(setPrompt(prompt));
	};

	if (!promptToUpsert) {
		return (
			<Grid container xs={12} spacing={2}>
				<Grid xs={12}>
					{/* New prompt will be initiated from here */}
					<StartNewPromptButton startNew={startNewPrompt} />
				</Grid>

				<Grid xs={12}>
					{/* Existing prompt is set in redux here */}
					<PromptSelectorTable
						getPrompts={() => getCsvParsePromptsForAccount({ accountId: currentlySelectedAccount.id })}
					/>
				</Grid>
			</Grid>
		);
	} else {
		return (
			<Grid container xs={12} spacing={1}>
				<Grid container xs={12} justifyContent='space-between' flexDirection='row'>
					<Grid>
						<GoBackButton goBack={goToPromptTableOverview} text='Prompts' />
					</Grid>

					<Grid>
						<CsvParseResultPreview />
					</Grid>
				</Grid>

				<Grid xs={8}>
					<TextEditor>
						<GptModelSelector />
						<ConfigurationSliders />
					</TextEditor>

					<AccordionCard>
						<SystemInfoEditor />
					</AccordionCard>
				</Grid>

				<Grid xs={4} container alignContent='start'>
					<Grid xs={12}>
						<MetadataEditor />
					</Grid>

					<Grid xs={12}>
						<KeywordManager />
					</Grid>

					<Grid xs={12}>
						<FinishButton
							minKeywords={3}
							buttonVisibility={isPromptReadyToBeUpserted}
							validateTemplate={validateTemplate}
						/>
					</Grid>
				</Grid>
			</Grid >
		);
	}
};
