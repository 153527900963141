import { PropTypes } from 'prop-types';
import {
	Typography,
	Chip,
	Paper,
	Unstable_Grid2 as Grid,
} from "@mui/material";

export const KeywordOverview = ({ prompt, keywordColumnMap }) => {
	if (!prompt || !prompt.keywords) { return null; }

	const mappedKeywords = Object.keys(keywordColumnMap);

	// Keywords to be mapped to .csv column headers
	const userInputKeyword = [];

	// Some keywords gets filled by the scraper. 
	// We show these, but seperate from the rest
	const scrapperInputKeywords = [];

	prompt.keywords?.forEach(keyword => {
		if (keyword.inputType === 'user') { userInputKeyword.push(keyword); }
		else if (keyword.inputType === 'scraper') { scrapperInputKeywords.push(keyword); }
	})

	return (
		<Paper
			elevation={24}
			sx={{
				p: 2,
				bgcolor: '#2a2e35',
				borderRadius: 2,
				boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px',
			}}
		>
			<Grid container>
				<Typography color="white" variant="h5" sx={{ textAlign: 'center', width: 1, mb: 1 }}>
					Keywords
				</Typography>

				{userInputKeyword.map(keyword => (
					<Chip
						color={mappedKeywords.includes(keyword.key) ? 'success' : 'error'}
						sx={{ mt: 1, mr: 1 }}
						role="user-keyword"
						key={keyword.key}
						label={keyword.name}
					/>
				))}

				<Typography color="white" variant="subtitle1" sx={{ textAlign: 'center', width: 1, mt: 2 }}>
					From Scraper
				</Typography>

				{scrapperInputKeywords.map(keyword => (
					<Chip
						color="info"
						role="scraper-keyword"
						sx={{ mt: 1, mr: 1 }}
						key={keyword.key}
						label={keyword.name}
					/>
				))}
			</Grid >
		</Paper >
	);
};

KeywordOverview.propTypes = {
	prompt: PropTypes.shape({
		text: PropTypes.string.isRequired,
		keywords: PropTypes.arrayOf(PropTypes.object).isRequired,
		id: PropTypes.number.isRequired
	}),
	keywordColumnMap: PropTypes.object.isRequired
};
