import { useSelector } from 'react-redux';
import { ClickToCopyTextAndLabel } from '../../account-detail-page/tabs/klaviyo-page/prompt-editor-page/values-to-copy-viewer-components/click-to-copy-text-and-label';

export const EmailUsageStep = () => {
	const prompt = useSelector((state) => state.promptEditor.prompt); // Redux
	if (!prompt) { return null; }

	return (
		<ClickToCopyTextAndLabel
			label={'Main Body - with fallack value'}
			value={`{{person.main_body_${prompt.id}|default:person.main_body_${prompt.id}_fallback|linebreaksbr}}`}
		/>
	);
};