// import { useCallback, useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { PropTypes } from 'prop-types'
// import { Link, Unstable_Grid2 as Grid, Typography, Box } from "@mui/material";
// import { useNavigate, useParams } from 'react-router-dom';
// import { intersection } from 'lodash';

// export const SubpageNavigation = ({ subpages }) => {
// 	const { tabSubpage } = useParams();
// 	const navigate = useNavigate();
// 	const [filteredSubpages, setFilteredSubpages] = useState([]);
// 	const [activePageId, setActivePageId] = useState(subpages[0].id);
// 	const userAuthPermissions = useSelector((state) => state.auth.xpermissions); // Redux

// 	const subpageSelected = useCallback((pageId) => {
// 		setActivePageId(pageId);
// 		navigate(`../${pageId}`, { relative: 'path' });
// 	}, [navigate]);

// 	// On initial page load
// 	useEffect(() => {
// 		console.log('subpages', { subpages, userAuthPermissions });
// 		const _filteredSubpages = [];
// 		for (const subpage of subpages) {
// 			if (subpage.permissionsAny) {
// 				console.log({
// 					'subpage.permissions': subpage.permissionsAny,
// 					userAuthPermissions
// 				});
// 				// if( (intersection(subpage.permissions, userAuthPermissions).length > 0) {
// 				// 	_filteredSubpages.push(subpage);
// 				// })
// 				for (const perm of subpage.permissionsAny) {
// 					if (userAuthPermissions[perm] === true) {
// 						_filteredSubpages.push(subpage);
// 						// continue;	
// 					}
// 				}
// 			} else {
// 				_filteredSubpages.push(subpage);
// 			}
// 		}
// 		setFilteredSubpages(_filteredSubpages);
// 		console.log('_filteredSubpages', _filteredSubpages);

// 		const subpage = subpages.find(page => page.id === tabSubpage);
// 		if (subpage) { subpageSelected(subpage.id); }
// 		else {
// 			// Since theres no "tabSubpage", we go back up one route instead of two
// 			// otherwise we'd remove the actual tab page we're on's path
// 			setActivePageId(subpages[0].id);
// 			navigate(`./${subpages[0].id}`, { relative: 'path' });
// 		}
// 	}, [navigate, subpageSelected, tabSubpage, userAuthPermissions, subpages]);

// 	return (
// 		<Grid container xs={12}>
// 			<Grid xs={2} marginTop={4}>
// 				{filteredSubpages.map(page => (
// 					<Box key={page.id} sx={{ pb: 2.5 }}>
// 						<Link
// 							href="#"
// 							underline='none'
// 							onClick={() => subpageSelected(page.id)}
// 							color={page.id === activePageId ? 'primary.main' : 'neutral.400'}
// 						>
// 							<Typography textAlign='center'>
// 								{page.label}
// 							</Typography>
// 						</Link>
// 					</Box>
// 				))}
// 			</Grid>

// 			<Grid xs={10}>
// 				{subpages.find(page => page.id === activePageId).component}
// 			</Grid>
// 		</Grid >
// 	);
// };

// SubpageNavigation.propTypes = {
// 	subpages: PropTypes.arrayOf(
// 		PropTypes.shape({
// 			label: PropTypes.string.isRequired,
// 			id: PropTypes.string.isRequired,
// 			component: PropTypes.element.isRequired
// 		}).isRequired
// 	).isRequired
// };
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types'
import { Link, Unstable_Grid2 as Grid, Typography, Box } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom';

export const SubpageNavigation = ({ subpages }) => {
	const { tabSubpage } = useParams();
	const navigate = useNavigate();
	const [filteredSubpages, setFilteredSubpages] = useState([]);
	const [activePageId, setActivePageId] = useState(subpages[0].id);
	// const userAuthPermissions = useSelector((state) => state.auth.xpermissions); // Redux
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);

	const subpageSelected = useCallback((pageId) => {
		setActivePageId(pageId);
		navigate(`../${pageId}`, { relative: 'path' });
		// navigate(pageId);
	}, [navigate]);

	// On initial page load
	useEffect(() => {
		// console.log('subpages', { subpages, currentlySelectedAccount });
		const _filteredSubpages = [];
		for (const subpage of subpages) {
			if (subpage.permissionsAny) {
				// console.log({
				// 	'subpage.permissions': subpage.permissionsAny,
				// 	userAuthPermissions
				// });

				for (const perm of subpage.permissionsAny) {
					if (currentlySelectedAccount?.permissions[perm] === true) {
						// console.log('adding subpage', { subpage, perm});
						_filteredSubpages.push(subpage);
						break
					}
				}
			} else {
				_filteredSubpages.push(subpage);
			}
		}
		setFilteredSubpages(_filteredSubpages);
		// console.log('_filteredSubpages', _filteredSubpages);ss

		const subpage = _filteredSubpages.find(page => page.id === tabSubpage);
		// console.log('subpage', subpage);
		if (subpage) { subpageSelected(subpage.id); }
		else {
			// Since theres no "tabSubpage", we go back up one route instead of two
			// otherwise we'd remove the actual tab page we're on's path
			setActivePageId(_filteredSubpages[0].id);
			// navigate(`./${_filteredSubpages[0].id}`, { relative: 'path' });
			navigate(`../${_filteredSubpages[0].id}`, { relative: 'path' });
		}
		// console.log('filtered subpages', _filteredSubpages);
	}, [navigate, subpageSelected, tabSubpage, currentlySelectedAccount, subpages]);

	return (
		<Grid container xs={12}>
			<Grid xs={2} marginTop={4}>
				{filteredSubpages.map(page => (
					<Box key={page.id} sx={{ pb: 2.5 }}>
						<Link
							href="#"
							underline='none'
							onClick={() => subpageSelected(page.id)}
							color={page.id === activePageId ? 'primary.main' : 'neutral.400'}
						>
							<Typography textAlign='center'>
								{page.label}
							</Typography>
						</Link>
					</Box>
				))}
			</Grid>

			<Grid xs={10}>
				{filteredSubpages.find(page => page.id === activePageId)?.component}
			</Grid>
		</Grid >
	);
};

SubpageNavigation.propTypes = {
	subpages: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			component: PropTypes.element.isRequired
		}).isRequired
	).isRequired
};
