import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Modal, Paper, Button, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { PromptPreview } from "components/shared-components/prompt-editor/prompt-result-preview/prompt-preview";
import { PromptAnswerPreview } from "components/shared-components/prompt-editor/prompt-result-preview/prompt-answer-preview";
import { PromptNavigator } from "components/shared-components/prompt-editor/prompt-result-preview/prompt-preview-navigator";
import { PreviewPayload } from "./preview-payload";

import { clearPromptPreview } from "services/redux/slicers/slicers.prompt-editor";

const MODAL_BOX_STYLE = {
	position: 'absolute',
	left: '50%',
	transform: 'translate(-50%, 0%)',
	minWidth: '40vw',
	maxWidth: '90vw',
};

const PREVIEW_STAGES = [
	{
		order: 0,
		id: 'VIEW_TEMPLATE',
		label: 'View Template',
		icon: <ArrowForwardIosIcon />,
		component: <PreviewPayload />
	},
	{
		order: 1,
		id: 'GPT_RESPONSE_PREVIEW',
		label: 'AI Response',
		icon: <ArrowForwardIosIcon />,
		component: <PromptPreview />
	},
	{
		order: 2,
		id: 'DONE',
		label: 'Done',
		component: <PromptAnswerPreview />
	}
];

export const CsvParseResultPreview = () => {
	const dispatch = useDispatch();
	const [activeStage, setActiveStage] = useState(PREVIEW_STAGES.find(stg => stg.order === 0)); // State
	const [open, setOpen] = useState(false); // State
	const preview = useSelector((state) => state.promptEditor.preview); // Redux
	const prompt = useSelector((state) => state.promptEditor.prompt); // Redux

	const handleModalOpen = () => setOpen(true);
	const handleModalClose = () => {
		if (
			(preview && preview.generatingPromptResult && confirm('Seems like you are waiting for at GPT preview respose, sure you want to quit?') === true)
			|| (preview && preview.generatingPromptResult === false)
		) {
			dispatch(clearPromptPreview());
			setActiveStage(PREVIEW_STAGES.find(stage => stage.order === 0));
			setOpen(false);
		}
	};

	// Only show the preview button if the prompt has been inserted (exists in database) and it doensn't have any uncommited changes
	if (!prompt.id || !preview) { return null; }
	else return (
		<Box>
			<Button
				variant="contained"
				endIcon={<RemoveRedEyeIcon />}
				onClick={handleModalOpen}
			>
				Preview
			</Button>

			<Modal open={open} onClose={handleModalClose}>
				<Box sx={MODAL_BOX_STYLE}>
					<IconButton
						size='small'
						aria-label='delete'
						sx={{ color: 'white' }}
						onClick={handleModalClose}
					>
						<CloseIcon />
					</IconButton>

					<Paper sx={{ p: 1, maxHeight: 'calc(90vh - 3rem)', overflow: 'auto' }}>
						<PromptNavigator
							stage={activeStage}
							stages={PREVIEW_STAGES}
							stageChanged={setActiveStage}
							closeModal={handleModalClose}
						/>

						<Box sx={{ p: 1 }}>
							{activeStage.component}
						</Box>
					</Paper>
				</Box>
			</Modal>
		</Box>
	);
};
