import React, { useCallback, useEffect, useState } from 'react';
import { Skeleton, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { Tier } from './tier';
import TIERS from './pricing.json'
import { getStripeSubscription } from 'api/account';
import { useSelector } from 'react-redux';
import { getStripeProducts } from 'api/stripe';
import { camelCase } from 'lodash';

export const TiersRow = () => {
	const [activeTier, setActiveTier] = useState(null);
	const [isLoadingTiers, setIsLoadingTiers] = useState(false);
	const [isLoadingActiveSubscription, setIsLoadingActiveSubscription] = useState(false);
	const [activeStripeSubscription, setActiveStripeSubscription] = useState(null);
	const [tiers, setTiers] = useState([]);

	const user = useSelector((state) => state.auth.user);
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);

	const findAndSetActiveTier = useCallback((_tiers, subscription) => {
		if (!subscription) { return; }
		// console.log('finding and setting active sub', { subscription, _tiers });
		const tier = _tiers.find(t => t.id === subscription.xano_internal_product_id);
		if (!tier) { return; }
		tier.actualUsage = subscription.monthly_usage;
		tier.usage = subscription.usage_limit;
		tier.currency = subscription.currency;
		tier.activeStatus = subscription.status || null;
		// console.log('active tier', tier);
		setActiveTier(tier);
	}, []);

	useEffect(() => {
		const internalProductIds = TIERS.map(tier => tier.id);
		if (Array.isArray(tiers) && tiers.length === 0) {
			// Get the products
			setIsLoadingTiers(true);
			getStripeProducts(internalProductIds)
				.then(resp => {
					// Add the products' features and prices to the tiers
					const products = resp.data;
					for (let i = 0; i < products.length; i++) {
						const product = products[i];
						for (let j = 0; j < TIERS.length; j++) {
							const tier = TIERS[j];
							if (product.xano_internal_product_id === tier.id) {
								tier.features = product.features;
								tier.priceTiers = product.default_price?.tiers?.map(obj => convertObjectKeysToCamelCase(obj));
								tier.stripeProduct = product;
							}
						}
					}

					// console.log('setting tiers', { TIERS, activeStripeSubscription });
					setTiers(TIERS);

					// console.log('products loaded', (activeStripeSubscription));
					if (activeStripeSubscription) {
						// Reset the active tier if the active sub was retrieved before the products
						findAndSetActiveTier(TIERS, activeStripeSubscription);
					}
				})
				.catch(err => { console.error('getStripeProducts', err); })
				.finally(() => setIsLoadingTiers(false));
		}

		if (activeStripeSubscription === null) {
			// Get the customer's active subscription
			if (user && currentlySelectedAccount) {
				setIsLoadingActiveSubscription(true)
				getStripeSubscription(currentlySelectedAccount.id)
					.then(resp => {
						// console.log('active subscription', { activeSub: resp.data, tiers });
						setActiveStripeSubscription(resp.data);
						findAndSetActiveTier(tiers, resp.data);
					})
					.catch(err => { console.error('getStripeSubscription', err); })
					.finally(() => { setTimeout(() => { setIsLoadingActiveSubscription(false); }, 4000) });
			}
		} else {
			setActiveStripeSubscription(activeStripeSubscription);
		}
	}, [tiers, activeStripeSubscription, findAndSetActiveTier, currentlySelectedAccount, user]);

	function convertObjectKeysToCamelCase(obj) {
		// Return a new object with keys mapped to camelCase
		return Object.entries(obj).reduce((acc, [key, value]) => {
			const camelCaseKey = camelCase(key);
			acc[camelCaseKey] = value;
			return acc;
		}, {});
	}

	return (
		<Grid
			container
			xs={12}
			md={12}
			columnSpacing={2}
			rowSpacing={2}
			ml={0}
			maxWidth={'1280px'}
			flexDirection={'row'}
			justifyContent={'center'}
			justifySelf={'center'}
		>

			{currentlySelectedAccount && (
				<Grid xs={12}>
					<Typography>
						Monthly Usage: <b>{activeTier?.actualUsage?.toLocaleString(undefined)}</b>
					</Typography>
				</Grid>
			)}

			{isLoadingTiers && (
				[1, 2, 3, 4, 5].map(i => (
					<Grid xs={12} sm={6} md={4} lg={2.4} key={i}>
						<Skeleton variant="rounded" height={580} />
					</Grid>
				))
			) ||
				tiers.map(tier => (
					<Grid xs={12} sm={6} md={4} lg={2.4} key={tier.id} >
						<Tier
							tier={tier}
							activeTier={activeTier}
							isLoadingActiveSubscription={isLoadingActiveSubscription}
						/>
					</Grid>
				))}
		</Grid>);
};
