// React
import React from "react";
import ReactDOM from "react-dom/client";

// Pages
import { App } from "App";
import { PageLoader } from "components/page-loader";

// Routing
import { BrowserRouter } from "react-router-dom";

// Style
import "assets/styles/styles.css";
import { createTheme } from "assets/styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";

// Redux
import { Provider } from "react-redux";
import { persistor, store } from "services/redux/stores/index";
import { PersistGate } from "redux-persist/integration/react";

const theme = createTheme();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Provider store={store}>
				<PersistGate loading={<PageLoader />} persistor={persistor}>
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={theme}>
							<App />
						</ThemeProvider>
					</StyledEngineProvider>
				</PersistGate>
			</Provider>
		</BrowserRouter>
	</React.StrictMode>
);
