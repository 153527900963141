import { useDispatch, useSelector } from 'react-redux';
import { Box, Unstable_Grid2 as Grid } from "@mui/material";
import { Slider, Typography, Link } from "@mui/material";
import { get } from "lodash";
import HelpIcon from '@mui/icons-material/Help';

import { updatePrompt } from "services/redux/slicers/slicers.prompt-editor";

const SLIDERS = [
	{
		id: 'gpt_config.temperature',
		label: 'Temperature',
		default: 1,
		min: 0,
		max: 2,
		step: 0.1,
		link: 'https://platform.openai.com/docs/api-reference/chat/create#temperature'
	},
	{
		id: 'gpt_config.top_p',
		label: 'Top P',
		default: 1,
		min: 0,
		max: 1,
		step: 0.1,
		link: 'https://platform.openai.com/docs/api-reference/chat/create#top_p'
	},
	{
		id: 'gpt_config.presence_penalty',
		label: 'Presence Penalty',
		default: 0,
		min: -2,
		max: 2,
		step: 0.1,
		link: 'https://platform.openai.com/docs/api-reference/chat/create#presence_penalty'
	},
	{
		id: 'gpt_config.frequency_penalty',
		label: 'Frequency Penalty',
		default: 0,
		min: -2,
		max: 2,
		step: 0.1,
		link: 'https://platform.openai.com/docs/api-reference/chat/create#frequency_penalty'
	}
];

export const ConfigurationSliders = () => {
	const dispatch = useDispatch(); // Redux
	const prompt = useSelector((state) => state.promptEditor.prompt); // Redux

	const sliderValueChange = (e, sliderId) => {
		dispatch(updatePrompt({ key: sliderId, value: e.target.value }));
	};

	return (
		<>
			<Grid container spacing={2}>
				{SLIDERS.map(slider =>
					<Grid xs={6} direction='row' key={slider.id}>
						<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
							<Typography
								color="white"
								gutterBottom

								aria-owns={open ? 'mouse-over-popover' : undefined}
								aria-haspopup="true"
							>
								{slider.label}: {get(prompt, slider.id, slider.default)}
							</Typography>

							<Link href={slider.link} target="_blank">
								<HelpIcon fontSize="inherit" />
							</Link>
						</Box>

						<Slider
							defaultValue={slider.default}
							value={get(prompt, slider.id, slider.default)}
							onChange={e => sliderValueChange(e, slider.id)}
							min={slider.min}
							step={slider.step}
							max={slider.max}
							valueLabelDisplay="auto"
						/>
					</Grid>
				)}
			</Grid>
		</>
	);
};
