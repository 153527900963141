import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Tab, Tabs } from "@mui/material";

import { SettingsTeamPage } from "components/settings-page/team/settings-team-page";
import { SettingsBillingPage } from "components/settings-page/billing/settings-billing-page";
import { SettingsOrganizationPage } from "components/settings-page/organization/settings-organization-page";
import { SettingsProfilePage } from "components/settings-page/profile/settings-profile-page";
import { PERMISSIONS } from "config/permissions";
import { setCurrentlySelectedAccount } from "services/redux/slicers/slicers.accounts";

const TABS = [
	{
		label: 'Profile',
		id: 'profile',
		requiredFeaturesAny: [],
		mainPage: <SettingsProfilePage />
	},
	{
		label: 'Organization',
		id: 'organization',
		requiredFeaturesAny: [PERMISSIONS.manageAccount],
		mainPage: <SettingsOrganizationPage />
	},
	{
		label: 'Team',
		id: 'team',
		requiredFeaturesAny: [PERMISSIONS.manageAccount],
		mainPage: <SettingsTeamPage />
	},
	{
		label: 'Billing',
		id: 'billing',
		requiredFeaturesAny: [PERMISSIONS.manageAccount],
		mainPage: <SettingsBillingPage />
	}
];

export const SettingsPage = () => {
	const { tab } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);
	const userAccount = useSelector((state) => state.auth.userAccount);

	const [activeTabId, setActiveTabId] = useState(tab ? tab : TABS[0].id);
	const [filteredSubpages, setFilteredSubpages] = useState([]);

	useEffect(() => {
		// Set user's account as currently selected
		dispatch(setCurrentlySelectedAccount(userAccount));
	}, [userAccount, dispatch])

	const handleTabChange = (event, tabId) => {
		navigate(`/settings/${tabId}`);
		setActiveTabId(tabId);
	};

	useEffect(() => {
		const _filteredTabs = [];
		for (const tab of TABS) {
			if (tab.requiredFeaturesAny?.length) {
				for (const perm of tab.requiredFeaturesAny) {
					if (currentlySelectedAccount?.permissions[perm] === true) {
						_filteredTabs.push(tab); break;
					}
				}
			} else {
				_filteredTabs.push(tab);
			}
		}
		setFilteredSubpages(_filteredTabs);

		const tab = _filteredTabs.find(page => page.id === activeTabId);
		if (tab) { handleTabChange(null, tab.id); }
		else { handleTabChange(null, _filteredTabs[0].id); }
	}, [currentlySelectedAccount]);

	return (
		<Box>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={activeTabId} onChange={handleTabChange} scrollButtons="auto" variant="scrollable">
					{filteredSubpages.map(tab => (
						<Tab
							label={tab.label} value={tab.id} key={tab.id}
							sx={{ p: 2, borderRadius: 1 }}
						/>
					))}
				</Tabs>
			</Box>

			{/* Add the views for the Tabs */}
			{filteredSubpages
				.filter(tab => tab.id === activeTabId)
				.map(tab => (<Box sx={{ pl: 1, pt: 1 }} key={tab.id}>{tab.mainPage}</Box>))}
		</Box>
	);
};
