import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Box, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import './tier.css'
import { UsageSelector } from './usage-selector';
import { TierStartBtn } from './tier-start-btn';
import { TierPricing } from './tier-pricing';
import { TierIsActiveSign } from './tier-is-active-sign';

export const Tier = ({ tier, activeTier, isLoadingActiveSubscription }) => {
	const [selectedUsage, setSelectedUsage] = useState(tier.priceTiers && tier.priceTiers[0] || null);
	const [disabled, setDisabled] = useState(false);
	const [wrapperClasses, setWrapperClasses] = useState(['tier-wrapper']);

	useEffect(() => {
		let disabled = false;
		if (activeTier) {
			// disable the tier card if the tier's level is lower or is the free tier (since we cant upgrade its quantity)
			disabled = (activeTier.level > tier.level) || (activeTier.level === 0 && tier.level === activeTier.level);
			setDisabled(disabled);
		}

		let wrapperClasses = ['tier-wrapper', disabled && 'disabled'];
		if (tier.extraCardCssClasses?.length > 0 && Array.isArray(tier.extraCardCssClasses)) {
			wrapperClasses = wrapperClasses.concat(tier.extraCardCssClasses);
		}

		setWrapperClasses(wrapperClasses);
	}, [tier.extraCardCssClasses, tier, activeTier]);

	return (
		<div className={wrapperClasses.join(' ')} >
			<TierIsActiveSign  activeTier={activeTier} tier={tier} />

			<Box height='100px' paddingTop={'0.75rem'}>
				<Typography variant='h5'>
					{tier.header}
				</Typography>

				<Typography variant='body2'>
					{tier.subHeader}
				</Typography>
			</Box>

			<Box sx={{ height: '275px' }}>
				<Typography variant='body2'>
					<b>{tier.featureHeader}</b>
				</Typography>

				{tier.features.map(feature => (
					<Grid key={feature.name} display={'flex'} p={0} mt={1}>
						<CheckIcon color='success' />
						<Typography variant='body2' pl={1}>{feature.name}</Typography>
					</Grid>
				))}
			</Box>

			<TierPricing
				tier={tier}
				activeTier={activeTier}
				selectedUsage={selectedUsage}
				isLoadingActiveSubscription={isLoadingActiveSubscription}
			/>

			<UsageSelector
				tier={tier}
				disabled={disabled}
				activeTier={activeTier}
				selectedUsage={selectedUsage}
				onUsageChanged={setSelectedUsage}
				isLoadingActiveSubscription={isLoadingActiveSubscription}
			/>

			<TierStartBtn
				tier={tier}
				disabled={disabled}
				activeTier={activeTier}
				selectedUsage={selectedUsage}
				isLoadingActiveSubscription={isLoadingActiveSubscription}
			/>
		</div>
	);
};

Tier.propTypes = {
	isLoadingActiveSubscription: PropTypes.bool,
	tier: PropTypes.shape({
		id: PropTypes.string.isRequired,
		header: PropTypes.string.isRequired,
		subHeader: PropTypes.string.isRequired,
		featureHeader: PropTypes.string.isRequired,
		features: PropTypes.array.isRequired,
		priceTiers: PropTypes.array,
		level: PropTypes.number,
		extraCardCssClasses: PropTypes.array,
	}).isRequired,
	activeTier: PropTypes.shape({
		id: PropTypes.string.isRequired,
		header: PropTypes.string.isRequired,
		subHeader: PropTypes.string.isRequired,
		featureHeader: PropTypes.string.isRequired,
		features: PropTypes.array.isRequired,
		usageLevels: PropTypes.array,
		level: PropTypes.number,
		extraCardCssClasses: PropTypes.array,
	}),
};
