import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Skeleton, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import './tier.css'

import { SummaryPricingModal } from './summary-pricing-modal';
import { getUpgradeWaitingRoomUrl } from 'pages/account-upgrade-waiting-room';
import { getCustomerCheckoutSession, getCustomerInvoicePreview, updateStripeSubscription } from 'api/account';
import { setOpenInfo } from 'services/redux/slicers/slicers.snackbar';
import { messageFromSuspectedXanoError } from 'api/axios-api';

// const options = ['Upgrade', 'Get Link'];

export const TierStartBtn = ({ tier, selectedUsage, disabled, activeTier, isLoadingActiveSubscription }) => {
	const modalRef = useRef();
	const dispatch = useDispatch();

	const [open, setOpen] = useState(false);
	const anchorRef = useRef(null);
	const [selectedIndex, setSelectedIndex] = useState(0);

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [btnLabel, setBtnLabel] = useState(null);
	const user = useSelector((state) => state.auth.user);
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);

	useEffect(() => {
		const determineLabel = () => {
			if (!activeTier) { return tier.btnLabel; }

			if (activeTier && tier.upgradable) {
				if (tier.id === activeTier.id) {
					return selectedUsage.upTo > activeTier.usage ? 'Upgrade' : 'Downgrade';
				}
				return 'Upgrade';
			}

			return tier.btnLabel;
		};

		const label = determineLabel();
		setBtnLabel(label);
	}, [loading, activeTier, tier, selectedUsage]);

	const clicked = () => {
		if (tier.btnClickGoToPage) {
			// go to enterprise contact form
			window.open(tier.btnClickGoToPage, "_blank");
			return;
		} else if ((!activeTier || activeTier?.activeStatus === 'trialing') && selectedIndex === 0) {
			// console.l(og('tier', tier);
			// return)
			// go to the checkout for the product and the selected usage
			setLoading(true);
			const {
				REACT_APP_XANO_BRANCH,
				REACT_APP_AUTH0_REDIRECT_URI,
				REACT_APP_XANO_LEADDRIVE_AUTH_URL
			} = process.env;

			// When the user has been authed, they are redirected to a callback page where these params
			// are used to redirect the user to the corerct checkout page + sucess landing page
			const url = new URL(REACT_APP_AUTH0_REDIRECT_URI);
			url.searchParams.set('type', 'pricing_table');
			url.searchParams.set('price_id', tier.stripeProduct.default_price.id);
			url.searchParams.set('stripe_product_id', tier.stripeProduct.id);
			url.searchParams.set('quantity', selectedUsage.upTo);
			url.searchParams.set('account_id', currentlySelectedAccount?.id);

			axios.get(REACT_APP_XANO_LEADDRIVE_AUTH_URL + '/oauth/auth0/init',
				{
					headers: { 'X-Branch': REACT_APP_XANO_BRANCH, },
					params: { redirect_uri: url.toString() }
				}
			)
				.then(resp => { window.location.href = resp.data.authUrl; })
				.catch(err => {
					// Log and set error
					console.error('Error getting auth url for auth', err);
					setError(err.response);
				})
				.finally(() => setTimeout(() => { setLoading(false) }, 500));
		} else if (selectedIndex > 0) {
			// Get payment link directly to send to customer
			const params = {
				quantity: selectedUsage.upTo,
				price_id: tier.stripeProduct.default_price.id,
				success_url: window.location.origin + '/payment-link-success', 
				account_id: currentlySelectedAccount.id
			};
			setLoading(true);
			getCustomerCheckoutSession(currentlySelectedAccount.id, params)
				.then(sessionResp => {
					console.log('sessionResp', sessionResp);
					navigator.clipboard.writeText(sessionResp.data.customer_checkout_session.url);
					dispatch(setOpenInfo({
						title: 'Copied Checkout Link',
						message: sessionResp.data.customer_checkout_session.url
					}));
				})
				.catch(err => {
					console.log(err)
					setError(messageFromSuspectedXanoError(err));
				})
				.finally(() => setLoading(false))

		} else {
			setLoading(true);
			// It's an upgrade
			const stripeProductDefaultPriceId = tier?.stripeProduct?.default_price.id;
			getCustomerInvoicePreview(currentlySelectedAccount.id, selectedUsage.upTo, stripeProductDefaultPriceId)
				.then(resp => {
					// open modal with summary of the upgrade/downgrade
					setError(null);
					modalRef.current.openSummaryModal(tier, resp.data.invoice_preview);
				})
				.catch(err => {
					console.error('error previewing invoice', err);
					setError(err?.response?.data?.message);
				})
				.finally(() => setLoading(false))
		}
	};

	const updateSubscription = () => {
		const stripeProductDefaultPriceId = tier?.stripeProduct?.default_price.id;
		modalRef.current.setLoading(true);

		// Update the subscription. On success, redirect to waiting page
		const upgradeUrl = getUpgradeWaitingRoomUrl(selectedUsage.upTo, tier.stripeProduct.id, currentlySelectedAccount.id);
		updateStripeSubscription(currentlySelectedAccount.id, selectedUsage.upTo, stripeProductDefaultPriceId, tier.stripeProduct.id)
			.then(() => window.location.href = upgradeUrl)
			.catch(err => {
				console.error('updateStripeSubscription error', err);
				modalRef.current.setError(err?.response?.data?.message || err.message);
			}).finally(() => modalRef.current.setLoading(false));
	};

	const handleMenuItemClick = (event, index) => {
		setSelectedIndex(index);
		setOpen(false);
	};

	const handleToggle = () => setOpen((prevOpen) => !prevOpen);
	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) { return; }
		setOpen(false);
	};


	if (isLoadingActiveSubscription === true) {
		return (
			<Box sx={{ mt: 0.5, height: '3rem' }}>
				<Skeleton sx={{ maxWidth: 'none', maxHeight: 'none', scale: '1 1.6', height: 'inherit' }} />
			</Box>
		)
	}

	return (
		<Box>
			{(!activeTier || tier.btnClickGoToPage || currentlySelectedAccount?.type !== 'agency_managed') && (
				<LoadingButton
					fullWidth
					sx={{ py: 1.5 }}
					onClick={clicked}
					disabled={loading || disabled}
					variant="contained"
					loading={loading}
					className={tier?.extraBtnCssClass}
				>{btnLabel}
				</LoadingButton>
			) || (<>
				<ButtonGroup
					variant="contained"
					disabled={disabled}
					ref={anchorRef}
					fullWidth
					aria-label="Payment Link Options buttons"
				>
					<LoadingButton
						fullWidth
						loading={loading}
						sx={{ py: 1.5 }}
						variant="contained"
						onClick={clicked}
					>{[[btnLabel, 'Get Link'][selectedIndex]]}</LoadingButton>

					<Button
						size="small"
						aria-controls={open ? 'split-button-menu' : undefined}
						aria-expanded={open ? 'true' : undefined}
						aria-label="select payment link type"
						aria-haspopup="menu"
						sx={{ width: '' }}
						onClick={handleToggle}
					>
						<ArrowDropDownIcon />
					</Button>
				</ButtonGroup>
				<Popper
					sx={{ zIndex: 1 }}
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					disablePortal
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
						>
							<Paper>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList id="split-button-menu" autoFocusItem>
										{[btnLabel, 'Get Link'].map((option, index) => (
											<MenuItem
												key={option}
												selected={index === selectedIndex}
												onClick={(event) => handleMenuItemClick(event, index)}
											>{option}</MenuItem>
										))}
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</>)}

			{error && (<Typography color={'error'} sx={{ mt: 1 }}>Error: {error}</Typography>)}

			{activeTier && (
				<SummaryPricingModal
					user={user}
					ref={modalRef}
					activeTier={activeTier}
					selectedUsage={selectedUsage}
					onUpdateSubscription={updateSubscription}
				/>
			)}
		</Box>
	);

	// return (
	// 	<Box>
	// 		<LoadingButton
	// 			fullWidth
	// 			sx={{ py: 1.5 }}
	// 			onClick={clicked}
	// 			disabled={loading || disabled}
	// 			variant="contained"
	// 			loading={loading}
	// 			className={tier?.extraBtnCssClass}
	// 		>{btnLabel}
	// 		</LoadingButton>


	// 		{error && (
	// 			<Typography color={'error'} sx={{ mt: 1 }}>
	// 				Error: {error}
	// 			</Typography>
	// 		)}

	// 		{activeTier && (
	// 			<SummaryPricingModal
	// 				user={user}
	// 				ref={modalRef}
	// 				activeTier={activeTier}
	// 				selectedUsage={selectedUsage}
	// 				onUpdateSubscription={updateSubscription}
	// 			/>
	// 		)}
	// 	</Box>
	// );
};

TierStartBtn.propTypes = {
	selectedUsage: PropTypes.object,
	disabled: PropTypes.bool,
	tier: PropTypes.shape({
		id: PropTypes.string,
		btnClickGoToPage: PropTypes.string,
		btnLabel: PropTypes.string.isRequired,
		extraBtnCssClass: PropTypes.string,
		upgradable: PropTypes.bool,
		stripeProductId: PropTypes.string,
		stripeProduct: PropTypes.shape({
			id: PropTypes.string,
			default_price: PropTypes.shape({
				id: PropTypes.string
			})
		})
	}),
	activeTier: PropTypes.shape({
		id: PropTypes.string,
		activeStatus: PropTypes.string,
		usage: PropTypes.number,
		btnClickGoToPage: PropTypes.string,
		btnLabel: PropTypes.string.isRequired,
		extraBtnCssClass: PropTypes.string,
		upgradable: PropTypes.bool
	}),
	isLoadingActiveSubscription: PropTypes.bool,
};
