import { useEffect, useState } from "react";
import { PropTypes } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import { Modal, Button, Box } from "@mui/material";

import { getCsvParsePromptsForAccount } from "api/account";
import { HoverToPreviewPrompt } from "./hover-to-preview-prompt";
import { setOpenError } from "services/redux/slicers/slicers.snackbar";
import { StyledDataGrid } from "assets/styles/components/styled-data-grid";

const MODAL_BOX_STYLE = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	maxWidth: '75%',
	bgcolor: 'background.paper',
	boxShadow: 24,
	borderRadius: '1rem',
	p: 4,
};

const COLUMNS = [
	{ field: 'id', headerName: 'ID', width: 100, headerAlign: 'center', align: 'center' },
	{
		field: 'name',
		headerName: 'Name',
		width: 350,
	},
	{
		field: 'description',
		headerName: 'Description',
		width: 250
	},
	{
		field: 'created_at',
		headerName: 'Created',
		type: 'dateTime',
		width: 200,
		valueGetter: ({ value }) => value && new Date(value),
	},
	{
		field: 'text',
		headerName: 'Text',
		renderCell: HoverToPreviewPrompt
	},
];

export const PromptSelectorModal = ({ setSelectedPrompt }) => {
	const dispatch = useDispatch();
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected); // Redux

	const [prompts, setPrompts] = useState([]); // State
	const [open, setOpen] = useState(false); // State
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	useEffect(() => {
		if (!currentlySelectedAccount) { return }

		getCsvParsePromptsForAccount({ accountId: currentlySelectedAccount.id })
			.then(resp => { setPrompts(resp.data) })
			.catch(error => {
				dispatch(setOpenError({ title: 'Error Getting Prompts', message: error.message }));
				console.log('prompt err', error);
			});
	}, [currentlySelectedAccount, setPrompts, dispatch]);

	const rowClick = (e) => {
		setSelectedPrompt(e.row);
		handleClose();
	};

	return (<>
		<Button
			sx={{ mr: 2 }}
			variant="contained"
			onClick={handleOpen}
			endIcon={<AutoAwesomeMotionIcon />}
		>
			Select Prompt
		</Button>


		<Modal open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={MODAL_BOX_STYLE}>
				<StyledDataGrid
					onRowClick={rowClick} // TODO: fix cell highlight on row click
					rows={prompts}
					columns={COLUMNS}
					initialState={{
						sorting: { sortModel: [{ field: 'created_at', sort: 'desc' }] },
						pagination: { paginationModel: { pageSize: 10 } }
					}}
					pageSizeOptions={[10]}
					disableRowSelectionOnClick
				/>
			</Box>
		</Modal>
	</>
	);
};

PromptSelectorModal.propTypes = {
	setSelectedPrompt: PropTypes.func.isRequired
};

