import { axiosInstance, rejectIfNotExpiredToken } from 'api/axios-api';

/*
	Get the prompt results for a given account for the type "manual_csv_parse"
*/
export const getCsvParsePromptResultsForAccount = ({ accountId }) =>
	new Promise((resolve, reject) => {
		if (!accountId) { reject("Missing account Id"); }

		const path = `/accounts/${accountId}/prompts/manual_csv_parse/ai_prompt_results`;
		axiosInstance.get(path)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});

/**
 * Get the ai_prompts for a given Account
 * 
 * @param {Object} param
 * @param {number} param.accountId - the account id to get prompts for
 * @returns {Promise<Array[Object]>} 
 */
export const getCsvParsePromptsForAccount = ({ accountId }) =>
	new Promise((resolve, reject) => {
		const path = `/accounts/${accountId}/prompts/manual_csv_parse`;

		axiosInstance.get(path)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});

/*
	Deactivate Account
*/
// export const deactivateAccount = ({ accountId }) =>
// 	new Promise((resolve, reject) => {
// 		if (!accountId) { reject("Missing account Id"); }

// 		const path = `/accounts/${accountId}/deactivate`;
// 		axiosInstance.post(path, null)
// 			.then(resp => resolve(resp))
// 			.catch(error => rejectIfNotExpiredToken(error, reject));
// 	});

/*
	Create Account
*/
export const createAccount = (account) =>
	new Promise((resolve, reject) => {
		const path = "/accounts/create";

		axiosInstance.post(path, account)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});

/*
	Get all Klaviyo Accounts for an Account
*/
export const getKlaviyoAccountsForAccount = ({ accountId }) =>
	new Promise((resolve, reject) => {
		const path = `/accounts/${accountId}/klaviyo_accounts/all`;

		// TODO: yup validate all api inputs
		axiosInstance.get(path)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});

/*
	Get the managed Accounts for an agency
*/
export const getManagedAccountsForAccount = (accountId) =>
	new Promise((resolve, reject) => {
		const path = `/accounts/${accountId}/managed_accounts`;

		// TODO: yup validate all api inputs
		axiosInstance.get(path)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});

/*
	Get the usage limits for an account
*/
export const getUsageLimits = (accountId) =>
	new Promise((resolve, reject) => {
		const path = `/accounts/${accountId}/usage_limits`;

		// TODO: yup validate all api inputs
		axiosInstance.get(path)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});


/*
	Gets all the validated users and pending user invites for an account
*/
export const getAccountUsers = (accountId) =>
	new Promise((resolve, reject) => {
		const path = `/accounts/${accountId}/users/all`;

		axiosInstance.get(path)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});

/*
	Invite an email to be a user of the account
*/
export const sendUserInvite = (accountId, receiverEmail, inviteRole, authRedirectUri) =>
	new Promise((resolve, reject) => {
		const path = `/accounts/${accountId}/users/invite`;

		axiosInstance.post(path, {
			receiver_email: receiverEmail,
			invite_role: inviteRole,
			auth_user_redirect_uri: authRedirectUri
		})
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});

/*
	Cancel a user invite
*/
export const cancelUserInvite = (accountId, inviteId) =>
	new Promise((resolve, reject) => {
		const path = `/accounts/${accountId}/users/invites/${inviteId}/cancel`;

		axiosInstance.post(path)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});

/*
	Get an account's stripe customer portal session url
*/
export const getCustomerPortalSession = (accountId) =>
	new Promise((resolve, reject) => {
		const path = `/accounts/${accountId}/admin/stripe/customer_portal_session`;

		axiosInstance.get(path)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});

/*
	Get an account's stripe customer checkout session
*/
export const getCustomerCheckoutSession = (accountId, params) =>
	new Promise((resolve, reject) => {
		const path = `/accounts/${accountId}/admin/stripe/customer_checkout_session`;

		axiosInstance.get(path, { params })
			.then(resp => resolve(resp))
			// .catch(error => rejectIfNotExpiredToken(error, reject));
			.catch(error => reject(error));
	});

/*
	Get an account's stripe customer checkout session
*/
export const getCustomerInvoicePreview = (accountId, quantity, priceId) =>
	new Promise((resolve, reject) => {
		const path = `/accounts/${accountId}/admin/stripe/preview_invoice`;
		console.log(accountId, quantity, priceId);
		axiosInstance.post(path, {
			quantity: quantity,
			price_id: priceId
		})
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});

/*
	Attempts to update the account's stripe subscription 
*/
export const updateStripeSubscription = (accountId, quantity, priceId, productId) =>
	new Promise((resolve, reject) => {
		const path = `/accounts/${accountId}/admin/stripe/update_subscription`;

		axiosInstance.post(path, {
			quantity: quantity,
			target_stripe_product_price_id: priceId,
			target_stripe_product_id: productId
		})
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});

/*
	Gets the account's subscription
*/
export const getStripeSubscription = (accountId) =>
	new Promise((resolve, reject) => {
		const path = `/accounts/${accountId}/admin/stripe/subscription`;
		axiosInstance.get(path)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});

/*
	Get an account's details
*/
export const getAccountDetails = (accountId) =>
	new Promise((resolve, reject) => {
		const path = `/accounts/${accountId}/details`;

		axiosInstance.get(path)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});

/*
	Updates an account's details
*/
export const putAccountDetails = (accountId, data) =>
	new Promise((resolve, reject) => {
		const path = `/accounts/${accountId}/details`;

		axiosInstance.put(path, data)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});



/**
 * Tries to convert the account into an agency
 * @param {integer} accountId 
 * @param {object} data 
 * @returns 
 */
export const convertAccountToAgency = (accountId) =>
	new Promise((resolve, reject) => {
		const path = `/accounts/${accountId}/convert_to_agency`;

		axiosInstance.post(path)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});

/**
* Gets all the products from the store's bucket
* @param {integer} accountId  
* @returns {Object[]} products
*/
export const getAllStoreProducts = (accountId) =>
	new Promise((resolve, reject) => {
		const path = `/accounts/${accountId}/store/get_all_products`;
		axiosInstance.get(path)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});

/**
* Update an account's blacklist
* @param {Number} accountId  
* @param {String[]} accountId  
* @returns {Object} updated account to set in redux
*/
export const updateAccountBlacklist = (accountId, blacklist) =>
	new Promise((resolve, reject) => {
		const path = `/accounts/${accountId}/store/blacklist`;
		axiosInstance.post(path, { new_blacklist: blacklist })
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});
