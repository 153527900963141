import { axiosInstance, rejectIfNotExpiredToken } from 'api/axios-api';

/*
	Patch the prompt and keywords for a prompt
*/
export const patchPromptAndKeywords = (prompt) =>
	new Promise((resolve, reject) => {
		const path = `/prompts/${prompt.id}/patch`;
		const params = prompt;

		axiosInstance.patch(path, params)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});

/*
	Create a prompt and keywords for the account
*/
export const createPromptAndKeywords = ({ prompt, accountId }) =>
	new Promise((resolve, reject) => {
		const { text, keywords, name } = prompt;

		if (!text || !keywords || !name) { reject('missing params;'); return; }

		const path = '/prompts/create';
		const params = { ...prompt, account_id: accountId };

		axiosInstance.post(path, params)
			.then(resp => resolve(resp))
			.catch(error => rejectIfNotExpiredToken(error, reject));
	});
