import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { intersection } from 'lodash';
import {
	Box,
	Divider,
	Drawer,
	Stack,
	useMediaQuery,
	IconButton,
	Chip,
} from '@mui/material';
import { styled } from "@mui/material/styles";

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { Logo } from 'components/side-nav/logo';
import { Scrollbar } from 'components/side-nav/scrollbar';
import { ITEMS } from './config';
import { SideNavItem } from './side-nav-item';
import { AccountSelector } from './account-selector';

const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
	backgroundColor: theme.palette.neutral['800'],
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen
	}),
	overflowX: "hidden"
});

const closedMixin = (theme) => ({
	backgroundColor: theme.palette.neutral['800'],
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(10.5)} + 1px)`
	}
});

const MuiDrawer = styled(Drawer, {
	shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme)
	})
}));

export const SideNav = ({ open, onSetOpenNav }) => {
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);
	const userAccount = useSelector((state) => state.auth.userAccount);
	const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

	const sideNavItemsBox = <Box component='nav' sx={{ px: 2, py: 3 }}>
		<Stack component='ul' spacing={0.5} sx={{ listStyle: 'none', p: 0, m: 0 }}>
			{ITEMS.map((item) => {
				const active = item.path ? (window.location.pathname.startsWith(item.path)) : false;
				// Nav item is visible if there hasn't been specified any required roles
				// or if the currentlySelectedAccount's "permissions" has anyone in common with the "requiredAnyPermissions" array
				const validPermissions = item.requiredAnyPermissions.length === 0 || (item.requiredAnyPermissions.length > 0 &&
					intersection(item.requiredAnyPermissions, Object.keys(currentlySelectedAccount?.permissions || [])).length > 0)
				const validAccountType = item.requiredAnyAccountTypes.length === 0 || item.requiredAnyAccountTypes.includes(userAccount.type)
				const visible = validPermissions && validAccountType;

				return (
					<SideNavItem
						active={active}
						hide={!visible}
						disabled={item.disabled}
						external={item.external}
						icon={item.icon}
						key={item.title}
						path={item.path}
						title={item.title}
						sideNavOpen={open}
					/>
				);
			})}
		</Stack>
	</Box>

	if (mdUp) {
		return (
			<MuiDrawer variant="permanent" open={open}>
				<Box
					component={Link}
					href='/overview'
					sx={{ p: 2, height: '3.25rem', width: '3.25rem', pb: '3rem' }}
				>
					<Logo />
					<br />
					<Chip label="Beta" variant="outlined" color='primary' size="small" sx={{ width: '4rem', mt: '0.5rem' }} />
				</Box>

				<Divider sx={{ borderColor: 'neutral.700' }} />

				{sideNavItemsBox}

				<Divider sx={{ borderColor: 'neutral.700' }} />

				<IconButton
					sx={{
						m: 1,
						borderRadius: 1,
						display: 'flex',
						justifyContent: 'center',
						backgroundColor: 'rgba(255, 255, 255, 0.04)',
						'&:hover': {
							backgroundColor: 'rgba(255, 255, 255, 0.04)'
						}
					}}
					onClick={() => onSetOpenNav(!open)}
				>
					{open && <ChevronLeftIcon /> || <ChevronRightIcon />}
				</IconButton>


				<AccountSelector />
			</MuiDrawer>
		);
	}

	const smallContent = (
		<Scrollbar
			sx={{
				height: '100%',
				'& .simplebar-content': {
					height: '100%'
				},
				'& .simplebar-scrollbar:before': {
					background: 'neutral.400'
				}
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%'
				}}
			>
				<Box
					component={Link}
					href='/overview'
					sx={{ p: 3, height: '3rem', width: '3rem', display: 'inline-flex' }}
				>
					<Logo />
				</Box>

				<Divider sx={{ borderColor: 'neutral.700' }} />
				{sideNavItemsBox}
				<Divider sx={{ borderColor: 'neutral.700' }} />

				<AccountSelector />
			</Box>
		</Scrollbar>
	);

	return (
		<Drawer
			anchor='left'
			onClose={() => onSetOpenNav(false)}
			open={open}
			PaperProps={{
				sx: { backgroundColor: 'neutral.800', color: 'common.white', width: 280 }
			}}
			sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
			variant='temporary'
		>
			{smallContent}
		</Drawer>
	);
};

SideNav.propTypes = {
	open: PropTypes.bool.isRequired,
	onSetOpenNav: PropTypes.func.isRequired
};
