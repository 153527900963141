import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Backdrop, Box, Button, CircularProgress, TextField, Typography } from "@mui/material";

import { setUser } from "services/redux/slicers/slicers.auth";
import { putUserDetails } from "api/user";
import { capitalize } from "lodash";

export const SettingsProfilePage = () => {
	const dispatch = useDispatch();
	const [userName, setUserName] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const user = useSelector((state) => state.auth.user);

	useEffect(() => { setUserName(user.name); }, [user]);

	const onUserNameChange = (e) => setUserName(e.target.value);

	const onSaveClick = () => {
		if (loading) { return; }
		setLoading(true);
		putUserDetails({ name: userName })
			.then(resp => dispatch(setUser(resp.data)))
			.catch(err => {
				console.error('error putting user details', err)
				setError(error.response.data.message || error.message);

			})
			.finally(() => setLoading(false));
	};

	return (
		<Box p={2} px={5} position={'relative'}>
			<Typography variant="h6" mb={2}>
				Details
			</Typography>

			<Typography variant="body2">
				Name
			</Typography>
			<Typography variant="body2" color={'#6e6e80'} >
				The name associated with this user
			</Typography>
			<TextField
				label='Name'
				value={userName}
				sx={{ width: '20rem' }}
				onChange={onUserNameChange}
			/>

			<Typography variant="body2" sx={{ mt: 2 }}>
				Email
			</Typography>
			<Typography variant="body2" color={'#6e6e80'} >
				The email associated with this user
			</Typography>
			<TextField
				disabled
				label='email'
				value={user.email}
				sx={{ width: '20rem' }}
			/>

			<Typography variant="body2" sx={{ mt: 2 }}>
				Role
			</Typography>
			<Typography variant="body2" color={'#6e6e80'} >
				{capitalize(user.role)}
			</Typography>

			<br />
			<Button onClick={onSaveClick} variant="contained" sx={{ mt: 3 }}>
				Save
			</Button>

			<Backdrop
				open={loading}
				sx={{
					color: '#fff',
					borderRadius: '5px',
					position: 'absolute',
					zIndex: (theme) => theme.zIndex.drawer + 1
				}}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Box >
	);
};
