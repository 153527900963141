import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	accounts: []
};

export const klaviyoSlice = createSlice({
	name: 'klaviyo',

	initialState,

	reducers: {
		setKlaviyoAccounts: (state, action) => {
			state.accounts = action.payload;
		},
		addKlaviyoAccount: (state, action) => {
			state.accounts = [...state.accounts, action.payload];
		}
	},
});

// Action creators are generated for each case reducer function
export const {
	setKlaviyoAccounts,
	addKlaviyoAccount
} = klaviyoSlice.actions;

export default klaviyoSlice.reducer;
