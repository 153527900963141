import { createSlice } from '@reduxjs/toolkit';
// import { get } from 'lodash';

// import { PERMISSIONS } from 'config/permissions';

// const doesUserHaveFeatures = (usersFeatures, rules) => {
// 	const { requiredFeatures } = rules;
// 	// Assert that the user have all required features
// 	return requiredFeatures.every(feature => usersFeatures.includes(feature));
// };


// const createUserPermissions = (state) => {
// 	// const usersFeatures = get(state, 'user.feature_access', []);
// 	// const usersFeatures = state.feature_access;
// 	// console.log('setAllPermissions', usersFeatures);
// 	// const permissions = {};

// 	// For each permission rule, calculate it and then add it to the permissions list
// 	// Object.keys(USER_PERMISSION_RULES_LIBRARY).forEach(permissionName =>
// 	// 	permissions[permissionName] = doesUserHaveFeatures(
// 	// 		usersFeatures,
// 	// 		USER_PERMISSION_RULES_LIBRARY[permissionName]
// 	// 	)
// 	// );

// 	const userPermissionCodes = state.user.permission_codes;
// 	const permissions = {};
// 	const permissionKeys = Object.keys(PERMISSIONS)
// 	for (let i = 0; i < permissionKeys.length; i++) {
// 		const permissionName = permissionKeys[i];
// 		const permissionValue = PERMISSIONS[permissionName];
// 		permissions[permissionValue] = userPermissionCodes.includes(PERMISSIONS[permissionName]);
// 	}

// 	// const permissionKeys = Object.keys(PERMISSIONS)
// 	// for (let i = 0; i < userPermissionCodes.length; i++) {
// 	// 	const permissionName = userPermissionCodes[i];
// 	// 	permissions[userPermissionCodes] = userPermissionCodes.includes(PERMISSIONS[permissionName]);
// 	// }

// 	return permissions;
// };

const initialState = {
	user: null,
	userAccount: null,
	multiTenancyAccounts: null,
	loading: false,
	checkingUser: false,
	accessToken: null,
	isAuthenticated: false,
	permissions: {},

};

export const authSlice = createSlice({
	name: 'auth',

	initialState,

	reducers: {
		setAccessToken: (state, action) => {
			state.accessToken = action.payload;
		},
		startLoading: (state) => {
			state.loading = true;
		},
		stopLoading: (state) => {
			state.loading = false;
		},
		setUser: (state, action) => {
			state.user = action.payload;
		},
		setMultiTenancyAccounts: (state, action) => {
			state.multiTenancyAccounts = action.payload;
		},
		setAuthenticated(state, action) {
			state.isAuthenticated = action.payload;
		},
		setUserAccount(state, action) {
			state.userAccount = action.payload;
		},
		setCheckingUser(state, action) {
			state.checkingUser = action.payload;
		},
		setUserPermissions: (state, action) => {
			state.permissions = action.payload;
		},

		logout: (state) => {
			// localStorage.clear('persist:root');
			Object
				.keys(initialState)
				.forEach(key => state[key] = initialState[key]);
		}
	}
});

// Action creators are generated for each case reducer function
export const {
	setAccessToken,
	startLoading,
	stopLoading,
	setUser,
	setAuthenticated,
	setUserAccount,
	setCheckingUser,
	setUserPermissions,
	logout,
	setMultiTenancyAccounts
} = authSlice.actions;

export default authSlice.reducer;
