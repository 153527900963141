import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { get, isEqual } from "lodash";
// import { Button } from "@mui/material";
import { isDeepEqual } from "@mui/x-data-grid/internals";

import { patchPromptAndKeywords, createPromptAndKeywords } from "api/ai-prompts";
import { setOpenError, setOpenSuccess, setOpenWarning } from "services/redux/slicers/slicers.snackbar";
import { setPrompt, setPromptTextValidationError, setUpsertButtonVisibility } from "services/redux/slicers/slicers.prompt-editor";
import { LoadingButton } from "@mui/lab";

export const FinishButton = ({ minKeywords = 0, buttonVisibility, validateTemplate }) => {
	const dispatch = useDispatch();
	const [originalPrompt, setOriginalPromt] = useState(null);
	const [loading, setLoading] = useState(false);
	const prompt = useSelector((state) => state.promptEditor.prompt);
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);
	const upsertButtonVisibility = useSelector((state) => state.promptEditor.upsertButtonVisibility);

	/**
	 * A React hook that checks for changes in specific fields between the original and current prompt,
	 * determines the action type (update or insert), and updates the visibility of the upsert button.
	 *
	 * @param {function} dispatch - The dispatch function from a Redux store to dispatch actions.
	 * @param {function} buttonVisibility - The function to determine the visibility of the upsert button.
	 * @param {number} minKeywords - The minimum number of keywords required for the prompt.
	 * @param {Object} originalPrompt - The original prompt object.
	 * @param {Object} prompt - The current prompt object.
	 */
	const checkForChanges = useCallback(() => {
		// The fields to compare between the old and new 
		const keysToCompare = [
			'text',
			'name',
			'description',
			'gpt_config',
			'keywords',
			'klaviyo_campaign_id',
			'backup_text_static',
			'prompt_rules',
			'klaviyo_metric_id',
			// 'payload_product_identifier'
		];

		const sortFn = (a, b) => (a?.key?.localeCompare(b.key));
		const arePromptsEqual = keysToCompare.every((key) => {
			if (Array.isArray(prompt[key])) {
				// We have to sort arrays before we can compare them
				return isDeepEqual([...originalPrompt[key]].sort(sortFn), [...prompt[key]].sort(sortFn));
			} else {
				// isEqual compares objects, string, numbers and a bunch of others
				return isEqual(originalPrompt[key], prompt[key]);
			}
		});

		const actionType = prompt.id ? 'update' : 'insert';
		const showButton = buttonVisibility({
			actionType,
			minKeywords,
			currentPrompt: prompt,
			arePromptsEqual
		});

		dispatch(setUpsertButtonVisibility(showButton));
	}, [dispatch, buttonVisibility, minKeywords, originalPrompt, prompt]);

	// Store the original prompt with no changes made to it - then we have something to compare changes to
	useEffect(() => {
		if (originalPrompt && prompt) { checkForChanges(); }
		else { setOriginalPromt(prompt); }
	}, [prompt, originalPrompt, checkForChanges]);


	const upsert = async () => {
		try {
			// Validate with Handlebar
			const { valid, error } = await validateTemplate(prompt);
			if (!valid) { dispatch(setPromptTextValidationError(error?.message)); return; }

			// Clear any errors set previously now that the prompt is valid
			dispatch(setPromptTextValidationError(null));

			// Ensure an account has been selected
			if (!currentlySelectedAccount || !currentlySelectedAccount.id) {
				dispatch(setOpenWarning({ title: 'Missing Account', message: 'You need to select an account to create a prompt' }));
				return;
			}

			let upsertedPrompt;
			// console.log('upserting', prompt);
			setLoading(true);
			if (prompt.id) {
				// UPDATE the prompt and its keywords
				await patchPromptAndKeywords(prompt);
				upsertedPrompt = prompt;
			} else {
				// INSERT
				const resp = await createPromptAndKeywords({ prompt, accountId: currentlySelectedAccount.id });
				upsertedPrompt = resp.data;
			}

			// Set the updated prompt as the one to compare to
			setOriginalPromt(upsertedPrompt);

			// Save the prompt to set it as the new "original prompt"
			dispatch(setPrompt(upsertedPrompt));

			// Display success snackbar
			dispatch(setOpenSuccess({ title: prompt.id ? "Updated Prompt" : "Created Prompt" }));
		} catch (error) {
			// Display error snackbar+
			console.error('error upserting', error);
			const title = prompt.id ? "Error Updating Prompt" : "Error Creating Prompt";
			const message = get(error, 'response.data.message', error.message);
			dispatch(setOpenError({ title, message }));
		} finally {
			setLoading(false);
		}
	};

	// If the prompt has an id, it exists in the database and we are then updating, not creating
	const buttonText = prompt?.id ? 'Update Prompt' : 'Create New';

	if (!prompt || !upsertButtonVisibility) { return null; }
	// else return <Button variant="contained" sx={{ p: 3, mb: 2 }} onClick={upsert} fullWidth>{buttonText}</Button>;
	else return (
		<LoadingButton
			fullWidth
			onClick={upsert}
			loading={loading}
			variant="contained"
			sx={{ p: 3, mb: 2 }}
		>{buttonText}</LoadingButton>
	);
};

FinishButton.propTypes = {
	minKeywords: PropTypes.number,
	buttonVisibility: PropTypes.func.isRequired,
	validateTemplate: PropTypes.func.isRequired
};
