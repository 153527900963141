import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import { HoverToPreviewPrompt } from "components/account-detail-page/tabs/csv-parse/uploader-page/hover-to-preview-prompt";
import { setOpenError } from "services/redux/slicers/slicers.snackbar";
import { setPrompt } from "services/redux/slicers/slicers.prompt-editor";
import { Link } from "@mui/material";
import { StyledDataGrid } from "assets/styles/components/styled-data-grid";
import { messageFromSuspectedXanoError } from "api/axios-api";

const GET_COLUMNS = ({ rowClick }) => [
	{ field: 'id', headerName: 'ID', width: 100, headerAlign: 'center', align: 'center' },
	{
		field: 'name',
		headerName: 'Name',
		width: 400,
		renderCell: (params) => (
			<Link
				component="button" variant="body2"
				onClick={() => rowClick(params.row)}
			>{params.row.name}</Link>
		)
	},
	{
		field: 'description',
		headerName: 'Description',
		width: 250
	},
	// {
	// 	field: 'keywords',
	// 	headerName: 'Keywords',
	// 	valueGetter: ({ value }) => value?.length,
	// 	width: 80
	// },
	{
		field: 'created_at',
		headerName: 'Created',
		type: 'dateTime',
		width: 200,
		valueGetter: ({ value }) => value && new Date(value),
	},
	{
		field: 'text',
		headerName: 'Text',
		renderCell: HoverToPreviewPrompt
	}
];

export const PromptSelectorTable = ({ getPrompts }) => {
	const dispatch = useDispatch(); // Redux
	const [prompts, setPrompts] = useState([]); // State
	const [columns, setColumns] = useState([]); // State
	const [loading, setLoading] = useState(false); // State

	useEffect(() => {
		// Set the prompt in redux when prompt is selected
		const rowClick = (row) => dispatch(setPrompt(row))
		setColumns(GET_COLUMNS({ rowClick }));
	}, [dispatch])

	useEffect(() => {
		setLoading(true);
		// Get the this currently selected account's prompts
		getPrompts()
			.then(resp => setPrompts(resp.data)) // Set the newly retrieved prompts
			.catch(error => {
				// Display error in snackbar
				console.log('error in getPrompts', error);
				dispatch(setOpenError({ title: "Error Getting Campaign Prompts", message: messageFromSuspectedXanoError(error) }));
			})
			.finally(() => setLoading(false));
	}, [dispatch, getPrompts]);

	return (
		<StyledDataGrid
			rows={prompts ? prompts : []}
			columns={columns}
			loading={loading}
			initialState={{
				sorting: { sortModel: [{ field: 'created_at', sort: 'desc' }] },
				pagination: { paginationModel: { pageSize: 10 } }
			}}
			autoHeight
			pageSizeOptions={[10]}
			disableRowSelectionOnClick
			localeText={{ noRowsLabel: 'No prompts were found for the selected account' }}
		/>
	);
};

PromptSelectorTable.propTypes = {
	getPrompts: PropTypes.func.isRequired
};
