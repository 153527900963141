// These values correspond to the "feature_access" field in Xano on the "User" table
export const FEATURES = {
	LEADDRIVE: 'leaddrive',
	LEADDRIVE_CSV: 'leaddrive_csv',
	KLAVIYO_WEBHOOK: 'klaviyo_webhook',
	KLAVIYO_CAMPAIGN: 'klaviyo_campaign',
	CREATE_ACCOUNTS: 'create_accounts',
	CREATE_AGENCIES: 'create_agencies',
	VIEW_AGENCIES: 'view_agencies',
	ZAPIER_INTEGRATION_KEYWORDS: 'zapier_integration_keywords',
	GPT_MODEL_CONFIGURATION: 'model_configuration'
};

// A library of how to calculate each permission - these get added to the "PERMISSIONS" object as bools
export const USER_PERMISSION_RULES_LIBRARY = {
	CAN_CREATE_ACCOUNTS: {
		requiredFeatures: [FEATURES.CREATE_ACCOUNTS]
	},
	CAN_VIEW_AGENCIES: {
		requiredFeatures: [FEATURES.VIEW_AGENCIES]
	},
	CAN_CREATE_AGENCIES: {
		requiredFeatures: [FEATURES.CREATE_AGENCIES]
	},
	CAN_CREATE_NON_REFERENCED_KEYWORDS: {
		requiredFeatures: [FEATURES.ZAPIER_INTEGRATION_KEYWORDS]
	},
	CAN_CHANGE_GPT_MODEL: {
		requiredFeatures: [FEATURES.GPT_MODEL_CONFIGURATION]
	},
	CAN_CREATE_KLAVIYO_FLOW_PROMPT: {
		requiredFeatures: [FEATURES.KLAVIYO_WEBHOOK]
	},
	CAN_CREATE_KLAVIYO_FLOW_CAMPAIGN: {
		requiredFeatures: [FEATURES.KLAVIYO_CAMPAIGN]
	}
};

export const ACCOUNT_TYPES = {
	individual: 'individual',
	managed: 'agency_managed',
	agency: 'agency',
}

// values should match the "Permissions" in xano
export const PERMISSIONS = {
	klaviyoWebhookRead: 'klaviyo_webhook_read',
	klaviyoWebhookWrite: 'klaviyo_webhook_write',
	multipleKlaviyoAccountsWrite: 'multiple_klaviyo_accounts_write',
	klaviyoCampaignRead: 'klaviyo_campaign_read',
	klaviyoCampaignWrite: 'klaviyo_campaign_write',
	klaviyoCampaignStartJob: 'klaviyo_campaign_start_job',
	managedAccountWrite: 'managed_account_write',
	managedAccountRead: 'managed_account_read',
	csvParseWrite: 'csv_parse_write',
	csvParseRead: 'csv_parse_read',
	promptConfigModelSet: 'prompt_configuration_model_set',
	promptConfigModelSet_35t_1106: 'prompt_configuration_model_35t_1106',
	promptConfigModelSet_4_1106: 'prompt_configuration_model_4_1106',
	promptConfigStaticTextWrite: 'prompt_configuration_static_text_write',
	promptConfigStaticTextRead: 'prompt_configuration_static_text_read',
	promptConfigBackupTextWrite: 'prompt_configuration_backup_text_write',
	promptConfigBackupTextRead: 'prompt_configuration_backup_text_read',
	convertIndividualAccountToAgency: 'convert_individual_account_to_agency',
	manageAccount: 'manage_account',
};

// A library of how to calculate each permission - these get added to auth redux slice for global use
// export const USER_PERMISSION_RULES = {
// 	CAN_CREATE_ACCOUNTS: {
// 		requiredPermissions: [PERMISSIONS.CREATE_ACCOUNTS]
// 	},
// 	CAN_VIEW_AGENCIES: {
// 		requiredPermissions: [PERMISSIONS.VIEW_AGENCIES]
// 	},
// 	CAN_CREATE_AGENCIES: {
// 		requiredPermissions: [PERMISSIONS.CREATE_AGENCIES]
// 	},
// 	CAN_CREATE_NON_REFERENCED_KEYWORDS: {
// 		requiredPermissions: [PERMISSIONS.ZAPIER_INTEGRATION_KEYWORDS]
// 	},
// 	CAN_CHANGE_GPT_MODEL: {
// 		requiredPermissions: [PERMISSIONS.GPT_MODEL_CONFIGURATION]
// 	},
// 	CAN_CREATE_KLAVIYO_FLOW_PROMPT: {
// 		requiredPermissions: [PERMISSIONS.KLAVIYO_WEBHOOK]
// 	},
// 	CAN_CREATE_KLAVIYO_FLOW_CAMPAIGN: {
// 		requiredPermissions: [PERMISSIONS.KLAVIYO_CAMPAIGN]
// 	}
// };
