import { LoadingButton } from '@mui/lab';
import { TextField, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { putKlaviyoAccountDetails } from 'api/klaviyo-accounts';
import { set } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setOpenError } from 'services/redux/slicers/slicers.snackbar';
import { setKlaviyoAccounts } from 'services/redux/slicers/slicers.klaviyo-page';

export const KlaviyoAccountDetails = () => {
	const dispatch = useDispatch();
	const { klaviyoAccountId } = useParams();
	const klaviyo = useSelector((state) => state.klaviyo);
	// const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);
	const [klaviyoAccount, setKlaviyoAccount] = useState(null);
	const [changesMade, setChangesMade] = useState(false);
	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		if (klaviyoAccountId && Array.isArray(klaviyo?.accounts)) {
			console.log('klaviyo.accounts', klaviyo.accounts, klaviyoAccountId, klaviyo.accounts[0].id);
			const activeKlaviyoAccount = klaviyo.accounts.find(acc => acc.id.toString() === klaviyoAccountId);
			console.log('activeKlaviyoAccount', activeKlaviyoAccount);
			setKlaviyoAccount(activeKlaviyoAccount);
		}
	}, [klaviyoAccountId, klaviyo.accounts]);

	function onSaveClicked() {
		setLoading(true);
		putKlaviyoAccountDetails(klaviyoAccount)
			.then(resp => {
				console.log('updated klaviyo acc', resp);
				// dispatch(setUser(resp.data))
				const updatedKlaviyoAccounts = klaviyo.accounts.map(acc => {
					if (acc.id.toString() === klaviyoAccountId) { return resp.data; }
					return acc;
				});

				dispatch(setKlaviyoAccounts(updatedKlaviyoAccounts));
			})
			.catch(err => {
				console.error('error putting klav acc details', err)
				dispatch(setOpenError({ title: 'Error updating Klaviyo Account', message: err.response.data.message || err.message }))
			})
			.finally(() => setLoading(false));
	}

	function onChange(e, path) {
		const updatedAcc = set(structuredClone(klaviyoAccount), path, e.target.value);
		setKlaviyoAccount(updatedAcc);
		setChangesMade(true);
	}

	if (!klaviyoAccount) { return null; }
	return (
		<Grid>
			<Typography>
				Klaviyo Account details
				{klaviyoAccount?.name}
			</Typography>

			<Typography variant='h6' sx={{ mt: 3 }}>Shopify</Typography>
			<Typography variant="body2" color={'#6e6e80'}>The API key from the app</Typography>
			<TextField
				label='API Key'
				sx={{ width: '20rem' }}
				onChange={e => onChange(e, 'shopify_credentials.api_key')}
				value={klaviyoAccount.shopify_credentials.api_key}
			/>

			<Typography variant="body2" color={'#6e6e80'} sx={{ mt: 1 }}>
				The admin API access token the app - can&apos;t be viewed after being set
			</Typography>
			<TextField
				label="**********************"
				sx={{ width: '20rem' }}
				value={klaviyoAccount.shopify_credentials.admin_api_access_token || ''}
				onChange={e => onChange(e, 'shopify_credentials.admin_api_access_token')}
			/>

			<Typography variant="body2" color={'#6e6e80'} sx={{ mt: 1 }}>
				The Shopify Store URL (ex. store.myshopify.com)
			</Typography>
			<TextField
				label="Full Store Url"
				sx={{ width: '20rem' }}
				value={klaviyoAccount.shopify_credentials.store_url}
				onChange={e => onChange(e, 'shopify_credentials.store_url')}
			/>

			<br />
			{changesMade && (
				<LoadingButton onClick={onSaveClicked} variant="contained" sx={{ mt: 2 }} loading={isLoading}>
					Save
				</LoadingButton>
			)}
		</Grid>
	);
};
