import React, { Fragment, useEffect, useState } from 'react';
import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import { StyledDataGrid } from 'assets/styles/components/styled-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { GridActionsCellItem, GridDeleteIcon } from '@mui/x-data-grid';
import { updateAccountBlacklist } from 'api/account';
import { setCurrentlySelectedAccount } from 'services/redux/slicers/slicers.accounts';

const COLUMNS = (removeBlacklistItem) => [
	{
		field: 'title',
		headerName: 'Name',
		width: 300,
	},
	{
		field: 'description',
		headerName: 'Description',
		width: 500,
	},
	{ field: 'id', headerName: 'Id', width: 200, headerAlign: 'center', align: 'center' },
	{
		field: 'actions',
		type: 'actions',
		getActions: (params) => [
			<GridActionsCellItem icon={<GridDeleteIcon />} onClick={() => { removeBlacklistItem(params.id) }} label="Delete" key={0} />
		]
	}
];

export const Blacklist = (props) => {
	const dispatch = useDispatch();
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);
	const [blacklistRows, setBlacklistRows] = useState([]);
	const [loading, setLoading] = useState(false);
	const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
	const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
	const [autoCompleteProduct, setAutoCompleteProduct] = useState(null);


	useEffect(() => {
		// console.log('bl', currentlySelectedAccount);
		const blacklist = currentlySelectedAccount.store_products_blacklist || [];
		// console.log('blacklist', blacklist);

		const products = props.products;
		if (!Array.isArray(products) || products.length === 0) { return; }
		const _blacklistRows = [];
		const _autoCompleteOptions = [];
		for (let i = 0; i < products.length; i++) {
			const product = products[i];
			if (blacklist.includes(product.id.toString())) { _blacklistRows.push(product); }
			else { _autoCompleteOptions.push(product); }
		}

		// console.log('_blacklistRows', _blacklistRows);
		// console.log('_autoCompleteOptions', _autoCompleteOptions);
		setBlacklistRows(_blacklistRows);
		setAutoCompleteOptions(_autoCompleteOptions);

	}, [currentlySelectedAccount.store_products_blacklist, props.products]);

	function removeBlacklistItem(productIdToRemove) {
		// console.log('remove blacklist item', productIdToRemove);
		setLoading(true);
		setAutoCompleteOpen(false);
		const oldBlacklist = currentlySelectedAccount.store_products_blacklist || [];
		const newBlacklist = oldBlacklist.filter(id => id.toString() !== productIdToRemove.toString());

		updateAccountBlacklist(currentlySelectedAccount.id, newBlacklist)
			.then(resp => {
				// Set returned acc in redux
				const accountClone = { ...currentlySelectedAccount };
				accountClone.store_products_blacklist = resp.data; // get blacklist from resp to ensure format
				dispatch(setCurrentlySelectedAccount(accountClone));
			}).catch(err => {
				console.log('error adding blacklist items', err);
			}).finally(() => {
				setLoading(false);
				setAutoCompleteProduct(null);
			});

	}

	function addBlacklistItem(_, productToAdd) {
		setAutoCompleteProduct(productToAdd);
		setLoading(true);
		setAutoCompleteOpen(false);
		const oldBlacklist = currentlySelectedAccount.store_products_blacklist || [];
		const newBlacklist = [...oldBlacklist, productToAdd.id];
		updateAccountBlacklist(currentlySelectedAccount.id, newBlacklist)
			.then(resp => {
				// Set returned acc in redux
				const accountClone = { ...currentlySelectedAccount };
				accountClone.store_products_blacklist = resp.data; // get blacklist from resp to ensure format
				dispatch(setCurrentlySelectedAccount(accountClone));
			}).catch(err => {
				console.log('error adding blacklist items', err);
			}).finally(() => {
				setLoading(false);
				setAutoCompleteProduct(null);
			});
	}

	return (
		<Box>
			<Typography>
				Blacklist
			</Typography>

			<StyledDataGrid
				rows={blacklistRows}
				columns={COLUMNS(removeBlacklistItem)}
				autoHeight
				loading={loading}
				initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
				pageSizeOptions={[5]}
				disableRowSelectionOnClick
			/>
			<br />
			<Autocomplete
				disablePortal
				options={autoCompleteOptions || []}
				sx={{ width: 500 }}
				getOptionLabel={(option) => {
					if (autoCompleteOptions.filter(product => product.title === option.title).length > 1) {
						// return option.title + ' (' + option.id + ')'
						return option.title + ' (' + option.handle + ')'
					}
					return option.title;
				}}
				value={autoCompleteProduct}
				disabled={loading}
				open={autoCompleteOpen}
				onOpen={() => { setAutoCompleteOpen(true); }}
				onClose={() => { setAutoCompleteOpen(false); }}
				onChange={addBlacklistItem}
				renderInput={(params) => <TextField
					{...params}
					label="Product - Click to add"
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<Fragment>
								{loading ? <CircularProgress color="primary" size={20} /> : null}
								{params.InputProps.endAdornment}
							</Fragment>
						),
					}}
				/>}
			// renderInput={(params) => <TextField label={params.title} />}
			/>
			<Typography variant='body2'>
				Blacklisting a product means it won&apos;t be recommended and will be filtered from purchase histories
			</Typography>
		</Box>
	);
};


Blacklist.propTypes = {
	products: PropTypes.array
};
