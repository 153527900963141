import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Paper, Typography, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Alert } from "@mui/material";

import { ClickToCopyTextAndLabel } from './click-to-copy-text-and-label';
import { ClickToCopyText } from './click-to-copy-text';

const XANO_BASE_URL = process.env.REACT_APP_XANO_LEADDRIVE_API_BASE_URL;

export const WebhookStep = () => {
	const prompt = useSelector((state) => state.promptEditor.prompt); // Redux
	const klaviyoAccounts = useSelector((state) => state.klaviyo.accounts); // Redux
	const [leaddriveApiKey, setLeaddriveApiKey] = useState(null);

	useEffect(() => {
		if (!klaviyoAccounts) { return; }
		const selectedKlaviyoAccount = klaviyoAccounts.find(acc => acc.id === prompt.klaviyo_account_id);
		if (selectedKlaviyoAccount) { setLeaddriveApiKey(selectedKlaviyoAccount.droplet_validation_key); }
	}, [klaviyoAccounts, setLeaddriveApiKey, prompt.klaviyo_account_id]);

	if (!prompt) { return null; }

	return (
		<div>
			<Alert severity="info">Click hightlighted text to copy</Alert>
			<br />

			<ClickToCopyTextAndLabel
				label={'Destination URL'}
				value={`${XANO_BASE_URL}/klaviyo/ai_generate_content/${prompt.id}/run`}
			/>

			<Typography sx={{ mt: 3 }}><b>Headers</b></Typography>
			<TableContainer component={Paper} >
				<Table sx={{ minWidth: 650 }} aria-label="webhook headers table">
					<TableHead>
						<TableRow>
							<TableCell>Key</TableCell>
							<TableCell>Value</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>
								<ClickToCopyText
									value='leaddriveApiKey'
									fullWidth={false}
									isKey
									label={'leaddriveApiKey header key'}
								/>
							</TableCell>
							<TableCell>
								<ClickToCopyText
									value={leaddriveApiKey}
									fullWidth={true}
									label={'leaddriveApiKey header value'}
									clickToShow
								/>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>

			<ClickToCopyTextAndLabel
				sx={{ mt: 3 }}
				label={'JSON Body'}
				value={`{\n\t"person": {{ person | json_script:"" | striptags }},\n\t"event": {{ event | json_script:"" | striptags }},\n\t"klaviyoID": "{{ person.KlaviyoID }}"\n}`}
			/>
		</div>
	);
};