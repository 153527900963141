import { useSelector } from 'react-redux';
import { Box } from "@mui/material";
import { getCustomerPortalSession } from "api/account";
import { TiersRow } from 'components/pricing-page/tiers-row';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';

export const SettingsBillingPage = () => {
	const [loading, setLoading] = useState(false);
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);

	const goToStripeCustomerPortal = () => {
		setLoading(true);
		getCustomerPortalSession(currentlySelectedAccount.id)
			.then(resp => {
				console.log('resp', resp);
				window.open(resp.data.customer_portal_session.url, "_blank");
			}).catch(err => {
				console.error('err', err);
			}).finally(() => {
				setLoading(false);
			})
	}

	return (
		<Box>
			<LoadingButton onClick={goToStripeCustomerPortal} variant="contained" loading={loading}>
				Go to Stripe Billing Page
			</LoadingButton>
		
			<br />
			<br />

			<TiersRow />
		</Box>
	)
};

