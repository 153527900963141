import { Card } from "@mui/material";
import { PropTypes } from 'prop-types';

export const AccordionCard = ({ children }) => {
	return (<Card sx={{ bgcolor: '#2a2e35', mt: 1 }}>{children}</Card>);
};

AccordionCard.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.arrayOf(PropTypes.object)
	])
};
