import { WebhookStep } from "./values-to-copy-viewer-components/webhook-step";
import { EmailUsageStep } from "components/shared-components/prompt-editor/email-usage-step";
import { ValuesToCopyViewer } from "components/shared-components/prompt-editor/values-to-copy-viewer";
import { Typography } from "@mui/material";

const steps = [
	{
		label: 'Webhook Details',
		clickable: true,
		component: <WebhookStep />
	},
	{
		label: 'Time Delay (15 min.)',
		component: <TimeDelayStep />,
		clickable: false,
	},
	{
		label: 'Email Usage',
		clickable: true,
		component: <EmailUsageStep />
	},
	// {
	// 	label: 'Repeat!',
	// 	clickable: false,
	// },
];

function TimeDelayStep() {
	return (
		<Typography>
			Implement a &quot;Time Delay&quot; action of 15 minutes between the Leaddrive webhook and the email.<br/>
			This measure is intended to allow the AI sufficient time to generate content and update the profile before the email is dispatched.
		</Typography>
	);
}

export const KlaviyoWebhookValueCopier = () => {
	return <ValuesToCopyViewer steps={steps} />
};
