import React from 'react';
import { Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { TiersRow } from 'components/pricing-page/tiers-row';
import { WebsiteLayout } from 'layouts/website/layout';

export const PricingPage = () => {
	return (
		<WebsiteLayout>
			<Grid xs={12} marginTop={'3rem'} marginBottom={'1rem'}>
				<Typography variant='h2' textAlign={'center'}>Pricing</Typography>
			</Grid>
			
			<TiersRow />
		</WebsiteLayout>
	);
};
