import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ImageList, ImageListItem, ImageListItemBar, Typography, Box, Skeleton } from "@mui/material";
import { styled } from '@mui/material/styles';

import { updatePromptPreview } from "services/redux/slicers/slicers.prompt-editor";
import { SelectNewTemplateSplitButton } from "./select-new-template-split-button";
import { KlaviyoTemplateSearch } from "./klaviyo-template-search";
import { getKlaviyoTemplates } from "api/klaviyo-accounts";

const TemplateBlock = styled(ImageListItem)`
	border-radius: 8px;
	overflow: hidden;
	margin-left: 1rem;
	margin-bottom: 1rem !important;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

	:hover {
		opacity: 0.8;
		cursor: pointer;
		box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
	}
`;

const SKELETON_STYLING = { width: 'inherit', minWidth: '600px', height: 750, borderRadius: '8px', overflow: 'hidden', margin: 1 };

export const KlaviyoEmailTemplateSelector = () => {
	const dispatch = useDispatch();
	const { klaviyoAccountId } = useParams();
	
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [templates, setTemplates] = useState(null);
	const [searchName, setSearchName] = useState(null);
	
	const templateToRender = useSelector((state) => state.promptEditor.preview?.templateToRender);

	useEffect(() => {
		setLoading(true); // Start loading
		setError(null); // Clear error

		getKlaviyoTemplates({ klaviyoAccountId, searchName })
			.then((resp) => { setTemplates(resp.data); })
			.catch(err => { console.log('err', err); setError(err); })
			.finally(() => setLoading(false));
	}, [klaviyoAccountId, searchName]);

	// Set selected template in redux
	const templateSelected = (template) => {
		dispatch(updatePromptPreview({ key: 'templateToRender', value: template }));
	};

	// Display error - if any
	if (error) {
		return (
			<Box>
				<Typography variant="h5">Error in Template Selector</Typography>
				<Typography>{error.message}</Typography>
			</Box>
		);
	}
	// If a template has been selected, show the "Select New" options
	else if (templateToRender) { return <SelectNewTemplateSplitButton templates={templates} /> }

	// If loading, show skeleton
	else if (loading) {
		return (
			<Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
				<Skeleton variant='rectangular' sx={SKELETON_STYLING} />
				<Skeleton variant='rectangular' sx={SKELETON_STYLING} />
			</Box>
		);
	} else if (templates && templates.length > 0) {
		// If any templates were found, display them
		return (
			<Box>
				<Typography variant={'h6'} marginY={1}>
					Select a Template to render, from the options below.
				</Typography>
				<KlaviyoTemplateSearch onSearch={setSearchName} searchName={searchName} />

				<ImageList variant="masonry" cols={templates.length > 1 ? 2 : 1} sx={{ width: 'max-content', paddingBottom: '2rem' }}>
					{templates.map(template => (
						<TemplateBlock
							key={template.id}
							onClick={() => templateSelected(template)}
						>
							<div dangerouslySetInnerHTML={{ __html: template.attributes.html }}></div>
							<ImageListItemBar title={template.attributes.name}>
							</ImageListItemBar>
						</TemplateBlock>
					))}
				</ImageList>
			</Box>
		);
	} if (templates.length === 0) {
		// If no templates were found, let the user know
		return (
			<Box>
				<Typography variant={'h6'} marginY={1}>
					No Templates could be found for the Klaviyo Account
				</Typography>
				<KlaviyoTemplateSearch onSearch={setSearchName} searchName={searchName} />
			</Box>
		);
	}
	else { return null; }
};

KlaviyoEmailTemplateSelector.propTypes = {
};