import styles from './puls-icon.module.css'

export const PulseIcon = () => {

	return (
		<div className={styles.div}>
			<div className={styles.dotFront} />
			<div className={styles.dotBack} />
		</div>
	);
};   
