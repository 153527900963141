// import ChartBarIcon from '@heroicons/react/24/solid/ChartBarIcon';
// import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import BusinessIcon from '@mui/icons-material/Business';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import InventoryIcon from '@mui/icons-material/Inventory';
import { SvgIcon } from '@mui/material';

// On each nav item, we specify which features the user needs (if any) to view the item
// The key's value must match the one in the "feature_access" on the User object in Xano
import { PERMISSIONS, ACCOUNT_TYPES } from 'config/permissions';

export const ITEMS = [
	{
		title: 'Home',
		path: '/overview',
		requiredFeatures: [],
		requiredAnyPermissions: [],
		requiredAnyAccountTypes: [],
		icon: (<SvgIcon fontSize="small"><HomeIcon /></SvgIcon>)
	},
	{
		title: 'Accounts',
		path: '/accounts',
		requiredAnyPermissions: [],
		requiredAnyAccountTypes: [ACCOUNT_TYPES.agency],
		icon: (<SvgIcon fontSize="small"><BusinessIcon /></SvgIcon>)
	},
	{
		title: 'Klaviyo',
		path: '/klaviyo',
		requiredAnyPermissions: [PERMISSIONS.klaviyoWebhookRead, PERMISSIONS.klaviyoCampaignRead],
		requiredAnyAccountTypes: [ACCOUNT_TYPES.individual, ACCOUNT_TYPES.managed],
		icon: (<SvgIcon sx={{ rotate: '270deg' }} fontSize="small"><BookmarkIcon /></SvgIcon>)
	},
	{
		title: 'Products',
		path: '/products',
		requiredAnyPermissions: [],
		requiredAnyAccountTypes: [ACCOUNT_TYPES.individual, ACCOUNT_TYPES.managed],
		icon: (<SvgIcon fontSize="small"><InventoryIcon /></SvgIcon>)
	},
	{
		title: 'CSV Prompts',
		path: '/csv/all_prompts',
		requiredAnyPermissions: [PERMISSIONS.csvParseRead],
		requiredAnyAccountTypes: [ACCOUNT_TYPES.individual, ACCOUNT_TYPES.managed],
		icon: (<SvgIcon fontSize="small"><DriveFileRenameOutlineIcon /></SvgIcon>)
	},
	{
		title: 'Settings',
		path: '/settings',
		requiredAnyPermissions: [],
		requiredAnyAccountTypes: [],
		icon: (<SvgIcon fontSize="small"><SettingsIcon /></SvgIcon>)
	}
];
