import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Snackbar, Alert, AlertTitle } from "@mui/material";
import { setClose, clearSnackbar } from "services/redux/slicers/slicers.snackbar";
import { toString } from "lodash";

export const GlobalSnackbar = () => {
	const dispatch = useDispatch();
	// const loading = useSelector((state) => state.auth.loading); // Redux

	const [open, title, message, type] = useSelector((state) => [
		state.snackbar.open,
		state.snackbar.title,
		state.snackbar.message,
		state.snackbar.type,
	], shallowEqual); // shallowEqual ensures we don't re-render on unrelevant state changes



	const handleClose = (event, reason) => {
		if (reason === 'clickaway') { return; }
		dispatch(setClose());
	};

	const severity = type ? type : 'info'; // default severity type is "info"
	const alertTitle = title ? title : severity.charAt(0).toUpperCase() + severity.slice(1); // Title or capitalized type

	if (open && !(message || title)) {
		dispatch(setClose());
		// TODO: dont show snackbar if we're authentication (set when authing)
		// Wait a second before clearing the contents, because the snackbar fades away and if the type etc. 
		// is cleared, that is visible for at short amount of time
		setTimeout(() => { dispatch(clearSnackbar()); }, 1000);
		return null;
	} else {
		return (
			<Snackbar open={open} onClose={handleClose} autoHideDuration={8000}>
				{/*  <Snackbar open={open} onClose={handleClose} > */}
				<Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }} variant="filled">
					<AlertTitle>{toString(alertTitle)}</AlertTitle>
					{message &&
						<span
							style={{
								'maxWidth': '600px',
								'overflow': 'hidden',
								'whiteSpace': 'normal',
								'textOverflow': 'ellipsis',
								'display': 'inline-block',
							}}
						>
							{toString(message)}
						</span>
					}

				</Alert>
			</Snackbar >
		);
	}
};
