import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { upperFirst } from "lodash";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";

import { cancelUserInvite, getAccountUsers } from "api/account";
import { setOpenError, setOpenSuccess } from "services/redux/slicers/slicers.snackbar";

import { InviteMember } from "./invite-member";
import { LoadSkeleton } from "./load-skeleton";

export const SettingsTeamPage = () => {
	const dispatch = useDispatch();
	const [rows, setRows] = useState({});
	const [loading, setLoading] = useState(false);
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected);
	const user = useSelector((state) => state.auth.user); // Redux

	const refreshUsers = useCallback(() => {
		setLoading(true)
		// getAccountUsers(user.account_id)
		getAccountUsers(currentlySelectedAccount.id)
			.then(resp => {
				console.log('getAccountUsers resp', resp);
				setRows(resp.data);
			})
			.catch(err => {
				console.error('Error getting users for acc', err);
			})
			.finally(() => setLoading(false));
	}, [currentlySelectedAccount.id]);

	useEffect(() => {
		refreshUsers();
	}, [refreshUsers]);

	const cancelInvite = (id) => {
		cancelUserInvite(user.account_id, id)
			.then(() => {
				dispatch(setOpenSuccess({ title: 'Cancelled Invite' }))
				refreshUsers();
			})
			.catch(err => {
				console.error('error cancelling', err);
				dispatch(setOpenError({ title: 'Error Cancelling User Invite', message: err.message }));
			})
	};

	return (
		<Box p={2} px={5}>
			<InviteMember refreshUsers={refreshUsers} />

			{loading === false && (
				<Box>
					{rows.users?.map(row => (
						<Grid container flexDirection={'row'} key={row.id} alignSelf={'center'}>
							<Grid md={6} xs={12} item>
								<Typography variant="body2">{row.name}</Typography>
								<Typography variant="body2" color={'#6e6e80'}>{row.email}</Typography>
							</Grid>
							<Grid md={3} xs={12} alignSelf={'center'} item>
								{row.id === rows.account_owner && (
									<Typography variant="body2">Owner</Typography>
								) || (<Typography variant="body2">Member</Typography>)}
							</Grid>
							{/* <Grid md={3} xs={12}>
						<Grid xs={12} justifyContent={'flex-end'} container>
						<Button variant="outlined">
						Remove()
						</Button>
						</Grid>
					</Grid> */}

							<Grid xs={12} paddingY={2.5} item><Divider /></Grid>
						</Grid>
					))}


					{rows.invites?.map(row => (
						<Grid container flexDirection={'row'} key={row.id}>
							<Grid md={6} xs={12} alignSelf={'center'} item>
								<Typography variant="body2">{row.receiver_email}</Typography>
							</Grid>
							<Grid md={3} xs={12} alignSelf={'center'} item>
								<Typography color={'#6e6e80'} variant='body2'>{upperFirst(row.status)} Invite</Typography>
							</Grid>
							<Grid md={3} xs={12} item>
								<Grid xs={12} justifyContent={'flex-end'} container item>
									<Button variant="outlined" onClick={() => cancelInvite(row.id)}>
										Delete
									</Button>
								</Grid>
							</Grid>

							<Grid xs={12} paddingY={2.5} item><Divider /></Grid>
						</Grid>
					))}
				</Box>
			) || (<LoadSkeleton />)}
		</Box>

	);
};
