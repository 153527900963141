import { createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';

export const EDITOR_TYPES = {
	CSV_PARSE: 'csv_parse_editor',
	KLAVIYO_WEBHOOK: 'klaviyo_webhook_editor',
	KLAVIYO_CAMPAIGN: 'klaviyo_campaign_editor'
};

export const INITIAL_STATE = {
	editorType: null,
	prompt: null, // View the page's util function for creating empty prompt for values
	upsertButtonVisibility: false,
	preview: {
		payload: null, // The payload to populate the prompt
		populatedPrompt: null, // The populated prompt for generating a result
		generatingPromptResult: false, // Are we generating the prompt
		promptResult: null, // The generated prompt result
		templateToRender: null, // The Klaviyo Template to render
	},
	campaignJobRunning: false, // used to notify the child list to refresh. 
	promptTextValidationError: null
};

export const propmptEditorSlice = createSlice({
	name: 'prompt-editor',

	initialState: INITIAL_STATE,

	reducers: {
		setEditorType: (state, action) => {
			// Validate editor type before setting it
			if (!Object.values(EDITOR_TYPES).includes(action.payload) && !EDITOR_TYPES) {
				throw new Error('Editor Type not supported.');
			}
			state.editorType = action.payload;
		},
		setPrompt: (state, action) => {
			state.prompt = action.payload;
		},
		updatePrompt: (state, action) => {
			if (!state.prompt) { return; }
			const { key, value } = action.payload;
			const _prompt = { ...state.prompt };
			set(_prompt, key, value);
			state.prompt = _prompt;
		},
		clearPrompt: (state) => {
			Object
				.keys(INITIAL_STATE)
				.forEach(key => state[key] = INITIAL_STATE[key]);
		},
		setUpsertButtonVisibility: (state, action) => {
			state.upsertButtonVisibility = action.payload;
		},
		updatePromptPreview: (state, action) => {
			const { key, value } = action.payload;
			state.preview = { ...state.preview, [key]: value };
		},
		updateCampaignJobRunning: (state, action) => {
			state.campaignJobRunning = action.payload;
		},
		clearPromptPreview: (state) => {
			state.preview = INITIAL_STATE.preview
		},
		setPromptTextValidationError: (state, action) => {
			state.promptTextValidationError = action.payload;
		}
	}
});

// Action creators are generated for each case reducer function
export const {
	setEditorType,
	setPrompt,
	updatePrompt,
	updatePromptConfig,
	clearPrompt,
	setUpsertButtonVisibility,
	updatePromptPreview,
	updateCampaignJobRunning,
	clearPromptPreview,
	setPromptTextValidationError
} = propmptEditorSlice.actions;

export default propmptEditorSlice.reducer;
