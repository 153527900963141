import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { PropTypes } from "prop-types";
import { Button, ButtonGroup, Grow, Paper, MenuList, MenuItem, Popper, ClickAwayListener, Box } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { PERMISSIONS } from "config/permissions";

const options = [{ label: 'Add New Extra', promptKeywordType: 'prompt_extra' }];

export const KeywordManagerAddButton = ({ newKeywordAdded }) => {
	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);
	// const userPermissions = useSelector((state) => state.auth.xpermissions); // Redux
	const currentlySelectedAccount = useSelector((state) => state.accounts.currentlySelected); // Redux

	const handleClick = (type) => {
		newKeywordAdded(type);
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) { return; }
		setOpen(false);
	};

	const standardCreateButton = <Button onClick={() => handleClick('prompt_referenced')} sx={{ p: 2 }} variant="contained" fullWidth>Add New</Button>;

	// if (userPermissions.CAN_CREATE_NON_REFERENCED_KEYWORDS) {

	// TODO: actually implement the "canCreateNonReferencedKeywords" permission on a product 
	if (currentlySelectedAccount[PERMISSIONS.canCreateNonReferencedKeywords] === true) {
		return (
			<Box>
				<ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" sx={{ my: 2 }} fullWidth>
					{standardCreateButton}
					<Button
						size="small"
						aria-controls={open ? 'split-button-menu' : undefined}
						aria-expanded={open ? 'true' : undefined}
						aria-label='add-extra-keyword'
						role='select'
						aria-haspopup="menu"
						onClick={handleToggle}
						sx={{ width: 'fit-content' }}
					>
						<ArrowDropDownIcon />
					</Button>
				</ButtonGroup>
				<Popper
					open={open}
					sx={{ zIndex: 1 }}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					placement="bottom-end"
					disablePortal
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
						>
							<Paper>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList autoFocusItem>
										{options.map(option => (
											<MenuItem key={option.label} onClick={() => handleClick(option.promptKeywordType)}>
												{option.label}
											</MenuItem>
										))}
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</Box>
		);
	} else return (
		<Box sx={{ my: 2 }}>
			{standardCreateButton}
		</Box>
	);
};

KeywordManagerAddButton.propTypes = {
	newKeywordAdded: PropTypes.func.isRequired
};
