import { useSelector } from 'react-redux';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { PromptPreview } from "components/shared-components/prompt-editor/prompt-result-preview/prompt-preview";
import { PromptAnswerPreview } from "components/shared-components/prompt-editor/prompt-result-preview/prompt-answer-preview";
import { PreviewNavigationModal } from "components/shared-components/prompt-editor/prompt-result-preview/preview-navigation-modal";
import { KlaviyoEmailPreview } from "./klaviyo-email-preview";
import { JsonEditor } from 'components/shared-components/prompt-editor/prompt-result-preview/json-editor';
// import { WebhookEventSelector } from 'components/shared-components/prompt-editor/prompt-result-preview/webhook-event-selector';

const PREVIEW_STAGES = [
	{
		order: 0,
		id: 'VIEW_TEMPLATE',
		label: 'View Template',
		icon: <ArrowForwardIosIcon />,
		component: <JsonEditor />
		// component: <WebhookEventSelector />
	},
	{
		order: 1,
		id: 'GPT_RESPONSE_PREVIEW',
		label: 'AI Response',
		icon: <ArrowForwardIosIcon />,
		component: <PromptPreview />
	},
	{
		order: 2,
		id: 'RENDER_TEMPLATE',
		label: 'Render Template',
		component: <PromptAnswerPreview />
	},
	{
		order: 3,
		id: 'DONE',
		label: 'Done',
		component: <KlaviyoEmailPreview />
	}
];

export const KlaviyoResultPreview = () => {
	const promptId = useSelector((state) => state.promptEditor.prompt?.id); // Redux

	// Only show the preview button if the prompt has been inserted (exists in database) and it doensn't have any uncommited changes
	if (!promptId) { return null; }
	else return (
		<PreviewNavigationModal stages={PREVIEW_STAGES} />
	);
};